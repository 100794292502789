import React from 'react';
import Space from 'antd/lib/space';
import Button from 'antd/lib/button';
import ReloadOutlined from '@ant-design/icons/ReloadOutlined';
import { func } from 'prop-types';

const Refresh = ({ onRefresh }) => {
  return (
    <Space>
      <Button type="primary" icon={<ReloadOutlined />} onClick={onRefresh}>
        Làm mới
      </Button>
    </Space>
  );
};

export default Refresh;

Refresh.propTypes = {
  onRefresh: func
};

Refresh.defaultProps = {
  onRefresh: null
};
