import { Button, Modal, Space, Spin, Typography } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import actions from '../../../redux/actions';
import currency from '../../../utils/currency';
import dispatch from '../../../utils/dispatch';

export default function ConfirmModel() {
  const detail = useSelector(state => state.exchangePointRequest.detail);
  const payload = { request_id: detail?._id, user_id: detail?.user?.[0]?._id };
  const loading = useSelector(state => {
    const loadingConfirm = state.common.loadingImprove?.confirmExchangePointRequest;
    const loadingReject = state.common.loadingImprove?.rejectExchangePointRequest;
    return !!loadingConfirm || !!loadingReject;
  });

  const onCancel = () => dispatch(actions.clearExchangePointRequestInfo());
  const onReject = () => dispatch(actions.rejectExchangePointRequest(payload));
  const onConfirm = () => dispatch(actions.confirmExchangePointRequest(payload));

  return (
    <Modal
      visible={!!detail}
      title="Chi tiết đổi điểm thưởng"
      onCancel={onCancel}
      footer={
        <Space>
          <Button danger onClick={onReject}>
            Từ chối
          </Button>
          <Button type="primary" onClick={onConfirm}>
            Xác nhận
          </Button>
          <Button onClick={onCancel}>Đóng lại</Button>
        </Space>
      }
      width={650}
    >
      <Spin spinning={loading}>
        <span>
          Bạn có muốn xác nhận chuyển&nbsp;
          <Typography.Text strong type="warning">{`${currency.format(
            detail?.amount
          )}`}</Typography.Text>
          &nbsp;điểm thưởng thành&nbsp;
          <Typography.Text strong type="success">{`${currency.format(
            detail?.amount
          )} đ`}</Typography.Text>
          &nbsp;vào ví cho người dùng&nbsp;
          <Typography.Text strong>{detail?.user?.[0]?.name}</Typography.Text>
        </span>
      </Spin>
    </Modal>
  );
}
