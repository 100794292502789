import { all, takeLatest, call, put } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';

function* getListBank() {
  try {
    const result = yield call(services.getListBank);
    yield put({ type: TYPE.GET_LIST_BANK_SUCCESS, payload: result });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_BANK_FAILED });
  }
}

export default function* root() {
  yield all([takeLatest(TYPE.GET_LIST_BANK, getListBank)]);
}
