import { Space, Table, Tabs, Typography } from 'antd';
import { any, arrayOf, element, func, number, shape, string } from 'prop-types';
import React from 'react';
import './style.less';

const { Text, Title } = Typography;

const CustomizeTable = ({
  title,
  data,
  columns,
  count,
  currentPage,
  onPageChange,
  filterAndSearch,
  categoryTabs,
  activeCategoryTab,
  onChangeCategoryTab
}) => {
  return (
    <div className="list">
      <Space size="large">
        <Title className="title" level={5}>
          {title}
        </Title>
      </Space>
      <Space className={`head-table ${categoryTabs ? 'between' : ''}`}>
        {categoryTabs && (
          <Tabs
            activeKey={activeCategoryTab}
            onChange={onChangeCategoryTab}
            className="category"
          >
            {categoryTabs.map((item, index) => {
              return <Tabs.TabPane key={index} {...item} />;
            })}
          </Tabs>
        )}
        {filterAndSearch && (
          <Space align="end" className="search-filter">
            {filterAndSearch}
          </Space>
        )}
      </Space>
      <Table
        size="small"
        bordered
        className="table"
        pagination={{
          defaultPageSize: 10,
          current: currentPage,
          showSizeChanger: false,
          total: count,
          onChange: onPageChange
        }}
        rowClassName={record => record?.is_retail_customer && 'retail-customer'}
        columns={columns}
        dataSource={data}
        scroll={{ x: 'max-content', scrollToFirstRowOnChange: true }}
      />
      {activeCategoryTab === 'customer_confirmed' && (
        <Text className="note">
          Các khoản thanh toán từ khách hàng chờ xác nhận từ bộ phận tài chính
        </Text>
      )}
    </div>
  );
};

CustomizeTable.propTypes = {
  title: string,
  data: arrayOf(any),
  columns: arrayOf(any),
  count: number,
  currentPage: number,
  onPageChange: func,
  filterAndSearch: element,
  categoryTabs: arrayOf(
    shape({
      tab: string,
      key: string
    })
  ),
  activeCategoryTab: string,
  onChangeCategoryTab: func
};

CustomizeTable.defaultProps = {
  title: '',
  data: [],
  columns: [],
  count: 0,
  currentPage: 1,
  onPageChange: null,
  filterAndSearch: null,
  categoryTabs: null,
  activeCategoryTab: null,
  onChangeCategoryTab: null
};

export default CustomizeTable;
