import React from 'react';
import Form from 'antd/lib/form';
import Input from 'antd/lib/input';
import Radio from 'antd/lib/radio';
import Select from 'antd/lib/select';
import { useSelector } from 'react-redux';
import { get, has } from 'lodash';
import { any, bool, func, objectOf } from 'prop-types';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';

const { Option } = Select;

export default function FormAddress({ isVN, setIsVN, form }) {
  const listProvince = useSelector(state => {
    return get(state, 'location.province');
  });
  const listDistrict = useSelector(state => {
    return get(state, 'location.district');
  });
  const listWard = useSelector(state => {
    return get(state, 'location.ward');
  });

  const layout = {
    labelCol: {
      span: 6
    },
    wrapperCol: {
      span: 18
    },
    initialValues: {
      name: '',
      phone: '',
      type_addr: 'vietnam',
      city: '',
      district: '',
      ward: '',
      street: ''
    }
  };

  const rulesItem = {
    required: true
  };

  const handleChangeValue = (value, values) => {
    if (has(value, 'type_addr')) {
      if (value.type_addr === 'usa') setIsVN(false);
      if (value.type_addr === 'vietnam') setIsVN(true);
    }

    if (has(value, 'city')) {
      dispatch(actions.getAllDistrict(values.city));
      form.setFieldsValue({ district: '', ward: '' });
    }
    if (has(value, 'district')) {
      dispatch(actions.getAllWard(values.city, values.district));
      form.setFieldsValue({ ward: '' });
    }
  };

  return (
    <Form form={form} onValuesChange={handleChangeValue} {...layout}>
      <Form.Item
        name="name"
        label="Tên"
        rules={[{ ...rulesItem, message: 'Nhập tên' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Số điện thoại"
        rules={[{ ...rulesItem, message: 'Nhập số điện thoại' }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="type_addr" label="Địa chỉ ở">
        <Radio.Group value="vietnam">
          <Radio value="vietnam">Việt Nam</Radio>
          <Radio value="usa">Mỹ</Radio>
        </Radio.Group>
      </Form.Item>
      {isVN && (
        <>
          <Form.Item
            name="city"
            label="Tỉnh/Thành phố"
            rules={[{ ...rulesItem, message: 'Chọn tỉnh/Thành phố' }]}
          >
            <Select>
              {listProvince.map(prov => {
                return (
                  <Option key={prov.province_id} value={prov.province_id}>
                    {prov.province_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="district"
            label="Quận/Huyện"
            rules={[{ ...rulesItem, message: 'Chọn quận/Huyện' }]}
          >
            <Select>
              {listDistrict.map(prov => {
                return (
                  <Option key={prov.district_id} value={prov.district_id}>
                    {prov.district_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
          <Form.Item
            name="ward"
            label="Phường/Xã"
            rules={[{ ...rulesItem, message: 'Chọn phường/Xã' }]}
          >
            <Select>
              {listWard.map(prov => {
                return (
                  <Option key={prov.ward_id} value={prov.ward_id}>
                    {prov.ward_name}
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </>
      )}
      <Form.Item
        name="street"
        label="Địa chỉ cụ thể"
        rules={[{ ...rulesItem, message: 'Nhập địa chỉ cụ thể' }]}
      >
        <Input />
      </Form.Item>
    </Form>
  );
}

FormAddress.propTypes = {
  isVN: bool,
  setIsVN: func,
  form: objectOf(any)
};

FormAddress.defaultProps = {
  isVN: false,
  setIsVN: () => {},
  form: null
};
