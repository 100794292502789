import { Spin } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FilterAndSearch from '../../../components/FilterAndSearch';
import CustomizeTable from '../component/CustomizeTable/CustomizeTable';
// import ConfirmTransaction from '../../../components/Modal/ConfirmTransaction';
import {
  DEAL_TRANSACTION_STATUS,
  LIMIT_PAGE_DEFAULT,
  TRANSACTION_STATUS
} from '../../../contents/Constants';
import { MessageContent } from '../../../contents/Labels';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
// import TransactionDetail from '../TransactionDetail';
import TransactionDealDetail from '../component/TransactionDetail/TransactionDealDetail';
import columnData from './columnData';
import './style.less';

export const TransactionListContext = createContext(null);

const SEARCH_TRANSACTION = {
  text: '',
  date: null
};

const TransactionList = () => {
  const { count, transactions } = useSelector(state => {
    return get(state, 'transactionDeal.list', {});
  });
  const loading = useSelector(state => {
    return get(state, 'transactionDeal.isLoading', false);
  });
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(
    DEAL_TRANSACTION_STATUS.customerConfirmed.key
  );
  const [searchForm, setSearchForm] = useState(SEARCH_TRANSACTION);
  const searchFormRef = useRef(SEARCH_TRANSACTION);
  const activeTabRef = useRef(TRANSACTION_STATUS.customerConfirmed.key);

  useEffect(() => {
    const { text: search, date } = searchForm;
    const pageIndex =
      searchFormRef.current === searchForm && activeTabRef.current === activeTab
        ? page
        : 1;

    searchFormRef.current = searchForm;
    activeTabRef.current = activeTab;
    setPage(pageIndex);

    dispatch(
      actions.getListDeal({
        search,
        date,
        state: activeTab,
        page: pageIndex,
        limit: LIMIT_PAGE_DEFAULT
      })
    );
  }, [page, searchForm, activeTab]);

  const data = map(transactions, item => {
    return { key: item._id, ...item };
  }).filter(
    item =>
      !item?.is_retail_customer || item?.is_cs_verify || item?.payment_option === 1
  );

  const handleChangePage = pageIndex => {
    return setPage(pageIndex);
  };

  return (
    <TransactionListContext.Provider value={{ searchForm, setSearchForm }}>
      <Spin spinning={loading}>
        <CustomizeTable
          title={MessageContent.DealTransactionInfo}
          columns={columnData[activeTab]}
          data={data}
          count={count}
          currentPage={page}
          onPageChange={handleChangePage}
          filterAndSearch={<FilterAndSearch.DealTransaction />}
          categoryTabs={DEAL_TRANSACTION_STATUS.list}
          activeCategoryTab={activeTab}
          onChangeCategoryTab={setActiveTab}
        />
      </Spin>
      <TransactionDealDetail />
    </TransactionListContext.Provider>
  );
};

export default TransactionList;
