import { DatePicker, Modal, Spin } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import { notificationError } from '../../../utils/Notification';

const FORMAT_DATE = 'DD-MM-YYYY';

export default function ExportModal() {
  const detail = useSelector(state => state.exchangePointRequest.export);
  const onCancel = () => dispatch(actions.toggleExportPointStatistical());
  const [date, setDate] = useState([]);
  const onOk = () => {
    if (date.length !== 2) {
      notificationError('Vui lòng chọn đầy đủ ngày bắt đầu và kết thúc');
      return;
    }
    const [start_date, end_date] = date;
    dispatch(actions.exportPointStatistical({ start_date, end_date }));
  };

  return (
    <Modal
      visible={!!detail}
      title="Xuất báo cáo thống kê điểm thưởng"
      onCancel={onCancel}
      onOk={onOk}
      width={650}
    >
      <Spin spinning={false}>
        <span>Chọn thời gian xuất thống kê: </span>
        <DatePicker.RangePicker
          format={FORMAT_DATE}
          onChange={data =>
            setDate((data || []).map(item => moment(item).format(FORMAT_DATE)))
          }
        />
      </Spin>
    </Modal>
  );
}
