import { all, call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';
import {
  getListDepositForExchangePointRequest,
  getListPointForExchangePointRequest
} from './commonSaga';

function* getListExchangePointRequest(action) {
  try {
    const { data } = yield call(
      services.getListExchangePointRequest,
      action.payload
    );
    yield put({
      type: TYPE.GET_LIST_EXCHANGE_POINT_REQUEST_SUCCESS,
      payload: {
        data: data?.results || [],
        count: data?.count || 0
      }
    });
    yield getListDepositForExchangePointRequest();
    yield getListPointForExchangePointRequest();
  } catch (err) {
    yield put({
      type: TYPE.GET_LIST_EXCHANGE_POINT_REQUEST_FAILED,
      message: err
    });
  }
}

function* confirmExchangePointRequest(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'confirmExchangePointRequest'
    });
    yield call(services.confirmExchangePointRequest, action.payload);
    yield put({
      type: TYPE.CONFIRM_EXCHANGE_POINT_REQUEST_SUCCESS,
      ...action.payload
    });
    yield put({ type: TYPE.CLEAR_EXCHANGE_POINT_REQUEST_DETAIL });
  } catch (err) {
    yield put({
      type: TYPE.CONFIRM_EXCHANGE_POINT_REQUEST_FAILED,
      message: err
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'confirmExchangePointRequest'
    });
  }
}

function* rejectExchangePointRequest(action) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'rejectExchangePointRequest'
    });
    yield call(services.rejectExchangePointRequest, action.payload);
    yield put({
      type: TYPE.REJECT_EXCHANGE_POINT_REQUEST_SUCCESS,
      ...action.payload
    });
    yield put({ type: TYPE.CLEAR_EXCHANGE_POINT_REQUEST_DETAIL });
  } catch (err) {
    yield put({
      type: TYPE.REJECT_EXCHANGE_POINT_REQUEST_FAILED,
      message: err
    });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'rejectExchangePointRequest'
    });
  }
}

function* updatePointByAdmin(action) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'updatePointByAdmin' });
    const { data } = yield call(services.updatePointByAdmin, action.payload);
    yield put({
      type: TYPE.UPDATE_POINT_BY_ADMIN_SUCCESS,
      data
    });
    yield put({ type: TYPE.TOGGLE_REQUEST_CHANGE_POINT });
    yield put({ type: TYPE.CLEAR_CUSTOMER });
    yield getListPointForExchangePointRequest();
  } catch (err) {
    yield put({
      type: TYPE.UPDATE_POINT_BY_ADMIN_FAILED,
      message: err
    });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'updatePointByAdmin' });
  }
}

function* exportPointStatistical(action) {
  try {
    const { data } = yield call(services.exportPointStatistical, action.payload);
    window.open(data.url);
    yield put({ type: TYPE.EXPORT_POINT_STATISTICAL_SUCCESS });
  } catch (error) {
    yield put({ type: TYPE.EXPORT_POINT_STATISTICAL_FAILED });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_EXCHANGE_POINT_REQUEST, getListExchangePointRequest),
    takeLatest(TYPE.CONFIRM_EXCHANGE_POINT_REQUEST, confirmExchangePointRequest),
    takeLatest(TYPE.REJECT_EXCHANGE_POINT_REQUEST, rejectExchangePointRequest),
    takeLatest(TYPE.UPDATE_POINT_BY_ADMIN, updatePointByAdmin),
    takeLatest(TYPE.EXPORT_POINT_STATISTICAL, exportPointStatistical)
  ]);
}
