import { all, takeLatest, put, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';
import { ACTION_STATUS } from '../../contents/Constants';

function* getUrlImage(action) {
  try {
    yield call(services.getUrlImage, action.image);
  } catch (error) {
    yield put({
      type: TYPE.GET_URL_IMAGE_FAILED,
      message: ACTION_STATUS.FAILED,
      error
    });
  }
}

export default function* root() {
  yield all([takeLatest(TYPE.GET_URL_IMAGE, getUrlImage)]);
}
