import React from 'react';
import { string } from 'prop-types';
import './style.less';

export default function ContentItem({ title, value }) {
  return (
    <div className="content-item">
      <div className="title">{title}</div>
      <div className="value">{value}</div>
    </div>
  );
}

ContentItem.propTypes = {
  title: string,
  value: string
};

ContentItem.defaultProps = {
  title: '',
  value: ''
};
