import { CloseOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Drawer, Form, Input, Switch, Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import SearchCustomer from '../../../components/SearchCustomer';
import UploadImage from '../../../components/UploadImage';
import { SELLER_PROFILE_MODE } from '../../../contents/Constants';
import useUploadFile from '../../../hooks/useUploadFile';
import actions from '../../../redux/actions';

export default function CreateSellerProfile() {
  const { TabPane } = Tabs;
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [file, handleUploadFile, handleReset] = useUploadFile('');
  const mode = useSelector(state => state?.seller?.mode, shallowEqual);
  const dispatch = useDispatch();
  const userSelected = useSelector(
    state => state?.customer?.userSelected,
    shallowEqual
  );

  const handleChooseAccount = () => {
    dispatch(actions.clearCustomer());
  };

  const Breadcrumbs = [
    {
      label: 'Chọn tài khoản',
      onClick: handleChooseAccount
    },
    { label: 'Nhập thông tin Seller' }
  ];

  useEffect(() => {
    if (userSelected) {
      setBreadcrumbs(Breadcrumbs);
    } else {
      setBreadcrumbs([Breadcrumbs[0]]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userSelected]);

  const isShowSearchCustomer = !userSelected;

  const onFinish = data => {
    dispatch(
      actions.createSellerProfile({
        ...data,
        user_id: userSelected._id,
        type: data.type ? 'buyngon' : 'default',
        avatar_url: file.link
      })
    );
  };

  return (
    <Drawer
      className="drawer"
      width="40vw"
      title="Tạo đối tác bán hàng"
      placement="right"
      closeIcon={
        <CloseOutlined
          onClick={() => dispatch(actions.changeModeSeller(SELLER_PROFILE_MODE.off))}
        />
      }
      visible={mode === SELLER_PROFILE_MODE.create}
    >
      <Tabs defaultActiveKey="1">
        <TabPane tab="Đã có tài khoản buyngon" key="1">
          <>
            <Breadcrumb style={{ marginBottom: 10 }}>
              {breadcrumbs.map((i, k) => (
                <Breadcrumb.Item key={k} onClick={i.onClick}>
                  {i.label}
                </Breadcrumb.Item>
              ))}
            </Breadcrumb>
            {isShowSearchCustomer && <SearchCustomer />}
            {userSelected?.seller_profile && (
              <p style={{ fontSize: 15, color: 'red', fontStyle: 'italic' }}>
                Tài khoản này đã được tạo profile, vui lòng chọn tài khoản khác
              </p>
            )}
            {userSelected && !userSelected?.seller_profile && (
              <Form
                style={{ marginTop: 24 }}
                name="basic"
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                initialValues={{
                  email: userSelected?.email,
                  phone: userSelected?.phone,
                  type: true,
                  avatar_url: ''
                }}
                onFinish={onFinish}
                autoComplete="off"
              >
                <Form.Item
                  label="Tên cửa hàng"
                  name="name"
                  rules={[{ required: true, message: 'Nhập thông tin này' }]}
                >
                  <Input />
                </Form.Item>

                <Form.Item
                  label="email"
                  name="email"
                  rules={[
                    { required: true, message: 'Xác thực email để tạo Seller' }
                  ]}
                >
                  <Input disabled />
                </Form.Item>

                <Form.Item
                  label="Số điện thoại"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: 'Xác thực số điện thoại để tạo Seller'
                    }
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Cửa hàng BuyNgon" name="type">
                  <Switch checked disabled />
                </Form.Item>
                <Form.Item label="Avatar" name="avatar">
                  <UploadImage
                    imgUrl={file.link}
                    loading={file.isFetching}
                    beforeUpload={handleUploadFile}
                    handleReset={handleReset}
                  />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            )}
          </>
        </TabPane>
        <TabPane tab="Chưa có tài khoản buyngon" key="2">
          Update Soon !
        </TabPane>
      </Tabs>
    </Drawer>
  );
}
