import { get } from 'lodash';
import TYPE from '../actions/TYPE';

const initialState = {
  list: []
};

const shipping = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_SHIPPING_CATEGORIES_SUCCESS:
      return {
        ...state,
        list: get(action, 'data')
      };

    default:
      return state;
  }
};

export default shipping;
