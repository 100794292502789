import TYPE from '../actions/TYPE';

const initialState = {
  list: []
};

const debtRecovery = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_LIST_DEBT_RECOVERY_SUCCESS:
      return {
        ...state,
        list: action?.data
      };

    default:
      return state;
  }
};

export default debtRecovery;
