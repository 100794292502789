import { CloseOutlined } from '@ant-design/icons';
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  Spin
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Option } from 'antd/lib/mentions';
import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  CAMPAIGN_MODE,
  CAMPAIGN_PROMOTION,
  MESSAGE_CONTENT
} from '../../../contents/Constants';
import actions from '../../../redux/actions';
import { notificationSuccess } from '../../../utils/Notification';

export default function CreateCampaign() {
  const mode = useSelector(state => state?.campaign?.mode, shallowEqual);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const userSelected = useSelector(
    state => state?.customer?.userSelected,
    shallowEqual
  );

  const onFinish = data => {
    let result = null;
    if (data.type === CAMPAIGN_PROMOTION.TYPE.DECEMBER_BEST_DEAL.value) {
      result = {
        ...data,
        emails: data.emails.split(' '),
        websites: data.websites.split(' ')
      };
    }
    setLoading(true);
    dispatch(
      actions.createCampaign({
        data: result,
        callback: ({ error }) => {
          if (error) {
            setLoading(false);
            return null;
          }
          notificationSuccess(MESSAGE_CONTENT.createSuccess);
          form.resetFields();
          setLoading(false);
          return dispatch(actions.changeModeCampaign(CAMPAIGN_MODE.off));
        }
      })
    );
  };

  const switchComponent = componentType => {
    switch (componentType) {
      case 'number':
        return <InputNumber min={1} />;
      case 'textarea':
        return <TextArea rows={5} />;
      default:
        return <Input />;
    }
  };

  return (
    <Drawer
      className="drawer"
      width="40vw"
      title="Tạo chiến dịch mới"
      placement="right"
      closeIcon={
        <CloseOutlined
          onClick={() => dispatch(actions.changeModeCampaign(CAMPAIGN_MODE.off))}
        />
      }
      visible={mode === CAMPAIGN_MODE.create}
    >
      <Spin spinning={loading}>
        <Form
          form={form}
          style={{ marginTop: 24 }}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            email: userSelected?.email,
            phone: userSelected?.phone,
            type: true,
            avatar_url: ''
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Tên"
            name="name"
            rules={[{ required: true, message: 'Nhập thông tin này' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item name="type" label="Phân loại" rules={[{ required: true }]}>
            <Select onChange={value => setType(value)} allowClear>
              <Option value={CAMPAIGN_PROMOTION.TYPE.DECEMBER_BEST_DEAL.value}>
                {CAMPAIGN_PROMOTION.TYPE.DECEMBER_BEST_DEAL.label}
              </Option>
            </Select>
          </Form.Item>
          {type && (
            <Divider
              orientation="left"
              plain
              style={{ marginTop: 50, fontStyle: 'italic' }}
            >
              Điều kiện
            </Divider>
          )}
          {(CAMPAIGN_PROMOTION.CONDITION[type] || []).map(i => (
            <>
              <Form.Item
                label={i?.label}
                name={i?.value}
                rules={[{ required: true, message: 'Nhập thông tin này' }]}
              >
                {switchComponent(i?.component)}
              </Form.Item>
            </>
          ))}

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </Drawer>
  );
}
