import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import Tabs from 'antd/lib/tabs';
import './style.less';
import actions from '../../redux/actions';
import LoginAction from '../../components/actions/LoginAction';
import WebHook from '../../components/WebHook';
import Log from '../../components/Log';
import AutoConfirmSaleOrder from '../../components/AutoConfirmSaleOrder';
import PhoneOTP from '../../components/PhoneOTP';
import dispatch from '../../utils/dispatch';
import RuleAutoConfirm from '../../components/RuleAutoConfirm';

const TABS = [
  { key: 'login', tab: 'Đăng nhập', Component: LoginAction },
  { key: 'web-hook', tab: 'Web Hook', Component: WebHook },
  { key: 'log', tab: 'Log', Component: Log },
  { key: 'phone-otp', tab: 'Verify Phone', Component: PhoneOTP },
  {
    key: 'auto-confirm-sale-order',
    tab: 'Log tự động confirm đơn hàng',
    Component: AutoConfirmSaleOrder
  },
  {
    key: 'rule-auto-confirm-sale-order',
    tab: 'Rule tự động confirm đơn hàng',
    Component: RuleAutoConfirm
  }
];

const LogAction = () => {
  const [tab, setTab] = useState(TABS[0].key);

  useEffect(() => {
    dispatch(actions.getConfiguration());
    return () => dispatch(actions.clearConfiguration());
  }, []);

  return (
    <div className="container">
      <Title className="title" level={5}>
        Hoạt động
      </Title>
      <Tabs onChange={setTab}>
        <>
          {TABS.map(tabItem => {
            return (
              <Tabs.TabPane
                active={tabItem.key === tab}
                tab={tabItem.tab}
                key={tabItem.key}
              >
                <tabItem.Component />
              </Tabs.TabPane>
            );
          })}
        </>
      </Tabs>
    </div>
  );
};

export default LogAction;
