import React from 'react';
import { Card } from 'antd';
import { element, string } from 'prop-types';
import './style.less';

export default function CardLayout({ children, title, ...props }) {
  return (
    <Card className="card-layout card" title={title} {...props}>
      {children}
    </Card>
  );
}

CardLayout.propTypes = {
  title: string,
  children: element.isRequired
};

CardLayout.defaultProps = {
  title: ''
};
