import React from 'react';
import Text from 'antd/lib/typography/Text';
import Button from 'antd/lib/button';
import Skeleton from 'antd/lib/skeleton';
import Alert from 'antd/lib/alert';
import moment from 'moment';
import { isNumber } from 'lodash';
import { MessageContent } from '../../contents/Labels';
import currency from '../../utils/currency';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';

export default {
  draft: [
    {
      title: 'Ngày yêu cầu',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return <Text>{moment(text).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'confirmed_time',
      dataIndex: 'confirmed_time',
      className: 'hide'
    },
    {
      title: 'Khách hàng',
      dataIndex: 'user',
      render: (_, record) => (
        <>
          <Alert
            message={record?.user?.[0]?.name}
            type={record?.bank_refer?.is_verify ? 'success' : 'warning'}
            showIcon
          />
        </>
      )
    },
    {
      title: 'Khoản dư ghi nhận',
      dataIndex: 'current_deposit',
      render: deposit => <Text>{`${currency.format(deposit)} đ`}</Text>
    },

    {
      title: 'Khoản dư hiện tại',
      dataIndex: 'deposit',
      key: 'balance',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <Text>{`${currency.format(value)} đ`}</Text>;
      }
    },
    {
      title: 'Số tiền rút',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: text => {
        return <Text className="total">{`${currency.format(text)} đ`}</Text>;
      }
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      render: (_, record) => {
        return (
          <Button
            type="primary"
            onClick={() => {
              return dispatch(actions.getWithdrawalInfo(record), false);
            }}
          >
            {MessageContent.Confirm}
          </Button>
        );
      }
    }
  ],
  approved: [
    {
      title: 'Ngày yêu cầu',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return <Text>{moment(text).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'Ngày xác nhận',
      key: 'approved_time',
      dataIndex: 'approved_time',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return <Text>{moment(text).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },

    {
      title: 'Khách hàng',
      dataIndex: 'user',
      render: (_, record) => (
        <>
          <Alert
            message={record?.user?.[0]?.name}
            type={record?.bank_refer?.is_verify ? 'success' : 'warning'}
            showIcon
          />
        </>
      )
    },
    {
      title: 'Khoản dư ghi nhận',
      dataIndex: 'current_deposit',
      render: deposit => <Text>{`${currency.format(deposit)} đ`}</Text>
    },

    {
      title: 'Khoản dư hiện tại',
      dataIndex: 'deposit',
      key: 'balance',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <Text>{`${currency.format(value)} đ`}</Text>;
      }
    },
    {
      title: 'Số tiền rút',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: text => {
        return <Text className="total">{`${currency.format(text)} đ`}</Text>;
      }
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      render: (_, record) => {
        return (
          <Button
            type="dash"
            onClick={() => {
              return dispatch(actions.getWithdrawalInfo(record), false);
            }}
          >
            {MessageContent.Detail}
          </Button>
        );
      }
    }
  ],
  rejected: [
    {
      title: 'Ngày yêu cầu',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return <Text>{moment(text).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'Ngày xác nhận',
      key: 'approved_time',
      dataIndex: 'approved_time',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return <Text>{moment(text).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'Khách hàng',
      dataIndex: 'user',
      render: (_, record) => (
        <>
          <Alert
            message={record?.user?.[0]?.name}
            type={record?.bank_refer?.is_verify ? 'success' : 'warning'}
            showIcon
          />
        </>
      )
    },
    {
      title: 'Khoản dư ghi nhận',
      dataIndex: 'current_deposit',
      render: deposit => <Text>{`${currency.format(deposit)} đ`}</Text>
    },

    {
      title: 'Khoản dư hiện tại',
      dataIndex: 'deposit',
      key: 'balance',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <Text>{`${currency.format(value)} đ`}</Text>;
      }
    },
    {
      title: 'Số tiền rút',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: text => {
        return <Text className="total">{`${currency.format(text)} đ`}</Text>;
      }
    },
    {
      title: 'Xác nhận bởi',
      dataIndex: 'approved_by',
      key: 'approved_by',
      render: approved_by => {
        return <Text>{approved_by?.[0]?.name}</Text>;
      }
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      render: (_, record) => {
        return (
          <Button
            type="dashed"
            onClick={() => {
              return dispatch(actions.getWithdrawalInfo(record), false);
            }}
          >
            {MessageContent.Detail}
          </Button>
        );
      }
    }
  ]
};
