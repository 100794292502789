import React, { useContext, useEffect } from 'react';
import Search from 'antd/lib/input/Search';
import Space from 'antd/lib/space';
import DatePicker from 'antd/lib/date-picker';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import Button from 'antd/lib/button';
import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { MessageContent } from '../../contents/Labels';
import { TransactionListContext } from '../../pages/Transaction/TransactionList';
import history from '../../navigation';
import { Routes } from '../../contents/Constants';

const FilterSearchTransaction = () => {
  const notification = useSelector(state => {
    return get(state, 'transaction.notification', '');
  });
  const { searchForm, setSearchForm } = useContext(TransactionListContext);

  const onTextSearch = text => {
    return setSearchForm({ ...searchForm, text });
  };
  const onDateSearch = date => {
    return setSearchForm({
      ...searchForm,
      date: date ? date.format('DD-MM-YYYY') : null
    });
  };

  const handleCreateSaleOrder = () => {
    return history.push(Routes.CreateSaleOrder);
  };

  useEffect(() => {
    const saleOrderId = get(notification, 'saleOrderId', null);
    if (saleOrderId) {
      setSearchForm(null);
    }
  }, [notification, setSearchForm]);

  return (
    <Space>
      <Search
        allowClear
        placeholder={MessageContent.SearchBy}
        onSearch={onTextSearch}
        enterButton
      />
      <DatePicker onChange={onDateSearch} allowClear />
      <Button type="primary" icon={<PlusOutlined />} onClick={handleCreateSaleOrder}>
        Tạo đơn hàng
      </Button>
    </Space>
  );
};

export default FilterSearchTransaction;
