import React from 'react';
import Space from 'antd/lib/space';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { CAMPAIGN_MODE } from '../../contents/Constants';
import actions from '../../redux/actions';

const FilterSearchCampaign = () => {
  const dispatch = useDispatch();

  return (
    <Space>
      <Button
        type="primary"
        onClick={() => dispatch(actions.changeModeCampaign(CAMPAIGN_MODE.create))}
      >
        + Tạo chiến dịch mới
      </Button>
    </Space>
  );
};

export default FilterSearchCampaign;
