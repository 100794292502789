import { PlusOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import { func } from 'prop-types';
import React from 'react';

const DealWithdraw = ({ onOpenModal }) => {
  // const handleRefreshData = () => {
  //   dispatch(actions.getListDealWithdraw());
  // };

  return (
    <Space>
      <Button type="primary" icon={<PlusOutlined />} onClick={onOpenModal}>
        Tạo yêu cầu hoàn trả
      </Button>
      {/* <Button type="primary" icon={<ReloadOutlined />} onClick={handleRefreshData}>
        Làm mới
      </Button> */}
    </Space>
  );
};

DealWithdraw.propTypes = {
  onOpenModal: func
};

DealWithdraw.defaultProps = {
  onOpenModal: () => {}
};

export default DealWithdraw;
