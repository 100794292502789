import React from 'react';
import { element, object, oneOfType } from 'prop-types';
import SideBar from '../SideBar';
import './style.less';

const MainLayout = ({ children, history }) => {
  return (
    <main id="app">
      <SideBar history={history} />
      <div className="page-view">
        <div className="content-view">{children}</div>
      </div>
    </main>
  );
};
MainLayout.propTypes = {
  children: element.isRequired,
  history: oneOfType([object]).isRequired
};

export default MainLayout;
