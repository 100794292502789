import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Input, Switch } from 'antd';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import UploadImage from '../../../components/UploadImage';
import { SELLER_PROFILE_MODE } from '../../../contents/Constants';
import useUploadFile from '../../../hooks/useUploadFile';
import actions from '../../../redux/actions';

export default function EditSellerProfile() {
  const [file, handleUploadFile, handleReset, setFile] = useUploadFile('');
  const [form] = Form.useForm();
  const mode = useSelector(state => state?.seller?.mode, shallowEqual);
  const dispatch = useDispatch();
  const seller = useSelector(state => state?.seller?.edit?.seller, shallowEqual);

  const onFinish = data => {
    dispatch(
      actions.editSellerProfile({
        name: data.name,
        user_id: seller.key,
        type: data.type ? 'buyngon' : 'default',
        avatar_url: file.link,
        phone: data?.phone
      })
    );
  };

  useEffect(() => {
    form.resetFields();
    setFile({ link: seller?.avatar_url || '', isFetching: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seller]);
  return (
    <Drawer
      className="drawer"
      width="40vw"
      title="Chỉnh sửa Seller"
      placement="right"
      closeIcon={
        <CloseOutlined
          onClick={() => dispatch(actions.changeModeSeller(SELLER_PROFILE_MODE.off))}
        />
      }
      visible={mode === SELLER_PROFILE_MODE.edit}
    >
      <>
        <Form
          style={{ marginTop: 24 }}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          form={form}
          initialValues={{
            name: seller?.name,
            email: seller?.email,
            phone: seller?.phone,
            type: seller?.type === 'buyngon'
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="Tên cửa hàng"
            name="name"
            rules={[{ required: true, message: 'Nhập thông tin này' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="email"
            name="email"
            rules={[{ required: true, message: 'Xác thực email để tạo Seller' }]}
          >
            <Input disabled />
          </Form.Item>

          <Form.Item
            label="Số điện thoại"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Xác thực số điện thoại để tạo Seller'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="type"
            label="Cửa hàng BuyNgon"
            valuePropName="checked"
            initialValue
          >
            <Switch checkedChildren="YES" unCheckedChildren="NO" />
          </Form.Item>
          <Form.Item label="Avatar">
            <UploadImage
              imgUrl={file.link}
              loading={file.isFetching}
              beforeUpload={handleUploadFile}
              handleReset={handleReset}
            />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>
      </>
    </Drawer>
  );
}
