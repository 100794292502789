import { Modal, Spin, Typography } from 'antd';
import { isEmpty } from 'lodash';
import get from 'lodash/get';
import { bool, func } from 'prop-types';
import React, { createContext, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomerProfile from '../../../../components/CustomerProfile';
import SearchCustomer from '../../../../components/SearchCustomer';
import SearchDeal from '../../../../components/SearchDeal';
import actions from '../../../../redux/actions';
import dispatch from '../../../../utils/dispatch';
import { notificationError } from '../../../../utils/Notification';

const { Text } = Typography;

export const WithdrawModalContext = createContext();

export default function CreateNewWithdrawModal({ open, onClose }) {
  const [amountValue, setAmountValue] = useState(0);
  const [selectedDeal, setSelectedDeal] = useState({ value: '', label: '' });
  const contextValue = {
    amountValue,
    setAmountValue,
    selectedDeal,
    setSelectedDeal
  };

  const [isSelectedUser, setIsSelectedUser] = useState(false);
  const userSelectedId = useSelector(state => {
    return get(state, 'customer.userSelected._id');
  });
  const listDealOfUser = useSelector(
    state => state.transactionDeal.listDealOfUser || []
  );

  const handleCreateDealWithdrawRequest = () => {
    if (!userSelectedId) {
      notificationError('Vui lòng chọn khách hàng');
      return;
    }
    // if (!amountValue || amountValue <= 0) {
    //   notificationError('Số tiền không hợp lệ');
    //   return;
    // }

    const data = {
      user: userSelectedId,
      deal_gift_card: selectedDeal?._id
    };
    dispatch(actions.createDealWithdrawRequest(data));

    onClose();
  };

  return (
    <WithdrawModalContext.Provider value={contextValue}>
      <Modal
        width={550}
        visible={open}
        onCancel={onClose}
        title="Tạo yêu cầu hoàn trả Deal"
        onOk={handleCreateDealWithdrawRequest}
      >
        <Spin spinning={false}>
          <div>
            <Text strong>Chọn khách hàng:</Text>
            {!!userSelectedId && (
              <CustomerProfile
                isShowDeal
                shorthand
                isSelected={isSelectedUser}
                setIsSelected={setIsSelectedUser}
              />
            )}
            {!userSelectedId && <SearchCustomer />}

            {!isEmpty(listDealOfUser) && <SearchDeal />}
          </div>
        </Spin>
      </Modal>
    </WithdrawModalContext.Provider>
  );
}

CreateNewWithdrawModal.propTypes = {
  open: bool,
  onClose: func
};

CreateNewWithdrawModal.defaultProps = {
  open: false,
  onClose: () => {}
};
