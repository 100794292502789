import { all, takeLatest, call, put } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import TYPE from '../actions/TYPE';
import services from '../../services';
import { SELLER_PROFILE_MODE } from '../../contents/Constants';

function* listSellerProfile({ params: payload }) {
  try {
    const cleanedParams = pickBy(payload, identity);
    const { data } = yield call(
      services.marketplace.seller.listProfile,
      cleanedParams
    );
    yield put({ type: TYPE.LIST_SELLER_PROFILE_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.LIST_SELLER_PROFILE_FAILED, error });
  }
}

function* createSellerProfile({ payload }) {
  try {
    const { data, success } = yield call(
      services.marketplace.seller.createProfile,
      payload
    );

    if (!success) {
      yield put({ type: TYPE.CREATE_SELLER_PROFILE_FAILED });
      return;
    }
    yield put({ type: TYPE.CREATE_SELLER_PROFILE_SUCCESS, data });
    yield put({ type: TYPE.CLEAR_CUSTOMER });
    yield listSellerProfile({ params: {} });
  } catch (error) {
    yield put({ type: TYPE.CREATE_SELLER_PROFILE_FAILED, error });
  }
}

function* editSellerProfile({ payload }) {
  try {
    const { data, success } = yield call(
      services.marketplace.seller.editProfile,
      payload
    );

    if (!success) {
      yield put({ type: TYPE.EDIT_SELLER_PROFILE_FAILED });
      return;
    }
    yield put({ type: TYPE.EDIT_SELLER_PROFILE_SUCCESS, data });
    yield put({
      type: TYPE.CHANGE_MODE_SELLER_PROFILE,
      payload: SELLER_PROFILE_MODE.off
    });
    yield listSellerProfile({ params: {} });
  } catch (error) {
    yield put({ type: TYPE.EDIT_SELLER_PROFILE_FAILED, error });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.LIST_SELLER_PROFILE, listSellerProfile),
    takeLatest(TYPE.CREATE_SELLER_PROFILE, createSellerProfile),
    takeLatest(TYPE.EDIT_SELLER_PROFILE, editSellerProfile)
  ]);
}
