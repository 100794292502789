import { DiffOutlined, FileExcelOutlined } from '@ant-design/icons';
import { Button, Spin } from 'antd';
import { get, map } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizeTable from '../../components/CustomizeTable/CustomizeTable';
import FilterAndSearch from '../../components/FilterAndSearch';
import {
  LIMIT_PAGE_DEFAULT,
  EXCHANGE_POINT_REQUEST_STATUS
} from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import columnData from './columnData';
import ConfirmModel from './Modal/ConfirmModel';
import ExportModal from './Modal/ExportModal';
import RequestModel from './Modal/RequestModel';

export default function ExchangePointRequest() {
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(
    EXCHANGE_POINT_REQUEST_STATUS.draft.key
  );
  // const activeTabRef = useRef(WITHDRAWAL_STATUS.draft.key);
  // const pageIndex = activeTabRef.current === activeTab ? page : 1;

  useEffect(() => {
    // activeTabRef.current = activeTab;
    // setPage(pageIndex);

    dispatch(
      actions.getListExchangePointRequest({
        status: activeTab,
        page,
        limit: LIMIT_PAGE_DEFAULT
      })
    );
  }, [page, activeTab]);

  const { count, data } = useSelector(state => {
    return get(state, 'exchangePointRequest.list', { count: 0, data: [] });
  });
  const loading = false;
  const tableData = map(data, item => {
    return { key: item._id, ...item };
  });

  const handleChangePage = value => setPage(value);

  const onRefresh = () => {
    dispatch(
      actions.getListExchangePointRequest({
        status: activeTab,
        page,
        limit: LIMIT_PAGE_DEFAULT
      })
    );
  };

  const onExport = () => dispatch(actions.toggleExportPointStatistical());

  const onRequest = () => dispatch(actions.toggleRequestChangePoint());

  return (
    <Spin spinning={loading}>
      <CustomizeTable
        title={MessageContent.ExchangePointRequest}
        columns={columnData[activeTab]}
        data={tableData}
        count={count}
        currentPage={page}
        onPageChange={handleChangePage}
        filterAndSearch={
          <>
            <Button type="ghost" icon={<FileExcelOutlined />} onClick={onExport}>
              Xuất báo cáo
            </Button>
            <Button type="primary" icon={<DiffOutlined />} onClick={onRequest}>
              Tạo yêu cầu thay đổi điểm
            </Button>
            <FilterAndSearch.Refresh onRefresh={onRefresh} />
          </>
        }
        categoryTabs={EXCHANGE_POINT_REQUEST_STATUS.list}
        activeCategoryTab={activeTab}
        onChangeCategoryTab={setActiveTab}
      />
      <ConfirmModel />
      <RequestModel />
      <ExportModal />
    </Spin>
  );
}
