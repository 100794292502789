import { Spin } from 'antd';
import { get, map, size } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizeTable from '../../../components/CustomizeTable/CustomizeTable';
import FilterAndSearch from '../../../components/FilterAndSearch';
import { MessageContent } from '../../../contents/Labels';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import columnData from './columnData';
import './style.less';

export default function ListDeal() {
  const listdeal = useSelector(state => get(state, 'metaDeal.list', {}));
  // const isUpdate = useSelector(state => !!get(state, 'metaDeal.updated'));

  const [page, setPage] = useState(1);
  const count = size(listdeal);

  const handleChangePage = pageIndex => {
    return setPage(pageIndex);
  };
  const data = map(listdeal, item => {
    return { key: item.skuid, ...item };
  });

  useEffect(() => {
    dispatch(actions.getMetaDealList());
  }, []);

  // useEffect(() => {
  //   if (isUpdate) {
  //     dispatch(actions.getMetaDealList());
  //     dispatch(actions.clearUpdateStateDeal());
  //   }
  // }, [isUpdate]);

  return (
    <Spin spinning={false}>
      <CustomizeTable
        title={MessageContent.DealManage}
        // eslint-disable-next-line dot-notation
        columns={columnData}
        data={data}
        count={count}
        currentPage={page}
        onPageChange={handleChangePage}
        filterAndSearch={<FilterAndSearch.DealManage />}
      />
    </Spin>
  );
}
