import React from 'react';
import Layout from 'antd/lib/layout';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Image from 'antd/lib/image';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import './style.less';
import { element } from 'prop-types';
import Images from '../../contents/Images';
import { MessageContent } from '../../contents/Labels';

const OutLayout = ({ children }) => {
  return (
    <main id="app">
      <Layout>
        <Row className="c-layout">
          <Col span={12} className="left-layout">
            <Image
              className="logo"
              preview={false}
              src={Images.bgLogin}
              alt="bgLogin"
            />
            <Title className="title" level={4}>
              {MessageContent.OutLayoutTitle}
            </Title>
            <Paragraph className="describe" type="secondary">
              {MessageContent.OutLayoutDescribe}
            </Paragraph>
          </Col>
          <Col span={12} className="right-layout">
            {children}
          </Col>
        </Row>
      </Layout>
    </main>
  );
};
OutLayout.propTypes = {
  children: element.isRequired
};

export default OutLayout;
