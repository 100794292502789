import { DeleteFilled } from '@ant-design/icons';
import { Button, Col, Collapse, Input, Row, Space, Typography } from 'antd';
import Form from 'antd/lib/form';
import { get, isEmpty } from 'lodash';
import { bool, func, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useUploadFile from '../../hooks/useUploadFile';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { notificationError } from '../../utils/Notification';
import CustomizeCollapse from '../CustomizeCollapse';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import UploadImage from '../UploadImage';
import './styles.less';

export default function BannerItem({
  link: image,
  target: domain,
  index,
  setLoading,
  isNew,
  setShowAddNew
}) {
  const [file, handleUploadFile, handleReset] = useUploadFile(image);
  const [expand, setExpand] = useState(false);
  const listBanners = useSelector(state => {
    return get(state, 'configuration.banner.list', []);
  });
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ domain });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [domain]);

  const handleSaveBanner = values => {
    if (isEmpty(file.link)) {
      notificationError('Vui lòng nhập đầy đủ thông tin');
    } else {
      setLoading(true);
      const newData = [...listBanners];
      if (isNew) {
        newData.push({ type: file.type, link: file.link, target: values.domain });
      } else {
        newData[index] = {
          type: file.type,
          link: file.link.length ? file.link : image,
          target: values.domain
        };
      }
      dispatch(
        actions.updateConfiguration({
          data: {
            banner: newData
          },
          callback: () => {
            setTimeout(() => {
              setLoading(false);
              setShowAddNew(false);
            }, 500);
          }
        })
      );
    }
  };

  const handleDelete = e => {
    const newData = [...listBanners];
    newData.splice(index, 1);
    setLoading(true);
    dispatch(
      actions.updateConfiguration({
        data: {
          banner: newData
        },
        callback: () => {
          setTimeout(() => {
            setLoading(false);
            setShowAddNew(false);
          }, 500);
        }
      })
    );
    e.stopPropagation();
  };

  const header = (
    <Row justify="space-between" align="middle">
      <Space align="center" size="middle">
        {expand ? <ArrowUpIcon /> : <ArrowDownIcon />}
        <Typography.Text strong>
          {isNew ? 'New Slide' : `Slide ${index + 1}`}
        </Typography.Text>
      </Space>
      {!isNew && (
        <Col>
          <DeleteFilled onClick={handleDelete} />
        </Col>
      )}
    </Row>
  );
  const handleExpand = () => {
    setExpand(prevState => {
      return !prevState;
    });
  };

  return (
    <CustomizeCollapse onChange={handleExpand}>
      <Collapse.Panel showArrow={false} className="banner-header" header={header}>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <UploadImage
              isPreventCrop
              imgUrl={file.link}
              loading={file.isFetching}
              beforeUpload={handleUploadFile}
              handleReset={handleReset}
            />
          </Col>
          <Col className="gutter-row" span={12}>
            <Form form={form} onFinish={handleSaveBanner}>
              <Form.Item
                name="domain"
                initialValue={domain}
                rules={[{ required: true, message: 'Vui lòng nhập domain' }]}
              >
                <Input placeholder="domain" />
              </Form.Item>
              <Row justify="end">
                <Button htmlType="submit" className="banner-button" type="primary">
                  Lưu
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </Collapse.Panel>
    </CustomizeCollapse>
  );
}

BannerItem.propTypes = {
  target: string,
  index: number,
  link: string,
  setLoading: func,
  isNew: bool,
  setShowAddNew: func
};

BannerItem.defaultProps = {
  link: '',
  target: '',
  index: 0,
  setLoading: () => {},
  isNew: false,
  setShowAddNew: () => {}
};
