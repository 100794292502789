import firebase from 'firebase/app';
import 'firebase/messaging';
import 'firebase/auth';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { FCM_TOKEN } from '../contents/Constants';
import actions from '../redux/actions';
import { getStore } from '../App';
import { FIREBASE } from '../utils/domain';

const firebaseConfig = {
  apiKey: FIREBASE.CLOUD_MESSAGE.APIKEY,
  authDomain: FIREBASE.CLOUD_MESSAGE.AUTHDOMAIN,
  databaseURL: FIREBASE.CLOUD_MESSAGE.DATABASEURL,
  projectId: FIREBASE.CLOUD_MESSAGE.PROJECTID,
  storageBucket: FIREBASE.CLOUD_MESSAGE.STORAGEBUCKET,
  messagingSenderId: FIREBASE.CLOUD_MESSAGE.MESSAGINGSENDERID,
  appId: FIREBASE.CLOUD_MESSAGE.APPID
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app();
}

const checkBrowser = () => {
  if (firebase.messaging.isSupported()) {
    return true;
  }
  return false;
};

const messaging = () => {
  if (checkBrowser()) {
    return firebase.messaging();
  }
  return null;
};

if (checkBrowser()) {
  messaging().usePublicVapidKey(FIREBASE.CLOUD_MESSAGE.KEYPAIR);
}

const forwardingAction = ({ payload }) => {
  const saleOrderId = get(JSON.parse(payload), 'sale_order_id', '');
  if (isEmpty(saleOrderId)) {
    return;
  }
  getStore().dispatch(actions.notificationSaleOrder(saleOrderId));
};

const onForegroundMessage = async () => {
  messaging()
    .requestPermission()
    .then(async () => {
      const token = await messaging().getToken();
      window.localStorage.setItem(FCM_TOKEN, token);
    })
    .then(() => {
      navigator.serviceWorker.addEventListener('message', message => {
        const data = get(message, 'data');
        const dataMessage = get(data, 'firebase-messaging-msg-data.data');
        forwardingAction(dataMessage);
      });
    });
};

const uiConfigProps = {
  uiConfig: {
    signInFlow: 'popup',
    signInSuccessUrl: '/',
    signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
    callbacks: {
      signInSuccessWithAuthResult: authResult => {
        getStore().dispatch(actions.login(authResult));
      }
    },
    immediateFederatedRedirect: false
  },
  firebaseAuth: firebase.auth()
};

export { checkBrowser, onForegroundMessage, uiConfigProps };
