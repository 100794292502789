import React from 'react';
import Avatar from 'antd/lib/avatar';
import Button from 'antd/lib/button';
import Space from 'antd/lib/space';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import './style.less';
import LogoutOutlined from '@ant-design/icons/lib/icons/LogoutOutlined';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import actions from '../../redux/actions';
import { MessageContent } from '../../contents/Labels';

const UserInfo = () => {
  const dispatch = useDispatch();
  const profile = useSelector(state => {
    return get(state, 'profile.info');
  });
  const handleLogout = () => {
    return dispatch(actions.logout());
  };

  return (
    <div className="card">
      <Row gutter={[0, 20]}>
        <Col span={24}>
          <Space size="middle">
            <Avatar width={40} src={get(profile, 'avatar_url')} alt="logo" />
            <Title level={4} className="name">
              {get(profile, 'name')}
            </Title>
          </Space>
        </Col>
      </Row>
      <Row gutter={[0, 20]} align="middle" justify="center">
        <Col span={24}>
          <div className="content">
            <Title level={5} className="role">
              Role: ADMIN
            </Title>
            <Text className="text" type="secondary">
              {`${get(profile, 'email')}`}
            </Text>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Button
            icon={<LogoutOutlined />}
            onClick={handleLogout}
            block
            type="link"
            className="logout"
          >
            {MessageContent.Logout}
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default UserInfo;
