import React from 'react';
import { Tooltip, Button, Skeleton, Typography } from 'antd';
import moment from 'moment';
import isNumber from 'lodash/isNumber';
import { MessageContent } from '../../../contents/Labels';
import currency from '../../../utils/currency';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import { convertDollar, convertVND } from '../../../utils/convert';

const { Text } = Typography;

export default {
  draft: [
    {
      title: 'Ngày giao dịch',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return (
          <Text>{text ? moment(text).format('HH:mm:ss, DD/MM/YYYY') : '-'}</Text>
        );
      }
    },
    {
      title: 'Nội dung',
      dataIndex: 'deal_order_id',
      key: 'paymentCode',
      render: data => <Text>{data?.payment_code}</Text>
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'deal_order_id',
      key: 'orderCode',
      render: data => <Text>{data?.order_code}</Text>
    },
    {
      title: 'Số dư',
      dataIndex: 'deposit',
      key: 'balance',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <p>{`${currency.format(value)} đ`}</p>;
      }
    },
    {
      title: 'Giá trị mua deal',
      dataIndex: 'deal_order_id',
      key: 'amount',
      align: 'right',
      render: data => <p>{`${convertDollar(data?.amount)}`}</p>
    },
    {
      title: 'Số tiền đề xuất',
      dataIndex: 'deal_order_id',
      key: 'price_VND',
      align: 'right',
      render: data => {
        return (
          <Text className="total">{`${currency.format(
            data?.amount * data?.history?.rate
          )} đ`}</Text>
        );
      }
    },

    {
      title: 'Thông tin deal',
      dataIndex: 'deal_order_id',
      key: 'price_VND',
      render: data => {
        return (
          <Tooltip
            title={
              <ul>
                <li>rate: {convertVND(data?.history?.rate)}</li>
                <li>
                  Giá trị còn lại:
                  {convertDollar(
                    +data?.history?.price - data?.history?.price_in_used
                  )}
                </li>
              </ul>
            }
            color="#1D50FF"
          >
            {data?.history?.name}
          </Tooltip>
        );
      }
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      render: (_, record) => {
        return (
          <div>
            <Button
              type="primary"
              onClick={() => {
                return dispatch(
                  actions.confirmDeal({ purchase_transaction_id: record?._id })
                );
              }}
            >
              {MessageContent.Confirm}
            </Button>

            <Button
              type="danger"
              style={{ marginLeft: 10 }}
              onClick={() => {
                return dispatch(
                  actions.rejectDeal({ purchase_transaction_id: record?._id })
                );
              }}
            >
              {MessageContent.Reject}
            </Button>

            <Button
              type="dashed"
              style={{ marginLeft: 10 }}
              onClick={() => {
                return dispatch(actions.getDealDetail(record._id));
              }}
            >
              {MessageContent.Detail}
            </Button>
          </div>
        );
      }
    }
  ],
  buyngon_confirmed: [
    {
      title: 'Ngày giao dịch',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return (
          <Text>{text ? moment(text).format('HH:mm:ss, DD/MM/YYYY') : '-'}</Text>
        );
      }
    },
    {
      title: 'Ngày xác nhận',
      key: 'confirmed_time',
      dataIndex: 'confirmed_time',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return (
          <Text>{text ? moment(text).format('HH:mm:ss, DD/MM/YYYY') : '-'}</Text>
        );
      }
    },

    {
      title: 'Nội dung',
      dataIndex: 'deal_order_id',
      key: 'paymentCode',
      render: data => <Text>{data?.payment_code}</Text>
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'deal_order_id',
      key: 'orderCode',
      render: data => <Text>{data?.order_code}</Text>
    },
    {
      title: 'Giá trị mua deal',
      dataIndex: 'deal_order_id',
      key: 'amount',
      align: 'right',
      render: data => <p>{`${convertDollar(data?.amount)}`}</p>
    },
    {
      title: 'Số tiền đề xuất',
      dataIndex: 'deal_order_id',
      key: 'price_VND',
      align: 'right',
      render: data => {
        return (
          <Text className="total">{`${currency.format(
            data?.amount * data?.history?.rate
          )} đ`}</Text>
        );
      }
    },

    {
      title: 'Thông tin deal',
      dataIndex: 'deal_order_id',
      key: 'price_VND',
      render: data => {
        return (
          <Tooltip
            title={
              <ul>
                <li>rate: {convertVND(data?.history?.rate)}</li>
                <li>
                  Giá trị còn lại:
                  {convertDollar(
                    +data?.history?.price - data?.history?.price_in_used
                  )}
                </li>
              </ul>
            }
            color="#1D50FF"
          >
            {data?.history?.name}
          </Tooltip>
        );
      }
    },

    {
      title: 'Người xác nhận',
      dataIndex: 'confirmed_by',
      key: 'confirmed_by',
      render: data => <Text>{data?.[0]?.name}</Text>
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        return (
          <div>
            <Button
              type="dashed"
              style={{ marginLeft: 10 }}
              onClick={() => {
                return dispatch(actions.getDealDetail(record._id));
              }}
            >
              {MessageContent.Detail}
            </Button>
          </div>
        );
      }
    }
  ],
  buyngon_rejected: [
    {
      title: 'Ngày hủy',
      key: 'rejected_at',
      dataIndex: 'created_at',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return (
          <Text>{text ? moment(text).format('HH:mm:ss, DD/MM/YYYY') : '-'}</Text>
        );
      }
    },
    {
      title: 'Nội dung',
      dataIndex: 'deal_order_id',
      key: 'paymentCode',
      render: data => <Text>{data?.payment_code}</Text>
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'deal_order_id',
      key: 'orderCode',
      render: data => <Text>{data?.order_code}</Text>
    },
    {
      title: 'Giá trị mua deal',
      dataIndex: 'deal_order_id',
      key: 'amount',
      align: 'right',
      render: data => <p>{`${convertDollar(data?.amount)}`}</p>
    },
    {
      title: 'Số tiền đề xuất',
      dataIndex: 'deal_order_id',
      key: 'price_VND',
      align: 'right',
      render: data => {
        return (
          <Text className="total">{`${currency.format(
            data?.amount * data?.history?.rate
          )} đ`}</Text>
        );
      }
    },

    {
      title: 'Thông tin deal',
      dataIndex: 'deal_order_id',
      key: 'price_VND',
      render: data => {
        return (
          <Tooltip
            title={
              <ul>
                <li>rate: {convertVND(data?.history?.rate)}</li>
                <li>
                  Giá trị còn lại:
                  {convertDollar(
                    +data?.history?.price - data?.history?.price_in_used
                  )}
                </li>
              </ul>
            }
            color="#1D50FF"
          >
            {data?.history?.name}
          </Tooltip>
        );
      }
    },

    {
      title: 'Người từ chối',
      dataIndex: 'rejected_by',
      key: 'rejected_by',
      render: data => <Text>{data?.[0]?.name}</Text>
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record) => {
        return (
          <div>
            <Button
              type="dashed"
              style={{ marginLeft: 10 }}
              onClick={() => {
                return dispatch(actions.getDealDetail(record._id));
              }}
            >
              {MessageContent.Detail}
            </Button>
          </div>
        );
      }
    }
  ]
};
