import { Button, List, Spin } from 'antd';
import { get } from 'lodash';
import React, { useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import { useSelector } from 'react-redux';
import useDraggable from '../../hooks/useDraggable';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import BannerItem from './BannerItem';

export default function Banner() {
  const [loading, setLoading] = useState(false);
  const [showAddNew, setShowAddNew] = useState(false);
  const listBanners = useSelector(state => {
    return get(state, 'configuration.banner.list', []);
  });

  const { onDragEnd } = useDraggable(listBanners);

  const handleDragEnd = (fromIndex, toIndex) => {
    const result = onDragEnd(fromIndex, toIndex);
    dispatch(actions.draggableBanners(result));
  };

  const handleSavePriority = () => {
    setLoading(true);
    dispatch(
      actions.updateConfiguration({
        data: {
          banner: listBanners
        },
        callback: () => {
          setTimeout(() => {
            setLoading(false);
            setShowAddNew(false);
          }, 500);
        }
      })
    );
  };

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <div className="btn-action">
          <Button type="primary" onClick={handleSavePriority}>
            <b>Lưu thứ tự</b>
          </Button>
          {/* <div /> */}
          <Button
            onClick={() => {
              return setShowAddNew(true);
            }}
            className="save"
          >
            <b>+ Thêm slide</b>
          </Button>
        </div>
        <div className="dealngon-banner">
          <ReactDragListView
            onDragEnd={handleDragEnd}
            handleSelector=".ant-collapse-header"
            nodeSelector=".banner-item"
          >
            {showAddNew && (
              <BannerItem
                isNew
                setShowAddNew={setShowAddNew}
                setLoading={setLoading}
              />
            )}
            {(listBanners.length > 0 || !showAddNew) && (
              <List
                dataSource={listBanners}
                split={false}
                renderItem={(item, index) => {
                  return (
                    <List.Item className="banner-item">
                      <BannerItem
                        {...item}
                        key={index}
                        setLoading={setLoading}
                        setShowAddNew={setShowAddNew}
                        index={index}
                      />
                    </List.Item>
                  );
                }}
              />
            )}
          </ReactDragListView>
        </div>
      </div>
    </Spin>
  );
}
