import { all, takeLatest, put, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';

function* getShippingCategories() {
  try {
    const data = yield call(services.getShippingCategories);
    yield put({ type: TYPE.GET_SHIPPING_CATEGORIES_SUCCESS, data });
  } catch (error) {
    yield put({
      type: TYPE.GET_SHIPPING_CATEGORIES_FAILED,
      error
    });
  }
}

export default function* root() {
  yield all([takeLatest(TYPE.GET_SHIPPING_CATEGORIES, getShippingCategories)]);
}
