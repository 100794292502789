import get from 'lodash/get';
import TYPE from '../actions/TYPE';

const initialState = {
  list: {
    count: 0,
    accounts: []
  },
  detail: null
};

const account = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.LIST_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        list: {
          count: get(action, 'data.count', initialState.list.count),
          accounts: get(action, 'data.results', initialState.list.accounts)
        }
      };
    }

    case TYPE.LIST_ACCOUNTS_FAILED:
      return { ...state, list: initialState.list };

    case TYPE.CHANGE_STATUS_ACTIVITY_ACCOUNT_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          accounts: state.list.accounts.map(item => {
            return item._id !== get(action, 'data._id') ? item : get(action, 'data');
          })
        }
      };
    }

    case TYPE.SEARCH_ACCOUNT_SUCCESS: {
      return {
        ...state,
        list: {
          count: get(action, 'data.count', initialState.list.count),
          accounts: get(action, 'data.results', initialState.list.accounts)
        }
      };
    }

    default:
      return state;
  }
};

export default account;
