import TYPE from '../actions/TYPE';

const initialState = {
  list: {
    count: 0,
    transactions: []
  },
  listDealOfUser: [],
  currentDealOrder: null,
  detail: null,
  confirm: {
    isLoading: false
  },
  isLoading: false
};

const transactionDeal = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.CONFIRM_DEAL_ORDER_SUCCESS:
      return {
        ...state,
        currentDealOrder: null
      };

    case TYPE.GET_LIST_DEAL_BY_USER_SUCCESS:
      return {
        ...state,
        listDealOfUser: action.data
      };

    case TYPE.CLEAR_LIST_DEAL_BY_USER:
      return {
        ...state,
        listDealOfUser: []
      };

    case TYPE.GET_CURRENT_DEAL_ORDER_SUCCESS:
    case TYPE.DELETE_ITEM_DEAL_ORDER_SUCCESS:
    case TYPE.ADD_ITEM_DEAL_ORDER_SUCCESS:
      return {
        ...state,
        currentDealOrder: action.data
      };

    case TYPE.LIST_DEALS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case TYPE.LIST_DEALS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        list: {
          count: action.count,
          transactions: action.data
        }
      };
    }
    case TYPE.DETAIL_DEAL_SUCCESS: {
      return {
        ...state,
        detail: action.data
      };
    }

    case TYPE.CLEAR_DEAL_DETAIL: {
      return {
        ...state,
        detail: null
      };
    }

    case TYPE.CONFIRM_DEAL_SUCCESS: {
      return {
        ...state,
        list: {
          count: state.list.count - 1,
          transactions: state.list.transactions.filter(
            item => item?._id !== action.data?.purchase_transaction_id
          )
        }
      };
    }
    case TYPE.REJECT_DEAL_SUCCESS: {
      return {
        ...state,
        list: {
          count: state.list.count - 1,
          transactions: state.list.transactions.filter(
            item => item?._id !== action.data?.purchase_transaction_id
          )
        }
      };
    }
    case TYPE.LIST_DEPOSITS_SUCCESS: {
      return {
        ...state,
        list: {
          ...state.list,
          transactions: action.data
        }
      };
    }

    default:
      return state;
  }
};

export default transactionDeal;
