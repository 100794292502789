import { get } from 'lodash';
import TYPE from '../actions/TYPE';

const initialState = {
  list: [],
  detail: {},
  mode: null,
  isLoading: false
};

const campaign = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.LIST_CAMPAIGN_SUCCESS:
      return {
        ...state,
        list: get(action, 'data', [])
      };

    case TYPE.CHANGE_MODE_CAMPAIGN:
      return {
        ...state,
        mode: action.payload
      };

    case TYPE.OPEN_CAMPAIGN_DETAIL:
      return {
        ...state,
        detail: action.payload
      };

    case TYPE.GET_CAMPAIGN_HISTORY_SUCCESS:
      return {
        ...state,
        detail: {
          ...state.detail,
          history: action.payload
        }
      };
    default:
      return state;
  }
};

export default campaign;
