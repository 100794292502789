import { Button, Spin } from 'antd';
import List from 'antd/lib/list';
import { get } from 'lodash';
import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import ReactDragListView from 'react-drag-listview';
import useDraggable from '../../hooks/useDraggable';
import CategoryItem from './CategoryItem';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';

export default function DealNgonCategory() {
  const [loading, setLoading] = useState(false);
  const [showNewCategory, setShowNewCategory] = useState(false);
  const listCategories = useSelector(state => {
    return get(state, 'dealngon.categories', []);
  }, shallowEqual);

  const { onDragEnd } = useDraggable(listCategories);

  const handleDragEnd = (fromIndex, toIndex) => {
    const result = onDragEnd(fromIndex, toIndex);
    dispatch(actions.draggableCategories(result));
  };

  const handleSavePriority = () => {
    setLoading(true);
    dispatch(
      actions.updateCategories({
        data: listCategories,
        callback: () => {
          setTimeout(() => {
            setLoading(false);
            setShowNewCategory(false);
          }, 500);
        }
      })
    );
  };

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <div className="btn-action">
          <Button type="primary" onClick={handleSavePriority}>
            <b>Lưu thứ tự</b>
          </Button>
          <Button
            onClick={() => {
              return setShowNewCategory(true);
            }}
            className="save"
          >
            <b>+ Thêm category</b>
          </Button>
        </div>
      </div>
      <div className="dealngon-category">
        <ReactDragListView
          onDragEnd={handleDragEnd}
          nodeSelector=".category-item"
          handleSelector=".ant-collapse-header"
        >
          {showNewCategory && (
            <CategoryItem isNew setShowNewCategory={setShowNewCategory} />
          )}
          {(listCategories.length > 0 || !showNewCategory) && (
            <List
              split={false}
              dataSource={listCategories}
              renderItem={({ _id, ...restProps }) => (
                <List.Item key={_id} className="category-item">
                  <CategoryItem {...restProps} key={_id} id={_id} />
                </List.Item>
              )}
            />
          )}
        </ReactDragListView>
      </div>
    </Spin>
  );
}
