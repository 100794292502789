export default {
  CREATED_201: 201,
  SUCCESS_200: 200,
  NOT_FOUND: 400,
  AUTHORIZED_401: 401,
  FORBIDDENT_403: 403,
  METHOD_NOT_ALLOWED_405: 405,
  HAVE_NOT_ACCESS_406: 406,
  SYSTEM_ERROR: 500,
  ACTION_NOT_ACCESS_409: 409
};
