import { Spin, Tag } from 'antd';
import Button from 'antd/lib/button';
import get from 'lodash/get';
import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizeTable from '../../components/CustomizeTable/CustomizeTable';
import FilterAndSearch from '../../components/FilterAndSearch';
import {
  CAMPAIGN_MODE,
  CAMPAIGN_PROMOTION,
  LIMIT_PAGE_DEFAULT
} from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import CreateCampaign from './Create';
import DetailCampaign from './Edit';

export const SellerProfileContext = createContext(null);

const Campaign = () => {
  const { count, campaigns } = useSelector(state => {
    const response = get(state, 'campaign.list', []);
    return {
      count: response.length,
      campaigns: response.filter(i => i.state !== 'wait')
    };
  });

  const loading = useSelector(state => {
    return get(state, 'seller.isLoading', false);
  });
  const [page, setPage] = useState(1);

  const mode = useSelector(state => state?.campaign?.mode);

  useEffect(() => {
    dispatch(
      actions.listCampaign({
        limit: LIMIT_PAGE_DEFAULT
      })
    );
  }, [page]);

  const handleChangePage = pageIndex => {
    return setPage(pageIndex);
  };

  const columns = [
    {
      title: MessageContent.Status,
      key: 'state',
      dataIndex: 'state',
      width: '8%',
      align: 'center',
      fixed: true,
      render: (state, record) => (
        <Tag
          onClick={() => {
            dispatch(
              actions.updateCampaign({
                id: record._id,
                state: record.state !== 'activate' ? 'activate' : 'closed'
              })
            );
          }}
          color={state === 'activate' ? 'success' : 'warning'}
        >
          {state}
        </Tag>
      )
    },
    {
      title: MessageContent.Name,
      dataIndex: 'name',
      key: 'name',
      render: text => {
        return (
          <Button type="link" className="name" onClick={() => {}}>
            {text}
          </Button>
        );
      }
    },
    {
      title: MessageContent.Type,
      dataIndex: 'type',
      key: 'type',
      render: text => {
        return (
          <>
            <Tag color="success">
              {CAMPAIGN_PROMOTION.TYPE.DECEMBER_BEST_DEAL.value === text
                ? CAMPAIGN_PROMOTION.TYPE.DECEMBER_BEST_DEAL.label
                : null}
            </Tag>
          </>
        );
      }
    },
    {
      title: MessageContent.Action,
      key: 'action',
      align: 'center',
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              dispatch(actions.changeModeCampaign(CAMPAIGN_MODE.detail));
              dispatch(actions.openCampaignDetail(record));
            }}
            type="primary"
          >
            {MessageContent.Detail}
          </Button>
        );
      }
    }
  ];

  return (
    <SellerProfileContext.Provider>
      <Spin spinning={loading}>
        <CustomizeTable
          title={MessageContent.Campaign}
          data={campaigns}
          columns={columns}
          count={count}
          currentPage={page}
          onPageChange={handleChangePage}
          filterAndSearch={<FilterAndSearch.Campaign />}
        />
      </Spin>
      {mode === CAMPAIGN_MODE.create && <CreateCampaign />}
      {mode === CAMPAIGN_MODE.detail && <DetailCampaign />}
    </SellerProfileContext.Provider>
  );
};

export default Campaign;
