import { Collapse } from 'antd';
import { node } from 'prop-types';
import React from 'react';
import './styles.less';

export default function CustomizeCollapse({ children, ...restProps }) {
  return <Collapse {...restProps}>{children}</Collapse>;
}

CustomizeCollapse.propTypes = {
  children: node
};

CustomizeCollapse.defaultProps = {
  children: null
};
