import { Drawer, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import { get, map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizeTable from '../../../../components/CustomizeTable/CustomizeTable';
import { MessageContent } from '../../../../contents/Labels';
import actions from '../../../../redux/actions';
import currency from '../../../../utils/currency';
import dispatch from '../../../../utils/dispatch';

const WalletDetail = () => {
  const [page, setPage] = useState(1);
  const { userId, userDepositList } = useSelector(state => get(state, 'customer'));
  const data = map(userDepositList, (item, index) => {
    return { key: index, ...item };
  });
  const loading = useSelector(
    state => state.common.loadingImprove?.getDeliveryAddress
  );

  useEffect(() => {
    if (userId) {
      dispatch(actions.getDeliveryAddress(userId));
    }
  }, [userId]);

  const handleClose = () => {
    dispatch(actions.clearInfoWallet());
  };
  const columns = [
    {
      title: 'Ngày',
      align: 'center',
      width: '20%',
      fixed: true,
      render: value => {
        const { date } = value;
        return <Text key={date}>{date && moment(date).format('DD/MM/YYYY')}</Text>;
      }
    },

    {
      title: 'Nội dung',
      align: 'left',
      render: value => {
        const { content } = value;
        return <Text key={content}>{content}</Text>;
      }
    },
    {
      title: 'Giao dịch',
      align: 'right',
      amount_after: 'amount',
      render: value => {
        const { amount } = value;
        const text = `${currency.format(amount)} đ`;
        return <Text key={amount}>{text}</Text>;
      }
    },
    {
      title: 'Số xu còn lại',
      align: 'right',
      render: value => {
        const { amount_after } = value;
        const text = `${currency.format(amount_after)} đ`;
        return <Text key={amount_after}>{text}</Text>;
      }
    }
  ];
  return (
    <Drawer onClose={handleClose} width="70vw" visible={!!userId} placement="left">
      <Spin spinning={loading}>
        <CustomizeTable
          data={data}
          title={MessageContent.WalletDetail}
          columns={columns}
          currentPage={page}
          onPageChange={e => setPage(e)}
        />
      </Spin>
    </Drawer>
  );
};

export default WalletDetail;
