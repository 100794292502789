import { all, takeLatest, call, put } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';

function* list() {
  try {
    const { data } = yield call(services.marketplace.topic.list);
    yield put({ type: TYPE.LIST_TOPIC_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.LIST_TOPIC_FAILED, error });
  }
}

export default function* root() {
  yield all([takeLatest(TYPE.LIST_TOPIC, list)]);
}
