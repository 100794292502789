import React from 'react';
import { bool, number, string } from 'prop-types';
import { Tooltip } from 'antd';
import './style.less';
import { convertDollar, convertVND } from '../../../../../utils/convert';

export default function PriceItem({ title, vnd, isUsd }) {
  const convertCurrency = isUsd ? convertDollar : convertVND;
  return (
    <div className="price-item">
      <div className="title">{title}</div>
      <div className="value">
        <Tooltip placement="topLeft">{convertCurrency(vnd)}</Tooltip>
      </div>
    </div>
  );
}

PriceItem.propTypes = {
  title: string,
  vnd: number,
  isUsd: bool
};

PriceItem.defaultProps = {
  title: '',
  vnd: 0,
  isUsd: false
};
