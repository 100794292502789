import { Tag } from 'antd';
import Spin from 'antd/lib/spin';
import Text from 'antd/lib/typography/Text';
import { get, map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LOGS } from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import actions from '../../redux/actions';
import { convertVND } from '../../utils/convert';
import CustomizeTable from '../CustomizeTable/CustomizeTable';
import './style.less';

function AutoConfirmSaleOrder() {
  const dispatch = useDispatch();
  const [logs, setLogs] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    dispatch(
      actions.getLogCancel({
        data: { type: LOGS.AUTO_CONFIRM_SALE_ORDER_TRAN, page },
        callback: params => {
          setTimeout(() => {
            setLoading(false);
            setLogs(params.data);
          }, 1 * 1000);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const count = get(logs, 'count', 0);
  const data = map(logs?.results, item => ({
    key: item?._id,
    type: item?.type,
    success: item?.result?.success,
    error: item?.result?.error,
    order_name: item?.result?.message?.order_name,
    amount: item?.result?.message?.amount,
    description: item?.result?.message?.description,
    created_at: item?.created_at
  }));

  const columns = [
    {
      title: MessageContent.CreatedAt,
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => {
        return <Text>{moment(text).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'Mã đơn hàng',
      dataIndex: 'order_name',
      key: 'order_name',
      render: text => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: 'Nội dung giao dịch',
      dataIndex: 'description',
      key: 'description',
      render: text => {
        return <Text>{text}</Text>;
      }
    },
    {
      title: 'Số tiền giao dịch',
      dataIndex: 'amount',
      key: 'amount',
      render: text => {
        return <Text>{convertVND(text)}</Text>;
      }
    },
    {
      title: 'Lý do',
      dataIndex: 'error',
      key: 'error',
      render: (text, record) => {
        return <Text>{record?.success ? '' : text}</Text>;
      }
    },
    {
      title: MessageContent.Status,
      key: 'success',
      dataIndex: 'success',
      width: '8%',
      align: 'center',
      fixed: true,
      render: state => (
        <Tag color={state ? 'success' : 'warning'}>
          {state ? 'Thành công' : 'Thất bại'}
        </Tag>
      )
    }
  ];

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme log">
        <CustomizeTable
          columns={columns}
          data={data}
          count={count}
          onPageChange={setPage}
          currentPage={page}
        />
      </div>
    </Spin>
  );
}

export default AutoConfirmSaleOrder;
