import { useEffect, useState } from 'react';
import { handleUpload } from '../utils/helpers';

export default function useUploadFile(imgUrl) {
  const [file, setFile] = useState({ link: '', isFetching: false });

  useEffect(() => {
    setFile({ link: imgUrl, isFetching: false });
  }, [imgUrl]);

  const handleUploadFile = _file => {
    setFile(prevState => {
      return { ...prevState, isFetching: true };
    });
    handleUpload(_file)
      .then(({ data, ...fields }) => {
        setFile({ link: data, ...fields, isFetching: false });
      })
      .catch(() => {
        setFile(prevState => {
          return { ...prevState, isFetching: false };
        });
      });
  };
  const handleReset = () => {
    setFile(prevState => {
      return { ...prevState, link: '' };
    });
  };

  return [file, handleUploadFile, handleReset, setFile];
}
