import { all, takeLatest, put, call } from 'redux-saga/effects';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import TYPE from '../actions/TYPE';
import services from '../../services';
import { notificationSuccess } from '../../utils/Notification';

import { getListDepositForTransactionDeal } from './commonSaga';

function* getCurrentDealOrder({ payload }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getCurrentDealOrder' });
    const { data } = yield call(
      services.marketplace.transaction.getCurrentDealOrder,
      payload
    );
    yield put({ type: TYPE.GET_CURRENT_DEAL_ORDER_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_CURRENT_DEAL_ORDER_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getCurrentDealOrder' });
  }
}

function* confirmDealOrder({ payload }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'confirmDealOrder' });
    const { data, success, message } = yield call(
      services.marketplace.transaction.confirmDealOrder,
      payload
    );
    if (!success) throw new Error(message);
    yield put({ type: TYPE.CONFIRM_DEAL_ORDER_SUCCESS, data });
    notificationSuccess('Xác nhận đơn hàng thành công');
  } catch (error) {
    yield put({ type: TYPE.CONFIRM_DEAL_ORDER_FAILED, error });
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'confirmDealOrder' });
  }
}

function* getListDealByUser({ payload }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getListDealByUser' });
    const { data } = yield call(
      services.marketplace.transaction.getListDealByUser,
      payload
    );
    yield put({ type: TYPE.GET_LIST_DEAL_BY_USER_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_DEAL_BY_USER_FAILED, error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getListDealByUser' });
  }
}

function* deleteItemDealOrder({ payload }) {
  try {
    const { data } = yield call(
      services.marketplace.transaction.deleteItemDealOrder,
      payload
    );
    yield put({ type: TYPE.DELETE_ITEM_DEAL_ORDER_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.DELETE_ITEM_DEAL_ORDER_FAILED, error });
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
  }
}

function* addItemDealOrder({ payload }) {
  try {
    const { data } = yield call(
      services.marketplace.transaction.addItemDealOrder,
      payload
    );
    yield put({ type: TYPE.ADD_ITEM_DEAL_ORDER_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.ADD_ITEM_DEAL_ORDER_FAILED, error });
  } finally {
    yield put({ type: TYPE.OFF_LOADING });
  }
}

function* list(action) {
  try {
    const cleanedParams = pickBy(action.params, identity);

    const {
      data: { results, count }
    } = yield call(services.marketplace.transaction.list, cleanedParams);
    yield put({
      type: TYPE.LIST_DEALS_SUCCESS,
      data: results,
      count
    });

    yield getListDepositForTransactionDeal();
  } catch (err) {
    yield put({
      type: TYPE.LIST_DEALS_FAILED,
      message: err
    });
  }
}

function* detail(action) {
  try {
    const { data } = yield call(services.marketplace.transaction.detail, action.id);
    yield put({
      type: TYPE.DETAIL_DEAL_SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: TYPE.DETAIL_DEAL_FAILED,
      message: err
    });
  }
}

function* confirm({ payload }) {
  try {
    const data = yield call(services.marketplace.transaction.confirm, payload);
    if (data?.success) {
      yield put({ type: TYPE.CONFIRM_DEAL_SUCCESS, data: payload });
      notificationSuccess('Xác nhận mua deal thành công');
    }
  } catch (error) {
    yield put({ type: TYPE.CONFIRM_DEAL_FAILED, error });
  }
}

function* reject({ payload }) {
  try {
    yield call(services.marketplace.transaction.reject, payload);
    yield put({ type: TYPE.REJECT_DEAL_SUCCESS, data: payload });
    notificationSuccess('Từ chối thành công');
  } catch (error) {
    yield put({ type: TYPE.REFRESH_TOKEN_FAILED, error });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_CURRENT_DEAL_ORDER, getCurrentDealOrder),
    takeLatest(TYPE.CONFIRM_DEAL_ORDER, confirmDealOrder),
    takeLatest(TYPE.GET_LIST_DEAL_BY_USER, getListDealByUser),
    takeLatest(TYPE.DELETE_ITEM_DEAL_ORDER, deleteItemDealOrder),
    takeLatest(TYPE.ADD_ITEM_DEAL_ORDER, addItemDealOrder),
    takeLatest(TYPE.LIST_DEALS, list),
    takeLatest(TYPE.DETAIL_DEAL, detail),
    takeLatest(TYPE.CONFIRM_DEAL, confirm),
    takeLatest(TYPE.REJECT_DEAL, reject)
  ]);
}
