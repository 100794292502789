import { find, findIndex } from 'lodash';
import TYPE from '../actions/TYPE';

const initialValues = {
  tags: [],
  websites: [],
  categories: [],
  icons: [],
  promotionUs: [],
  loading: false
};

const dealngon = (state = initialValues, action = {}) => {
  switch (action.type) {
    case TYPE.UPDATE_CATEGORIES_SUCCESS:
    case TYPE.DRAGGABLE_CATEGORIES:
      return {
        ...state,
        categories: action.payload
      };
    case TYPE.ENABLE_LOADING:
      return {
        ...state,
        loading: true
      };
    case TYPE.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };
    case TYPE.GET_LIST_TAGS_SUCCESS:
      return {
        ...state,
        tags: action.payload
      };
    case TYPE.CREATE_TAG_SUCCESS:
      return {
        ...state,
        tags: [...state.tags, action.payload]
      };
    case TYPE.UPDATE_TAG_SUCCESS: {
      const index = findIndex(state.tags, { _id: action.payload._id });
      const newState = { ...state };
      if (index > -1) {
        newState.tags[index] = action.payload;
      }
      return newState;
    }
    case TYPE.GET_LIST_WEBSITES_SUCCESS:
      return {
        ...state,
        websites: action.payload
      };
    case TYPE.CREATE_WEBSITE_SUCCESS:
      return {
        ...state,
        websites: [...state.websites, action.payload]
      };
    case TYPE.UPDATE_WEBSITE_SUCCESS: {
      const index = findIndex(state.websites, { _id: action.payload._id });
      const newWebsites = [...state.websites];
      if (index > -1) {
        if (action.payload.status) {
          // Update
          newWebsites[index] = {
            ...action.payload,
            icon: [{ _id: action.payload.icon }],
            tags: action.payload.tags.map(item => {
              return { _id: item };
            })
          };
        } else {
          // Delete
          newWebsites.splice(index, 1);
        }
      }
      return {
        ...state,
        websites: newWebsites
      };
    }
    case TYPE.GET_LIST_DN_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: action.payload || []
      };
    case TYPE.CREATE_DN_CATEGORY_SUCCESS: {
      const newValue = { ...action.payload };
      const iconObj = find(state.icons, { _id: action.payload.icon });
      newValue.icon = [iconObj];
      return {
        ...state,
        categories: [...state.categories, newValue]
      };
    }

    case TYPE.UPDATE_DN_CATEGORY_SUCCESS: {
      const index = findIndex(state.categories, { _id: action.payload._id });
      const newState = [...state.categories];
      const iconObj = find(state.icons, { _id: action.payload.icon });
      if (index > -1) {
        if (action.payload.status) {
          newState[index] = {
            ...action.payload,
            icon: [iconObj],
            tags_exclude: action.payload.tags_exclude.map(item => {
              return { _id: item };
            }),
            tags_include: action.payload.tags_include.map(item => {
              return { _id: item };
            })
          };
        } else {
          newState.splice(index, 1);
        }
      }
      return {
        ...state,
        categories: newState
      };
    }
    case TYPE.UPDATE_ICON_CATEGORIES: {
      const index = findIndex(state.categories, { _id: action.payload._id });
      const newState = [...state.categories];
      const iconObj = find(state.icons, { _id: action.payload.icon });

      newState[index] = {
        ...action.payload,
        icon: [iconObj]
      };

      return {
        ...state,
        categories: newState
      };
    }
    case TYPE.GET_LIST_ICONS_OTHER_SUCCESS:
      return {
        ...state,
        icons: action.payload
      };

    case TYPE.GET_LIST_ICONS_SUCCESS:
      return {
        ...state,
        icons: action.payload
      };
    case TYPE.CREATE_ICON_SUCCESS:
      return {
        ...state,
        icons: [...state.icons, action.payload]
      };

    // case TYPE.DELETE_ICON_SUCCESS:
    //   return {
    //     ...state,
    //     icons: [...state.icons].filter(item => item._id !== action.payload)
    //   };

    case TYPE.DELETE_TAG_SUCCESS:
      return {
        ...state,
        tags: [...state.tags].filter(item => {
          return item._id !== action.payload;
        })
      };

    case TYPE.GET_PROMOTION_US_SUCCESS:
      return {
        ...state,
        promotionUs: action.payload
      };

    case TYPE.DELETE_PROMOTION_US_SUCCESS:
      return {
        ...state,
        promotionUs: [
          ...state.promotionUs.filter(item => item._id !== action.payload)
        ]
      };
    case TYPE.CREATE_PROMOTION_US_SUCCESS:
      return {
        ...state,
        promotionUs: [...state.promotionUs, action.payload]
      };
    case TYPE.UPDATE_PROMOTION_US_SUCCESS:
      const newPromotionUs = state.promotionUs.map(item => {
        if (item._id === action.payload._id) return action.payload;
        return item;
      });

      return {
        ...state,
        promotionUs: newPromotionUs
      };
    default:
      return state;
  }
};

export default dealngon;
