import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import {
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  Select,
  Table,
  Tag,
  Tooltip,
  Typography
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { Option } from 'antd/lib/mentions';
import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CAMPAIGN_MODE, CAMPAIGN_PROMOTION } from '../../../contents/Constants';
import actions from '../../../redux/actions';

const { Text } = Typography;
export default function DetailCampaign() {
  const mode = useSelector(state => state.campaign.mode, shallowEqual);
  const campaign = useSelector(state => state.campaign.detail, shallowEqual);
  const dispatch = useDispatch();

  const switchComponent = componentType => {
    switch (componentType) {
      case 'number':
        return <InputNumber min={1} />;
      case 'textarea':
        return <TextArea rows={5} />;
      default:
        return <Input />;
    }
  };

  useEffect(() => {
    dispatch(actions.getCampaignHistory(campaign._id));
  }, [campaign._id, dispatch]);

  const emails = (campaign?.condition?.emails || []).map(i => ({ name: i }));

  const columns = [
    {
      title: 'Danh sách Email',
      key: 'name',
      dataIndex: 'name'
    }
  ];

  const saleOrderColumns = [
    {
      title: 'Ngày giao dịch',
      key: 'createdAt',
      dataIndex: 'info',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return (
          <Text>
            {moment(text?.sale_order?.confirmed_time).format('HH:mm:ss, DD/MM/YYYY')}
          </Text>
        );
      }
    },
    {
      title: 'Ngày xác nhận',
      key: 'approved_time',
      dataIndex: 'info',
      align: 'center',
      width: '20%',
      fixed: true,
      render: text => {
        return (
          <Text>
            {moment(text?.sale_order?.approved_time).format('HH:mm:ss, DD/MM/YYYY')}
          </Text>
        );
      }
    },
    {
      title: 'Nội dung chuyển khoản',
      key: 'payment_code',
      dataIndex: 'info',
      render: text => {
        return <Text>{text?.sale_order?.payment_code}</Text>;
      }
    },
    {
      title: 'Tên khách hàng',
      key: 'name',
      dataIndex: 'info',
      render: text => {
        return <Tooltip title={text?.user?.email}>{text?.user?.name}</Tooltip>;
      }
    },
    {
      title: 'Sản phẩm khuyến mãi',
      key: 'campaign',
      dataIndex: 'info',
      align: 'center',
      render: text => {
        const promotionLine = (text?.sale_order?.quotation_list || []).filter(i =>
          (campaign?.condition?.websites || []).includes(i?.link)
        );
        return (
          <Tooltip title={!isEmpty(promotionLine) ? promotionLine?.[0]?.link : null}>
            <Tag color={!isEmpty(promotionLine) ? '#2db7f5' : '#f50'}>
              {!isEmpty(promotionLine) ? <CheckOutlined /> : <CloseOutlined />}
            </Tag>
          </Tooltip>
        );
      }
    }
  ];
  return (
    <Drawer
      className="drawer"
      width="70vw"
      title="Tạo chiến dịch mới"
      placement="right"
      closeIcon={
        <CloseOutlined
          onClick={() => dispatch(actions.changeModeCampaign(CAMPAIGN_MODE.off))}
        />
      }
      visible={mode === CAMPAIGN_MODE.detail}
    >
      <Form
        style={{ marginTop: 24 }}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        initialValues={{
          ...campaign,
          ...campaign.condition
        }}
        autoComplete="off"
      >
        <Form.Item
          label="Tên"
          name="name"
          rules={[{ required: true, message: 'Nhập thông tin này' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item name="type" label="Phân loại" rules={[{ required: true }]}>
          <Select disabled allowClear defaultValue={campaign.type}>
            <Option value={CAMPAIGN_PROMOTION.TYPE.DECEMBER_BEST_DEAL.value}>
              {CAMPAIGN_PROMOTION.TYPE.DECEMBER_BEST_DEAL.label}
            </Option>
          </Select>
        </Form.Item>

        <Divider
          orientation="left"
          plain
          style={{ marginTop: 50, fontStyle: 'italic' }}
        >
          Điều kiện
        </Divider>
        {(CAMPAIGN_PROMOTION.CONDITION[campaign.type] || [])
          .filter(i => i.value !== 'emails')
          .map(i => (
            <>
              <Form.Item
                label={i?.label}
                name={i?.value}
                rules={[{ required: true, message: 'Nhập thông tin này' }]}
              >
                {switchComponent(i?.component)}
              </Form.Item>
            </>
          ))}

        <Table
          size="small"
          bordered
          className="table"
          pagination={{
            defaultPageSize: 5
          }}
          columns={columns}
          dataSource={emails}
        />

        <Table
          size="small"
          bordered
          className="table"
          pagination={{
            defaultPageSize: 5
          }}
          columns={saleOrderColumns}
          dataSource={campaign?.history}
        />
      </Form>
    </Drawer>
  );
}
