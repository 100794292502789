import TYPE from '../actions/TYPE';

const initialState = {
  loading: false,
  iconLoading: false,
  loadingImprove: {},
  notification: null
};

const common = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.ENABLE_LOADING_IMPROVE:
      return {
        ...state,
        loadingImprove: { ...state.loadingImprove, [action.target]: true }
      };
    case TYPE.DISABLE_LOADING_IMPROVE:
      return {
        ...state,
        loadingImprove: { ...state.loadingImprove, [action.target]: false }
      };

    case TYPE.GET_LIST_ICONS_OTHER:
    case TYPE.DELETE_ICON:
      return {
        ...state,
        iconLoading: true
      };

    case TYPE.GET_LIST_ICONS_OTHER_SUCCESS:
    case TYPE.DELETE_ICON_SUCCESS:
      return {
        ...state,
        iconLoading: false
      };

    case TYPE.GET_LIST_ICONS_OTHER_FAILED:
    case TYPE.DELETE_ICON_FAILED:
      return {
        ...state,
        iconLoading: false
      };

    case TYPE.LOADING:
      return {
        ...state,
        loading: true
      };
    case TYPE.OFF_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};

export default common;
