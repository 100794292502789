import { Spin } from 'antd';
import { get, map } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizeTable from '../../components/CustomizeTable/CustomizeTable';
import FilterAndSearch from '../../components/FilterAndSearch';
import { LIMIT_PAGE_DEFAULT, WITHDRAWAL_STATUS } from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import columnData from './columnData';
import WithdrawalDetail from './WithdrawalDetail';

export default function Withdrawal() {
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState(WITHDRAWAL_STATUS.draft.key);
  const activeTabRef = useRef(WITHDRAWAL_STATUS.draft.key);
  const pageIndex = activeTabRef.current === activeTab ? page : 1;

  useEffect(() => {
    activeTabRef.current = activeTab;
    setPage(pageIndex);

    dispatch(
      actions.getWithdrawal({
        state: activeTab,
        page: pageIndex,
        limit: LIMIT_PAGE_DEFAULT
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, activeTab]);

  const { count, withdrawals } = useSelector(state => {
    return get(state, 'withdrawal.list', { count: 0, withdrawals: [] });
  });
  const loading = useSelector(state => {
    return get(state, 'withdrawal.isLoading', false);
  });
  const data = map(withdrawals, item => {
    return { key: item._id, ...item };
  });

  const handleChangePage = value => {
    return setPage(value);
  };

  const onRefresh = () => {
    dispatch(
      actions.getWithdrawal({
        state: activeTab,
        page: pageIndex,
        limit: LIMIT_PAGE_DEFAULT
      })
    );
  };

  return (
    <Spin spinning={loading}>
      <CustomizeTable
        title={MessageContent.WithdrawalRequest}
        columns={columnData[activeTab]}
        data={data}
        count={count}
        currentPage={page}
        onPageChange={handleChangePage}
        filterAndSearch={<FilterAndSearch.Refresh onRefresh={onRefresh} />}
        categoryTabs={WITHDRAWAL_STATUS.list}
        activeCategoryTab={activeTab}
        onChangeCategoryTab={setActiveTab}
      />
      <WithdrawalDetail />
    </Spin>
  );
}
