import React from 'react';
import Button from 'antd/lib/button';
import notification from 'antd/lib/notification';
import { MessageContent } from '../contents/Labels';

export const notificationSuccess = (message, description, duration = 2) => {
  return notification.success({ message, description, duration });
};

export const notificationError = (
  message,
  description,
  showButton,
  duration = 2
) => {
  return notification.error({
    message,
    description,
    duration,
    btn: showButton ? (
      <Button
        type="primary"
        onClick={() => {
          return window.location.reload();
        }}
      >
        {MessageContent.ReloadPage}
      </Button>
    ) : null
  });
};
