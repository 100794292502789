import { call, put, takeLatest, all } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';

function* getAllProvince() {
  try {
    const { data } = yield call(services.getAllProvince);
    yield put({ type: TYPE.GET_ALL_PROVINCE_SUCCESS, data });
  } catch (err) {
    yield put({
      type: TYPE.GET_ALL_PROVINCE_FAILED,
      message: err
    });
  }
}

function* getAllDistrict(action) {
  try {
    const { provinceId } = action;
    const { data } = yield call(services.getAllDistrict, provinceId);
    yield put({ type: TYPE.GET_ALL_DISTRICT_SUCCESS, data });
  } catch (err) {
    yield put({
      type: TYPE.GET_ALL_DISTRICT_FAILED,
      message: err
    });
  }
}

function* getAllWard(action) {
  try {
    const { provinceId, districtId } = action;
    const { data } = yield call(services.getAllWard, provinceId, districtId);
    yield put({ type: TYPE.GET_ALL_WARD_SUCCESS, data });
  } catch (err) {
    yield put({
      type: TYPE.GET_ALL_WARD_FAILED,
      message: err
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_ALL_PROVINCE, getAllProvince),
    takeLatest(TYPE.GET_ALL_DISTRICT, getAllDistrict),
    takeLatest(TYPE.GET_ALL_WARD, getAllWard)
  ]);
}
