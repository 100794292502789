import React from 'react';
import { Button, Typography } from 'antd';
import currency from '../../../utils/currency';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';

const { Text } = Typography;
const data = [
  {
    title: 'Tên deal',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Tên người bán',
    dataIndex: 'seller_profile',
    key: 'seller_profile',
    render: profile => {
      return <Text>{profile?.name}</Text>;
    }
  },
  {
    title: 'Website',
    dataIndex: 'website',
    key: 'website',
    render: website => website?.[1]
  },
  {
    title: 'Category',
    dataIndex: 'product_productcategory',
    key: 'product_productcategory',
    render: product_productcategory => {
      return <Text>{product_productcategory?.translate}</Text>;
    }
  },

  {
    title: 'Avatar',
    dataIndex: 'avatar',
    key: 'avatar',
    render: value => {
      return <img src={value} style={{ width: 50, height: 50 }} />;
    }
  },

  {
    title: 'Giá trị',
    dataIndex: 'price',
    key: 'price',
    render: value => {
      return <p>{`$${value.toLocaleString('en-US')}`}</p>;
    }
  },
  {
    title: 'Đã mua',
    dataIndex: 'price_in_used',
    key: 'price_in_used',
    render: value => {
      return <p>{`$${value.toLocaleString('en-US')}`}</p>;
    }
  },
  {
    title: 'Tỉ giá',
    dataIndex: 'rate',
    key: 'rate',
    render: value => {
      return <Text className="total">{`${currency.format(value)} đ`}</Text>;
    }
  },
  {
    title: 'Bước nhảy',
    dataIndex: 'buy_step',
    key: 'buy_step',
    render: value => {
      return <p>{`$${value.toLocaleString('en-US')}`}</p>;
    }
  },
  {
    title: 'Thao tác',
    dataIndex: 'action',
    align: 'left',
    render: (_, record) => {
      return (
        <div>
          {record.state === 'available' ? (
            <Button
              onClick={() => {
                return dispatch(
                  actions.updateStateDeal({
                    skuid: record.skuid,
                    state: 'unavailable'
                  })
                );
              }}
              type="dashed"
            >
              hide
            </Button>
          ) : (
            <Button
              onClick={() => {
                return dispatch(
                  actions.updateStateDeal({
                    skuid: record.skuid,
                    state: 'available'
                  })
                );
              }}
              type="primary"
            >
              show
            </Button>
          )}
        </div>
      );
    }
  }
];

export default data;
