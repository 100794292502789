import { Card, Row, Spin, Button, Form, Input, InputNumber, Table } from 'antd';
import moment from 'moment';
import Text from 'antd/lib/typography/Text';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import actions from '../../../redux/actions';
import { notificationError, notificationSuccess } from '../../../utils/Notification';
import { MESSAGE_CONTENT } from '../../../contents/Constants';
import { convertVND, roundNumber } from '../../../utils/convert';
import '../style.less';

export default function Appota() {
  const dispatch = useDispatch();

  const payment = useSelector(
    state => state?.configuration?.payment || {},
    shallowEqual
  );

  const listVAs = useSelector(state => state?.bankTransfer?.listVA, shallowEqual);

  const pool = useSelector(
    state =>
      state?.bankTransfer?.pool || {
        appotaFrequencyInPool: 0,
        appotaTransferAmount: 0,
        buyngonFrequencyInPool: 0,
        buyngonTransferAmount: 0
      }
  );

  const formatPool = {
    appotaTransferAmount: `${convertVND(pool.appotaTransferAmount)} - ${roundNumber(
      pool.appotaFrequencyInPool,
      0
    )}%`,
    buyngonTransferAmount: `${convertVND(
      pool.buyngonTransferAmount
    )} - ${roundNumber(pool.buyngonFrequencyInPool, 0)}%`
  };

  const [frequencyForm] = Form.useForm();
  const [bankForm] = Form.useForm();
  const [poolForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const frequencyData = payment?.frequency_of_appearance_bank_transfer || {
    buyngon: 100,
    appota: 0
  };

  const time_to_expire_appota_bank = payment?.time_to_expire_appota_bank;

  useEffect(() => {
    frequencyForm.setFieldsValue({ ...frequencyData });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  useEffect(() => {
    poolForm.setFieldsValue({ ...formatPool });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formatPool]);

  useEffect(() => {
    bankForm.setFieldsValue({
      time_to_expire_appota_bank: time_to_expire_appota_bank / 86400
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment]);

  useEffect(() => {
    dispatch(actions.getBankTransferPool());
    dispatch(actions.getListVAs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFrequencyFinish = ({ buyngon, appota }) => {
    if (buyngon + appota !== 100) {
      notificationError('total percent = 100');
      return null;
    }

    setLoading(true);
    return dispatch(
      actions.updateConfiguration({
        data: {
          payment: {
            ...payment,
            frequency_of_appearance_bank_transfer: { buyngon, appota }
          }
        },
        callback: ({ error }) => {
          setTimeout(() => {
            setLoading(false);
            if (!error) {
              notificationSuccess(MESSAGE_CONTENT.updateSuccess);
            }
          }, 1 * 1000);
        }
      })
    );
  };

  const VAColumns = [
    {
      title: 'Ngày tạo',
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => {
        return <Text>{moment(text).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'accountNo',
      dataIndex: 'info',
      key: 'accountNo',
      render: info => <Text>{info?.payment?.bankAccounts?.accountNo}</Text>
    },
    {
      title: 'bankCode',
      dataIndex: 'info',
      key: 'bankCode',
      render: info => <Text>{info?.payment?.bankAccounts?.bankCode}</Text>
    },
    {
      title: 'bankName',
      dataIndex: 'info',
      key: 'bankName',
      render: info => <Text>{info?.payment?.bankAccounts?.bankName}</Text>
    },
    {
      title: 'Trạng thái',
      dataIndex: 'state',
      key: 'state'
    },
    {
      title: 'Đơn hàng',
      dataIndex: 'sale_order_v2',
      key: 'sale_order_v2',
      render: data => <Text>{data?.order_code}</Text>
    },
    {
      title: 'Nạp',
      dataIndex: 'transfer',
      key: 'transfer',
      render: data => <Text>{data?.result?.code}</Text>
    }
  ];

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <Row>
          <Card title="Tần suất xuất hiện (%)" className="card">
            <Form
              initialValues={{ ...frequencyData }}
              form={frequencyForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFrequencyFinish}
            >
              <Form.Item
                label="BuyNgon"
                name="buyngon"
                rules={[{ required: true, max: 100, type: 'number' }]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                label="Appota"
                name="appota"
                rules={[{ required: true, max: 100, type: 'number' }]}
              >
                <InputNumber />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                <Button type="primary" htmlType="submit">
                  Lưu
                </Button>
              </Form.Item>
            </Form>
          </Card>
          <Card title="Số tiền đã chuyển vào tài khoản ngân hàng" className="card">
            <Form
              // initialValues={{ ...formatPool }}
              form={poolForm}
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
              onFinish={onFrequencyFinish}
            >
              <Form.Item label="BuyNgon" name="buyngonTransferAmount">
                <Input disabled />
              </Form.Item>
              <Form.Item label="Appota" name="appotaTransferAmount">
                <Input disabled />
              </Form.Item>
            </Form>
          </Card>

          <Card title="Thông tin tài khoản" className="card">
            <Form
              initialValues={{ time_to_expire_appota_bank }}
              form={bankForm}
              labelCol={{ span: 14 }}
              onFinish={onFrequencyFinish}
            >
              <Form.Item
                label="Thời gian hết hạn giao dịch (ngày)"
                name="time_to_expire_appota_bank"
              >
                <Input disabled />
              </Form.Item>
            </Form>
          </Card>

          <Card title="Danh sách tài khoản ảo Appota" className="card card-va">
            <Table loading={!listVAs} columns={VAColumns} dataSource={listVAs} />
          </Card>
        </Row>
      </div>
    </Spin>
  );
}
