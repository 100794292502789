import { all, takeLatest, put, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';
import { ACTION_STATUS } from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import { notificationError, notificationSuccess } from '../../utils/Notification';

function* listAccounts(action) {
  try {
    const data = yield call(services.getAccountList, action.payload);
    yield put({
      type: TYPE.LIST_ACCOUNTS_SUCCESS,
      message: ACTION_STATUS.SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: TYPE.LIST_ACCOUNTS_FAILED,
      message: ACTION_STATUS.FAILED
    });
  }
}

function* changeStatusActivityAccount(action) {
  try {
    const {
      payload: { id, status }
    } = action;
    const data = yield call(services.changeStatusActivityAccount, id, status);
    yield put({
      type: TYPE.CHANGE_STATUS_ACTIVITY_ACCOUNT_SUCCESS,
      message: ACTION_STATUS.SUCCESS,
      data
    });
    notificationSuccess(MessageContent.ChangeStatusSuccess);
  } catch (err) {
    yield put({
      type: TYPE.CHANGE_STATUS_ACTIVITY_ACCOUNT_FAILED,
      message: ACTION_STATUS.FAILED
    });
    notificationError(MessageContent.ChangeStatusFailed);
  }
}

function* searchAccount(action) {
  try {
    const data = yield call(services.searchAccount, action.payload);
    yield put({
      type: TYPE.SEARCH_ACCOUNT_SUCCESS,
      message: ACTION_STATUS.SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: TYPE.SEARCH_ACCOUNT_FAILED,
      message: ACTION_STATUS.FAILED
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.LIST_ACCOUNTS, listAccounts),
    takeLatest(TYPE.CHANGE_STATUS_ACTIVITY_ACCOUNT, changeStatusActivityAccount),
    takeLatest(TYPE.SEARCH_ACCOUNT, searchAccount)
  ]);
}
