import { useEffect, useRef } from 'react';

export default function useTyping(effect, deps, delay = 300) {
  const typingRef = useRef(null);

  useEffect(() => {
    if (typingRef.current) {
      clearTimeout(typingRef.current);
    }

    typingRef.current = setTimeout(effect, delay);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return null;
}
