import { Menu } from 'antd';
import filter from 'lodash/filter';
import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { RoutePages, Routes } from '../../contents/Constants';
import './style.less';

function getClassName(isActive, rootClass = 'menu-item') {
  return `${rootClass} ${isActive ? 'active' : ''}`;
}

export default function MainMenu() {
  const currentRoute = window.location.pathname;
  const dealPath = [Routes.DealTransaction, Routes.DealManage, Routes.DealWithdraw];
  const isDealPath = dealPath.includes(currentRoute);

  const [openKeys, setOpenKeys] = useState(isDealPath ? ['Deal'] : ['']);
  const rootSubmenuKeys = ['Deal'];
  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Menu
      mode="inline"
      className="menu"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    >
      {filter(RoutePages.private, item => item.showMenu).map(item => {
        if (item?.includes) {
          return (
            <Menu.SubMenu
              key={item.name}
              title={item.name}
              icon={<item.Icon />}
              className={getClassName(isDealPath, 'sub-menu-item')}
            >
              {item?.includes.map(sub => {
                const routeItem = RoutePages.private.find(x => x.path === sub);
                if (!routeItem) return null;
                return (
                  <Menu.Item
                    key={routeItem.name}
                    icon={<routeItem.Icon />}
                    className={getClassName(currentRoute === routeItem.path)}
                  >
                    <NavLink to={routeItem.path}>{routeItem.name}</NavLink>
                  </Menu.Item>
                );
              })}
            </Menu.SubMenu>
          );
        }
        return (
          <Menu.Item
            key={item.name}
            icon={<item.Icon />}
            className={getClassName(currentRoute === item.path)}
          >
            <NavLink to={item.path}>{item.name}</NavLink>
          </Menu.Item>
        );
      })}
    </Menu>
  );
}
