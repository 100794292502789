import React from 'react';
import Button from 'antd/lib/button';
import Popconfirm from 'antd/lib/popconfirm';
import Table from 'antd/lib/table';
import Space from 'antd/lib/space';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import { any, objectOf } from 'prop-types';
import { MessageContent } from '../../../../contents/Labels';
import actions from '../../../../redux/actions';

const COLUMNS = deleteRow => {
  return [
    {
      title: MessageContent.Name,
      dataIndex: 'name',
      width: '25%'
    },
    {
      title: MessageContent.Phone,
      dataIndex: 'phone',
      width: '10%'
    },
    {
      title: MessageContent.Address,
      dataIndex: 'address',
      width: '60%',
      render: (_, record) => {
        return (
          <Space>
            {record.type_addr === 'vietnam'
              ? `${record.street}, ${record.ward.name}, ${record.district.name}, ${record.city.name},`
              : record.street}
          </Space>
        );
      }
    },
    {
      title: '',
      dataIndex: 'action',
      width: '5%',
      align: 'center',
      render: (_, record) => {
        return (
          <Popconfirm
            title={MessageContent.DeleteAddress}
            onConfirm={deleteRow(record)}
            okText={MessageContent.YesContent}
            cancelText={MessageContent.NoContent}
          >
            <Button size="small" type="default" icon={<DeleteOutlined />} />
          </Popconfirm>
        );
      }
    }
  ];
};

const AddressList = ({ customer }) => {
  const dispatch = useDispatch();
  const deleteRow = address => {
    return () => {
      return dispatch(
        actions.updateCustomer({
          ...customer,
          address_management: get(customer, 'address_management', []).filter(
            addr => {
              return addr._id !== address._id;
            }
          )
        })
      );
    };
  };

  const cancelRow = () => {};

  const columns = COLUMNS(deleteRow).map(column => {
    return {
      ...column,
      onCell: record => {
        return {
          record,
          inputtype: 'text',
          dataindex: column.dataIndex,
          title: column.title
        };
      }
    };
  });

  const tableProps = {
    size: 'small',
    bordered: true,
    dataSource: get(customer, 'address_management').filter(item => {
      return !item.main_address;
    }),
    columns,
    rowClassName: 'editable-row',
    pagination: {
      onChange: cancelRow,
      defaultPageSize: 5
    }
  };

  return <Table {...tableProps} />;
};

AddressList.propTypes = {
  customer: objectOf(any)
};

AddressList.defaultProps = {
  customer: null
};
export default AddressList;
