import { Avatar, Button, Form, Input, InputNumber } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CloseCircleFilled, UserOutlined } from '@ant-design/icons';
import SearchCustomer from '../../components/SearchCustomer';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { notificationError } from '../../utils/Notification';

export const DebtRecoveryCreateForm = () => {
  const [amount, setAmount] = useState(0);
  const [form] = Form.useForm();

  const userSelected = useSelector(state => {
    return state?.customer?.userSelected;
  });

  const onFinish = () => {
    if (!amount || !userSelected) {
      notificationError('Vui lòng nhập đầy đủ thông tin');
      return;
    }
    dispatch(actions.createDebtRecovery({ amount, user: userSelected?._id }));
  };

  const onChange = e => {
    setAmount(e || 0);
  };
  return (
    <Form
      name="basic"
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 16 }}
      onFinish={onFinish}
      autoComplete="off"
      form={form}
    >
      <Form.Item label="Khách hàng" name="user">
        {!userSelected && <SearchCustomer />}
        {userSelected && (
          <Input
            prefix={<Avatar icon={<UserOutlined />} />}
            suffix={
              <Avatar
                onClick={() => {
                  dispatch(actions.clearCustomer());
                }}
                style={{ cursor: 'pointer' }}
                icon={<CloseCircleFilled />}
                size="small"
              />
            }
            value={userSelected?.name}
          />
        )}
      </Form.Item>

      <Form.Item label="Tiền" name="amount" rules={[{ type: 'number' }]}>
        <InputNumber value={amount} onChange={onChange} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
        <Button type="primary" htmlType="submit">
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};
