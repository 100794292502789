import TYPE from '../actions/TYPE';

const initialState = {
  list: {},
  detail: {}
};

const dealWithdraw = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_LIST_DEAL_WITHDRAW_SUCCESS:
      return {
        ...state,
        list: {
          count: action.count,
          data: action.data
        }
      };

    case TYPE.GET_DEAL_WITHDRAW_DETAIL:
      return {
        ...state,
        detail: (state.list?.data || []).find(item => item._id === action?.payload)
      };

    case TYPE.CONFIRM_DEAL_WITHDRAW_SUCCESS:
    case TYPE.REJECT_DEAL_WITHDRAW_SUCCESS:
      return {
        ...state,
        list: {
          count: state.list.count - 1,
          data: state.list.data.filter(item => item?._id !== action.payload.id)
        }
      };

    default:
      return state;
  }
};

export default dealWithdraw;
