import Button from 'antd/lib/button';
import ButtonGroup from 'antd/lib/button/button-group';
import Card from 'antd/lib/card';
import Row from 'antd/lib/row';
import Spin from 'antd/lib/spin';
import Text from 'antd/lib/typography/Text';
import { get, map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LOGS } from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import actions from '../../redux/actions';
import CustomizeTable from '../CustomizeTable/CustomizeTable';
import './style.less';

function Log() {
  const dispatch = useDispatch();
  const [listCancel, setListCancel] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    dispatch(
      actions.getLogCancel({
        data: { type: LOGS.AUTO_CANCEL, page },
        callback: params => {
          setTimeout(() => {
            setLoading(false);
            setListCancel(params.data);
          }, 1 * 1000);
        }
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const count = get(listCancel, 'count', 0);
  const data = map(listCancel?.results, item => ({
    key: item?._id,
    type: item?.type,
    message: item?.message,
    created_at: item?.created_at
  }));

  const columns = [
    {
      title: MessageContent.CancelDate,
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => {
        return <Text>{moment(text).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: MessageContent.Message,
      dataIndex: 'message',
      key: 'message',
      render: text => {
        const arr = JSON.parse(text);
        const result = [];
        for (let i = 0; i < arr.length; i += 1) {
          result.push(<p className="message">{arr[i]}</p>);
        }
        return result;
      }
    }
  ];

  const onSave = () => {};

  const onReset = () => {};

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme log">
        <ButtonGroup className="btn-action">
          <Button className="save" onClick={onSave}>
            Lưu lại
          </Button>
          <Button type="default" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
        <Row>
          <Card title="Auto Cancel" className="card">
            <CustomizeTable
              columns={columns}
              data={data}
              count={count}
              currentPage={page}
              onPageChange={setPage}
            />
          </Card>
        </Row>
      </div>
    </Spin>
  );
}

export default Log;
