import { all, takeLatest, put, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';

function* get() {
  try {
    const { data } = yield call(services.getConfiguration);
    yield put({ type: TYPE.GET_CONFIGURATION_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_CONFIGURATION_FAILED, error });
  }
}

function* update({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(services.updateConfiguration, data);
    const configData = yield call(services.getConfiguration);
    yield put({ type: TYPE.GET_CONFIGURATION_SUCCESS, data: configData.data });
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, data: null });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_CONFIGURATION, get),
    takeLatest(TYPE.UPDATE_CONFIGURATION, update)
  ]);
}
