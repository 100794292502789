import React from 'react';
import Button from 'antd/lib/button';
import Input from 'antd/lib/input';
import './style.less';
import { any, func, number } from 'prop-types';
import Title from 'antd/lib/typography/Title';
import CloseOutlined from '@ant-design/icons/CloseOutlined';

const FormEdit = ({ data, index, onChangeData, onDeleteData }) => {
  const { id, ask, answer } = data;

  const handleChangeData = event => {
    const { name, value } = event.target;
    onChangeData({ id, name, value });
  };

  const handleDeleteData = () => {
    onDeleteData(id);
  };

  return (
    <div className="form-edit">
      <Title level={5} className="title">
        {`Câu hỏi ${index}:`}
      </Title>
      <Input.TextArea
        placeholder="Nhập nội dung câu hỏi mới"
        name="ask"
        value={ask}
        bordered={false}
        onChange={handleChangeData}
      />
      <Title level={5}>Trả lời</Title>
      <Input.TextArea
        placeholder="Nhập nội dung câu trả lời mới"
        name="answer"
        value={answer}
        bordered={false}
        onChange={handleChangeData}
      />
      <Button
        type="danger"
        className="delete-button"
        icon={<CloseOutlined />}
        onClick={handleDeleteData}
      >
        Xoá
      </Button>
    </div>
  );
};

FormEdit.propTypes = {
  data: any,
  index: number,
  onChangeData: func,
  onDeleteData: func
};

FormEdit.defaultProps = {
  data: null,
  index: undefined,
  onChangeData: () => {},
  onDeleteData: () => {}
};

export default FormEdit;
