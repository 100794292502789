import React, { useContext } from 'react';
import Search from 'antd/lib/input/Search';
import Space from 'antd/lib/space';
import Select from 'antd/lib/select';
import { MessageContent } from '../../contents/Labels';
import { CustomerContext } from '../../pages/Customer';

const { Option } = Select;

const FilterSearchCustomer = () => {
  const { searchForm, setSearchForm } = useContext(CustomerContext);

  const onTextSearch = text => {
    return setSearchForm({ ...searchForm, text });
  };
  const onStatusSearch = status => {
    return setSearchForm({ ...searchForm, status });
  };
  const onProviderSearch = provider => {
    return setSearchForm({ ...searchForm, provider });
  };

  return (
    <Space>
      <Search
        allowClear
        placeholder={MessageContent.SearchBy}
        onSearch={onTextSearch}
        enterButton
      />
      <Select defaultValue="all" style={{ width: 180 }} onChange={onStatusSearch}>
        <Option value="all">Tất cả trạng thái</Option>
        <Option value="active">Active</Option>
        <Option value="inactive">Inactive</Option>
      </Select>
      <Select defaultValue="all" style={{ width: 160 }} onChange={onProviderSearch}>
        <Option value="all">Tất cả nguồn</Option>
        <Option value="google.com">Google</Option>
        <Option value="facebook.com">Facebook</Option>
        <Option value="up">Account</Option>
      </Select>
    </Space>
  );
};

export default FilterSearchCustomer;
