import { CloseCircleTwoTone } from '@ant-design/icons';
import { Spin, Tag } from 'antd';
import Form from 'antd/lib/form';
import { Col } from 'antd/lib/grid';
import Input from 'antd/lib/input';
import Row from 'antd/lib/row';
import Typography from 'antd/lib/typography';
import { findIndex, get, size } from 'lodash';
import { func, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { notificationError } from '../../utils/Notification';
import { nonAccentVietnamese } from '../../utils/search';
import './styles.less';

const { Text } = Typography;

function handleCheckTagExisted(tagId, websites, categories) {
  const existedCategories = categories.filter(item => {
    const indexTagsExclude = findIndex(item.tags_exclude, { _id: tagId });
    const indexTagsInclude = findIndex(item.tags_include, { _id: tagId });
    return (indexTagsExclude !== -1 || indexTagsInclude !== -1) && item;
  });

  const existedWebsites = websites.filter(item => {
    const index = findIndex(item.tags, { _id: tagId });
    return index !== -1 && item;
  });

  const isExisted = size(existedCategories) > 0 || size(existedWebsites) > 0;
  return {
    existedCategories,
    existedWebsites,
    isExisted
  };
}

const TagItem = ({ name, _id: id, handleEdit }) => {
  const listWebsites = useSelector(state => {
    return get(state, 'dealngon.websites', []);
  });
  const listCategories = useSelector(state => {
    return get(state, 'dealngon.categories', []);
  });

  const handleDeleteTag = event => {
    event.stopPropagation();

    const { existedCategories, existedWebsites, isExisted } = handleCheckTagExisted(
      id,
      listWebsites,
      listCategories
    );

    if (!isExisted) {
      dispatch(actions.deleteTag(id));
      return;
    }

    existedCategories.forEach(item => {
      return notificationError(`Xoá tag category: ${item.name}`);
    });
    existedWebsites.forEach(item => {
      return notificationError(`Xoá tag website: ${item.domain}`);
    });
  };

  return (
    <Tag
      onClick={() => {
        return handleEdit({ name, id });
      }}
      className="tag-item"
    >
      {name}
      &nbsp;&nbsp;
      <CloseCircleTwoTone onClick={handleDeleteTag} twoToneColor="#B7B7B7" />
    </Tag>
  );
};

TagItem.propTypes = {
  name: string,
  _id: string,
  handleEdit: func
};

TagItem.defaultProps = {
  name: '',
  _id: '',
  handleEdit: () => {}
};

export default function Tags() {
  const [idEdited, setIdEdited] = useState();
  const [form] = Form.useForm();
  const listTagsReducer = useSelector(
    state => get(state, 'dealngon.tags', []),
    shallowEqual
  );
  const [listTags, setListTags] = useState([]);
  const loading = useSelector(state => {
    return get(state, 'dealngon.loading', false);
  });

  useEffect(() => {
    setListTags(listTagsReducer);
  }, [listTagsReducer]);

  useEffect(() => {
    if (!loading) {
      setIdEdited('');
    }
  }, [loading]);

  const handleEnter = e => {
    if (e.key === 'Enter') {
      form.validateFields().then(({ name }) => {
        if (idEdited) {
          dispatch(actions.updateTag({ name, _id: idEdited }));
        } else {
          dispatch(actions.createTag({ name }));
        }
        form.resetFields();
      });
    }
  };

  const handleSearch = ({ target }) => {
    const { value } = target;
    if (value) {
      const searchTerm = nonAccentVietnamese(value);
      const newList = listTagsReducer.filter(item => {
        return nonAccentVietnamese(item.name).includes(searchTerm);
      });
      setListTags(newList);
    } else {
      setListTags(listTagsReducer);
    }
  };

  const handleClearInput = event => {
    if (event.type === 'click') {
      setIdEdited('');
    }
  };

  const handleEdit = ({ name, id }) => {
    setIdEdited(id);
    form.setFieldsValue({ name });
  };

  return (
    <Spin spinning={loading}>
      <div className="dealngon-tags">
        <Row gutter={[24, 24]}>
          <Col span={24}>
            <Text strong type="secondary">
              Thêm tag mới
            </Text>
            <Form form={form}>
              <Form.Item name="name">
                <Input
                  placeholder="Nhập tag và nhấn enter để thêm tag mới"
                  onChange={handleClearInput}
                  allowClear
                  onKeyDown={handleEnter}
                />
              </Form.Item>
            </Form>
          </Col>
          <Col span={24}>
            <Row justify="end">
              <Col span={8}>
                <Input placeholder="Search Tags" onChange={handleSearch} />
              </Col>
            </Row>
            <Text strong type="secondary">
              Danh sách tag đã tạo
            </Text>
            <Row gutter={[0, 8]} className="tag-list">
              {listTags.map((item, index) => {
                return <TagItem handleEdit={handleEdit} key={index} {...item} />;
              })}
            </Row>
          </Col>
        </Row>
      </div>
    </Spin>
  );
}
