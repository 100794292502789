import { all, takeLatest, put, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';

function* getListPaymentContent({ payload }) {
  try {
    const data = yield call(services.getListPaymentContent, payload);
    yield put({
      type: TYPE.GET_LIST_PAYMENT_CONTENT_SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: TYPE.GET_LIST_PAYMENT_CONTENT_FAILED
    });
  }
}

function* createPaymentContent({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(services.createPaymentContent, data);
    callback({ error: false, data: result?.data?.results || [] });
  } catch (error) {
    callback({ error: true, data: null });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_PAYMENT_CONTENT, getListPaymentContent),
    takeLatest(TYPE.CREATE_PAYMENT_CONTENT, createPaymentContent)
  ]);
}
