import { get } from 'lodash';
import TYPE from '../actions/TYPE';

const initialState = {
  list: {},
  loading: {
    detail: false,
    list: false
  }
};

const withdrawal = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_LIST_WITHDRAWAL:
      return {
        ...state,
        loading: {
          ...state.loading,
          list: true
        }
      };

    case TYPE.GET_LIST_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          withdrawals: action.data,
          count: action.count
        },
        loading: false
      };

    case TYPE.LIST_DEPOSITS_FOR_WITHDRAWAL_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          withdrawals: action.data
        }
      };
    case TYPE.GET_WITHDRAWAL_DETAIL:
      return {
        ...state,
        showType: 'detail',
        detail: get(action, 'payload', null),
        loading: {
          ...state.loading,
          detail: true
        }
      };

    case TYPE.CLEAR_WITHDRAWAL_DETAIL:
      return {
        ...state,
        showType: null,
        detail: null
      };

    case TYPE.REJECT_WITHDRAWAL:
    case TYPE.APPROVE_WITHDRAWAL:
      return {
        ...state,
        loading: {
          ...state.loading,
          detail: true
        }
      };

    case TYPE.REJECT_WITHDRAWAL_SUCCESS:
    case TYPE.APPROVE_WITHDRAWAL_SUCCESS:
      const newList = state.list.withdrawals.filter(
        item => item._id !== state.detail._id
      );

      return {
        ...state,
        showType: null,
        detail: null,
        list: {
          withdrawals: newList,
          count: state.list.count - 1
        },
        loading: {
          ...state.loading,
          detail: false
        }
      };

    case TYPE.REJECT_WITHDRAWAL_FAILED:
    case TYPE.APPROVE_WITHDRAWAL_FAILED:
    case TYPE.GET_WITHDRAWAL_DETAIL_SUCCESS:
    case TYPE.GET_WITHDRAWAL_DETAIL_FAILED:
      return {
        ...state,
        loading: {
          ...state.loading,
          detail: false
        }
      };

    default:
      return state;
  }
};

export default withdrawal;
