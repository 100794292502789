import get from 'lodash/get';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FCM_TOKEN } from '../../contents/Constants';
import actions from '../../redux/actions';

const Dashboard = () => {
  const dispatch = useDispatch();
  const fcmToken = useSelector(state => {
    return get(state, 'profile.info.fcm_token', null);
  });
  const fcmStorage = localStorage.getItem(FCM_TOKEN);

  useEffect(() => {
    if (fcmToken !== null && fcmToken !== fcmStorage) {
      dispatch(actions.updateFcmToken(fcmStorage), false);
    }
  }, [fcmToken, fcmStorage, dispatch]);

  return (
    <div className="dash-board">
      <p>Dashboard Page</p>
    </div>
  );
};
export default Dashboard;
