import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import RootApp from './pages/RootApp';
import './less/index.less';
import { checkBrowser, onForegroundMessage } from './firebase/firebaseMessage';

export function getStore() {
  return store;
}

function App() {
  useEffect(() => {
    if (checkBrowser()) {
      onForegroundMessage();
    }
  }, []);

  return (
    <Provider store={store}>
      <RootApp />
    </Provider>
  );
}

export default App;
