import { all, takeLatest, put, call } from 'redux-saga/effects';
import { identity, pickBy } from 'lodash';
import TYPE from '../actions/TYPE';
import services from '../../services';
import { getListDepositForWithdrawal } from './commonSaga';
import { notificationError, notificationSuccess } from '../../utils/Notification';

function* listWithdrawal(action) {
  try {
    const cleanedParams = pickBy(action.params, identity);
    const { count, results } = yield call(services.getWithdrawalList, cleanedParams);
    yield put({
      type: TYPE.GET_LIST_WITHDRAWAL_SUCCESS,
      data: results,
      count
    });
    yield getListDepositForWithdrawal();
  } catch (err) {
    yield put({
      type: TYPE.GET_LIST_WITHDRAWAL_FAILED,
      message: err
    });
  }
}

function* rejectWithdrawal(action) {
  try {
    yield call(services.rejectWithdrawal, action.payload);
    yield put({ type: TYPE.REJECT_WITHDRAWAL_SUCCESS });

    notificationSuccess('Hủy thành công');
  } catch (err) {
    yield put({
      type: TYPE.REJECT_WITHDRAWAL_FAILED,
      message: err
    });
    notificationError(`${err}`);
  }
}

function* approveWithdrawal(action) {
  try {
    yield call(services.approveWithdrawal, action.payload);
    yield put({ type: TYPE.APPROVE_WITHDRAWAL_SUCCESS });
    notificationSuccess('Xác nhận thành công');
  } catch (err) {
    yield put({
      type: TYPE.APPROVE_WITHDRAWAL_FAILED,
      message: err
    });
    notificationError(`${err}`);
  }
}

function* detailWithdrawal() {
  try {
    yield put({ type: TYPE.GET_WITHDRAWAL_DETAIL_SUCCESS });
  } catch (err) {
    yield put({
      type: TYPE.GET_WITHDRAWAL_DETAIL_FAILED,
      message: err
    });
    notificationError(`${err}`);
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_WITHDRAWAL, listWithdrawal),
    takeLatest(TYPE.REJECT_WITHDRAWAL, rejectWithdrawal),
    takeLatest(TYPE.APPROVE_WITHDRAWAL, approveWithdrawal),
    takeLatest(TYPE.GET_WITHDRAWAL_DETAIL, detailWithdrawal)
  ]);
}
