import { all, takeLatest, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';

function* getLogsCancel({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(services.getLogsCancel, data);
    callback({ error: false, data: result });
  } catch (error) {
    callback({ error: true, data: null });
  }
}

export default function* root() {
  yield all([takeLatest(TYPE.AUTO_CANCEL, getLogsCancel)]);
}
