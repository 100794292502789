import { findIndex } from 'lodash';
import TYPE from '../actions/TYPE';

const initialValue = {
  groups: {
    count: null,
    result: []
  },
  loading: false
};

const category = (state = initialValue, action = {}) => {
  switch (action.type) {
    case TYPE.GET_ALL_CATEGORIES_SUCCESS:
      // case TYPE.UPDATE_CATEGORIES_SUCCESS:
      return {
        ...state,
        groups: action.data
      };
    case TYPE.DELETE_CATEGORY_SUCCESS: {
      const { type, groupIndex, categoryIndex } = action.data;
      if (type === 'group') {
        state.groups.result.splice(groupIndex, 1);
      } else {
        state.groups.result[groupIndex].categories.splice(categoryIndex, 1);
      }

      return {
        ...state
      };
    }
    case TYPE.UPDATE_CATEGORIES_NAME: {
      const {
        body: { name, type },
        groupIndex,
        categoryIndex
      } = action;
      const newState = { ...state };
      if (type === 'group') {
        newState.groups.result[groupIndex].name = name;
      } else {
        newState.groups.result[groupIndex].categories[categoryIndex].domain = name;
      }
      return {
        ...newState
      };
    }

    // case TYPE.DRAGGABLE_CATEGORIES:
    //   return {
    //     ...state,
    //     groups: action.payload
    //   };
    case TYPE.CREATE_GROUP_SUCCESS:
      return {
        ...state,
        groups: {
          count: state.groups.count + 1,
          result: [...state.groups.result, { ...action.data, categories: [] }]
        }
      };
    case TYPE.CREATE_CATEGORY_SUCCESS:
      const idx = findIndex(state.groups.result, { _id: action.data.group_id });

      if (idx !== -1) {
        state.groups.result[idx].categories.push(action.data);
      }
      return {
        ...state
      };

    case TYPE.ENABLE_LOADING:
      return {
        ...state,
        loading: true
      };
    case TYPE.DISABLE_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return {
        ...state
      };
  }
};

export default category;
