import { all, takeLatest, put, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';
import { notificationSuccess } from '../../utils/Notification';

function* getListDebtRecovery() {
  try {
    const { data } = yield call(services.marketplace.debtRecovery.list);
    yield put({
      type: TYPE.GET_LIST_DEBT_RECOVERY_SUCCESS,
      data
    });
  } catch (err) {
    yield put({
      type: TYPE.GET_LIST_DEBT_RECOVERY_FAILED
    });
  }
}

function* createDebtRecovery({ payload }) {
  try {
    const { success, data } = yield call(
      services.marketplace.debtRecovery.create,
      payload
    );

    if (success === true) {
      notificationSuccess('Tạo mới thành công');
      yield put({
        type: TYPE.CREATE_DEBT_RECOVERY_SUCCESS,
        data
      });

      yield getListDebtRecovery();
    }
  } catch (err) {
    yield put({
      type: TYPE.CREATE_DEBT_RECOVERY_FAILED
    });
  }
}

function* closeDebtRecovery({ payload }) {
  try {
    const { success } = yield call(services.marketplace.debtRecovery.close, payload);

    if (success === true) {
      notificationSuccess('Chỉnh sửa thành công');
      yield put({
        type: TYPE.CLOSE_DEBT_RECOVERY_SUCCESS
      });

      yield getListDebtRecovery();
    }
  } catch (err) {
    yield put({
      type: TYPE.CREATE_DEBT_RECOVERY_FAILED
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_DEBT_RECOVERY, getListDebtRecovery),
    takeLatest(TYPE.CREATE_DEBT_RECOVERY, createDebtRecovery),
    takeLatest(TYPE.CLOSE_DEBT_RECOVERY, closeDebtRecovery)
  ]);
}
