import { CameraFilled, CloseCircleOutlined } from '@ant-design/icons';
import { Space, Spin, Upload } from 'antd';
import { bool, func, string } from 'prop-types';
import React from 'react';
import ImgCrop from 'antd-img-crop';
import ImageIcon from '../icons/ImageIcon';
import './styles.less';

function UploadResource({ imgUrl }) {
  if (imgUrl.includes('mp4')) {
    return (
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{ width: '100%', height: '100%' }}
      >
        <source src={imgUrl} type="video/mp4" />
      </video>
    );
  }

  return <img src={imgUrl} alt={imgUrl} />;
}

UploadResource.propTypes = {
  imgUrl: string
};

UploadResource.defaultProps = {
  imgUrl: ''
};

export default function UploadImage({
  isPreventCrop,
  imgUrl,
  beforeUpload,
  loading,
  handleReset
}) {
  const CropComponent = isPreventCrop ? 'div' : ImgCrop;
  return (
    <Spin spinning={loading}>
      <div
        className={`upload-image ${
          imgUrl.length === 0 ? 'upload-image-default' : ''
        }`}
      >
        <CloseCircleOutlined onClick={handleReset} className="reset-image" />
        {!imgUrl ? (
          <Space direction="vertical" align="center">
            <ImageIcon />
            <p className="upload-text">Upload ảnh</p>
          </Space>
        ) : (
          <UploadResource imgUrl={imgUrl} />
        )}
        <CropComponent grid rotate>
          <Upload
            className="upload-button"
            isImageUrl
            showUploadList={false}
            beforeUpload={beforeUpload}
          >
            <CameraFilled style={{ fontSize: '16px', color: 'white' }} />
          </Upload>
        </CropComponent>
      </div>
    </Spin>
  );
}

UploadImage.propTypes = {
  imgUrl: string,
  beforeUpload: func,
  loading: bool,
  isPreventCrop: bool,
  handleReset: func
};

UploadImage.defaultProps = {
  imgUrl: '',
  beforeUpload: () => {},
  loading: false,
  isPreventCrop: false,
  handleReset: () => {}
};
