import Title from 'antd/lib/typography/Title';
import React, { useEffect, useState } from 'react';
import Tabs from 'antd/lib/tabs';
import './style.less';
import actions from '../../redux/actions';
import Appota from './Appota';
import dispatch from '../../utils/dispatch';

const TABS = [{ key: 'appota', tab: 'Appota', Component: Appota }];

const Payment = () => {
  const [tab, setTab] = useState(TABS[0].key);

  useEffect(() => {
    dispatch(actions.getConfiguration());
    return () => dispatch(actions.clearConfiguration());
  }, []);

  return (
    <div className="container">
      <Title className="title" level={5}>
        Hoạt động
      </Title>
      <Tabs onChange={setTab}>
        <>
          {TABS.map(tabItem => {
            return (
              <Tabs.TabPane
                active={tabItem.key === tab}
                tab={tabItem.tab}
                key={tabItem.key}
              >
                <tabItem.Component />
              </Tabs.TabPane>
            );
          })}
        </>
      </Tabs>
    </div>
  );
};

export default Payment;
