import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import Search from 'antd/lib/input/Search';
import { Button, Space } from 'antd';
import history from '../../navigation';
import { Routes } from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';

export default function DealManage() {
  const CreateDeal = () => {
    return history.push(Routes.CreateDeal);
  };

  const onTextSearch = text => {
    dispatch(actions.searchDeal(text));
  };

  return (
    <Space>
      <Search
        allowClear
        placeholder={MessageContent.SearchBy}
        onSearch={onTextSearch}
        enterButton
      />
      <Button type="primary" icon={<PlusOutlined />} onClick={CreateDeal}>
        Tạo Deal
      </Button>
    </Space>
  );
}
