import { all, takeLatest, put, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';

import { getListDepositForTransactionDeal } from './commonSaga';
import { Routes } from '../../contents/Constants';
import history from '../../navigation';

function* list() {
  try {
    const deal = yield call(services.marketplace.metadeal.listMeta);
    yield put({
      type: TYPE.LIST_META_DEAL_SUCCESS,
      data: deal.data
    });
    yield getListDepositForTransactionDeal();
  } catch (err) {
    yield put({
      type: TYPE.LIST_META_DEAL_FAILED,
      message: err
    });
  }
}

function* create({ payload }) {
  try {
    const { data } = yield call(services.marketplace.metadeal.createDeal, payload);
    history.push(Routes.DealManage);
    yield put({ type: TYPE.CREATE_DEAL_SUCCESS, data });
    yield list();
  } catch (error) {
    yield put({ type: TYPE.CREATE_DEAL_FAILED, error });
  }
}

function* updateState({ payload }) {
  try {
    const { data } = yield call(
      services.marketplace.metadeal.updateStateDeal,
      payload
    );

    yield put({ type: TYPE.UPDATE_STATE_DEAL_SUCCESS, data, payload });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_STATE_DEAL_FAILED, error });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.LIST_META_DEAL, list),
    takeLatest(TYPE.CREATE_DEAL, create),
    takeLatest(TYPE.UPDATE_STATE_DEAL, updateState)
  ]);
}
