import React from 'react';
import { func } from 'prop-types';
import Search from 'antd/lib/input/Search';
import Space from 'antd/lib/space';
import { MessageContent } from '../../contents/Labels';

const FitlerSearchAccount = ({ onSearch }) => {
  return (
    <Space>
      <Search
        allowClear
        placeholder={MessageContent.SearchBy}
        onSearch={onSearch}
        enterButton
      />
    </Space>
  );
};

FitlerSearchAccount.propTypes = {
  onSearch: func
  // onSort: func
};

FitlerSearchAccount.defaultProps = {
  onSearch: null
  // onSort: null
};

export default FitlerSearchAccount;
