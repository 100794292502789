import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './styles.less';
import Button from 'antd/lib/button';
import Drawer from 'antd/lib/drawer';
import Row from 'antd/lib/row';
import Space from 'antd/lib/space';
import { func } from 'prop-types';
import { get, isEmpty } from 'lodash';
import Avatar from 'antd/lib/avatar/avatar';
import Empty from 'antd/lib/empty';
import Spin from 'antd/lib/spin';
import Typography from 'antd/lib/typography';
import DoubleRightOutlined from '@ant-design/icons/DoubleRightOutlined';
import CloseOutlined from '@ant-design/icons/CloseOutlined';
import Title from 'antd/lib/typography/Title';
import CustomerProfile from '../CustomerProfile';
import SearchCustomer from '../SearchCustomer';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';
import { convertAddress } from '../../utils/convert';
import RequestSaleOrder from '../CreateSaleOrder/TableSaleOrder/RequestSaleOrder';
import CartSaleOrder from '../CreateSaleOrder/TableSaleOrder/CartSaleOrder';

function DealRequestSaleOrder() {
  const userSelectedId = useSelector(state => {
    return !!get(state, 'customer.userSelected._id');
  });

  if (!userSelectedId) {
    return <Empty description={<span>Vui lòng chọn khách hàng</span>} />;
  }

  return <RequestSaleOrder isDeal />;
}

function DealCartSaleOrder() {
  const list = useSelector(
    state => state?.transactionDeal?.currentDealOrder?.quotation_list
  );

  if (isEmpty(list)) return null;

  return (
    <CartSaleOrder
      isDeal
      keyValue="_id"
      quotationList={list}
      title="Giỏ hàng"
      showConfirm
    />
  );
}

function UserProfile({ onShowDrawer }) {
  const avatarUrl = useSelector(state => {
    return get(state, 'customer.userSelected.avatar_url', '');
  });
  const deliveryAddress = useSelector(state => {
    return get(state, 'customer.userSelected.address_management', []).find(
      item => item.selected
    );
  });
  const address = convertAddress(deliveryAddress);

  return (
    <Space className="user-profile">
      <Avatar src={avatarUrl} />
      <div className="user-info">
        <Typography.Title level={4}>
          {get(deliveryAddress, 'name', 'name')}
        </Typography.Title>
        <Typography.Text>
          <Typography.Text strong>{' Địa chỉ nhận hàng: '}</Typography.Text>
          {address}
        </Typography.Text>
        <Button type="text" className="btn" onClick={onShowDrawer}>
          {'Xem khách hàng '} <DoubleRightOutlined />
        </Button>
      </div>
    </Space>
  );
}

UserProfile.propTypes = {
  onShowDrawer: func.isRequired
};

const CreateSaleOrderByDeal = () => {
  const [isSelectedUser, setIsSelectedUser] = useState(false);
  const loading = useSelector(state => {
    return get(state, 'common.loading', false);
  });

  useEffect(() => {
    dispatch(actions.getListWebsiteDisableUS());
  }, []);

  useEffect(() => {
    return () => dispatch(actions.clearCustomer());
  }, []);

  const [visible, setVisible] = useState(false);
  const userSelectedId = useSelector(state => {
    return !!get(state, 'customer.userSelected._id');
  });
  const showDrawer = () => {
    dispatch(actions.selectCustomer(null));
    setVisible(true);
  };

  const handleShowDrawer = () => setVisible(true);

  const handleClose = () => {
    setVisible(false);
    dispatch(actions.clearCustomer());
  };

  const Header = userSelectedId ? (
    <UserProfile onShowDrawer={handleShowDrawer} />
  ) : (
    <Button type="primary" onClick={showDrawer}>
      Chọn khách hàng
    </Button>
  );

  return (
    <div className="create-sale-order">
      <Title className="title" level={5}>
        Lên đơn hàng bằng deal
      </Title>

      <Spin spinning={loading}>
        <Row justify="end" align="top">
          {Header}
        </Row>

        <DealRequestSaleOrder />

        <DealCartSaleOrder />

        {visible && (
          <Drawer
            className="drawer"
            width={500}
            title="Chọn khách hàng"
            placement="right"
            closeIcon={<CloseOutlined onClick={handleClose} />}
            visible={visible}
          >
            {userSelectedId && (
              <CustomerProfile
                isShowDeal
                isSelected={isSelectedUser}
                setIsSelected={setIsSelectedUser}
                setVisible={setVisible}
              />
            )}
            {!userSelectedId && <SearchCustomer />}
          </Drawer>
        )}
      </Spin>
    </div>
  );
};

export default CreateSaleOrderByDeal;
