export const CONFIG = {
  REACT_APP_FIREBASE_ACCESS_KEY: process.env.REACT_APP_FIREBASE_ACCESS_KEY_STAGING,
  REACT_APP_FIREBASE_AUTH_DOMAIN: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  CLOUD_MESSAGE: {
    APIKEY: process.env.REACT_APP_APIKEY,
    AUTHDOMAIN: process.env.REACT_APP_AUTHDOMAIN,
    DATABASEURL: process.env.REACT_APP_DATABASEURL,
    PROJECTID: process.env.REACT_APP_PROJECTID,
    STORAGEBUCKET: process.env.REACT_APP_STORAGEBUCKET,
    MESSAGINGSENDERID: process.env.REACT_APP_MESSAGINGSENDERID,
    APPID: process.env.REACT_APP_APPID,
    KEYPAIR: process.env.REACT_APP_KEY_PAIR
  }
};
