import TYPE from '../actions/TYPE';

const initialState = {
  list: {},
  detail: null,
  request: false,
  export: false
};

const exchangePointRequest = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_LIST_EXCHANGE_POINT_REQUEST_SUCCESS:
      return {
        ...state,
        list: action.payload
      };

    case TYPE.CONFIRM_EXCHANGE_POINT_REQUEST_SUCCESS:
    case TYPE.REJECT_EXCHANGE_POINT_REQUEST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          count: state.list.count - 1,
          data: state.list.data.filter(item => item._id !== action.request_id)
        }
      };

    case TYPE.UPDATE_POINT_BY_ADMIN_SUCCESS:
      return {
        ...state
      };

    case TYPE.LIST_DEPOSITS_FOR_EXCHANGE_POINT_REQUEST_SUCCESS:
    case TYPE.LIST_POINT_FOR_EXCHANGE_POINT_REQUEST_SUCCESS:
      return {
        ...state,
        list: {
          ...state.list,
          data: action.data
        }
      };

    case TYPE.GET_EXCHANGE_POINT_REQUEST_DETAIL:
      return {
        ...state,
        detail: action.payload
      };

    case TYPE.CLEAR_EXCHANGE_POINT_REQUEST_DETAIL:
      return {
        ...state,
        detail: null
      };

    case TYPE.TOGGLE_REQUEST_CHANGE_POINT:
      return {
        ...state,
        request: !state.request
      };

    case TYPE.TOGGLE_EXPORT_POINT_STATISTICAL:
      return {
        ...state,
        export: !state.export
      };

    default:
      return state;
  }
};

export default exchangePointRequest;
