import React from 'react';
import { number, string } from 'prop-types';
import './style.less';
import { Tooltip } from 'antd';
import { convertVND } from '../../../../../utils/convert';

export default function CouponItem({ name, price }) {
  return (
    <Tooltip placement="topLeft" title={convertVND(price)} className="coupon-item">
      <p>{name}</p>
    </Tooltip>
  );
}

CouponItem.propTypes = {
  price: number,
  name: string
};

CouponItem.defaultProps = {
  price: 0,
  name: ''
};
