import TYPE from '../actions/TYPE';

const initialState = {
  info: null,
  account: {
    list: [],
    detail: null
  }
};

const profile = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.PROFILE_OWNER_SUCCESS:
    case TYPE.LOGIN_SUCCESS:
      return {
        ...state,
        info: action.data
      };

    case TYPE.LOGOUT_SUCCESS:
      return initialState;

    case TYPE.LIST_ACCOUNTS_SUCCESS:
      return {
        ...state,
        account: {
          ...state.account,
          list: action.data
        }
      };

    case TYPE.ACCOUNT_DETAIL_SUCCESS:
    case TYPE.UPDATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        account: {
          ...state.account,
          detail: action.data
        }
      };

    case TYPE.CREATE_ACCOUNT_SUCCESS:
      return {
        ...state,
        acount: {
          ...state.account,
          list: [action.data, ...state.account.list]
        }
      };

    case TYPE.REMOVE_ACCOUNT_SUCCESS: {
      const restAccounts = state.account.list.filter(item => {
        return item._id !== action.data;
      });
      return {
        ...state,
        account: {
          ...state.account,
          list: restAccounts
        }
      };
    }
    case TYPE.UPDATE_FCM_TOKEN_SUCCESS: {
      return {
        ...state,
        info: {
          ...action.data
        }
      };
    }
    default:
      return state;
  }
};

export default profile;
