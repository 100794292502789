import { all, takeLatest, put, call } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';
import { notificationError } from '../../utils/Notification';

function* bankTransferPool() {
  try {
    const { success, data, message } = yield call(
      services.marketplace.bankTransfer.pool
    );
    if (!success) {
      notificationError(message);
    } else {
      yield put({
        type: TYPE.GET_BANK_TRANSFER_POOL_SUCCESS,
        data
      });
    }
  } catch (err) {
    yield put({
      type: TYPE.GET_BANK_TRANSFER_POOL_FAILED,
      message: err
    });
  }
}

function* getListVAs() {
  try {
    const { success, data, message } = yield call(
      services.marketplace.bankTransfer.listVas
    );
    if (!success) {
      notificationError(message);
    } else {
      yield put({
        type: TYPE.GET_LIST_VA_SUCCESS,
        data
      });
    }
  } catch (error) {
    yield put({
      type: TYPE.GET_LIST_VA_FAILED,
      message: error
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_BANK_TRANSFER_POOL, bankTransferPool),
    takeLatest(TYPE.GET_LIST_VA, getListVAs)
  ]);
}
