import React, { useEffect, useState } from 'react';
import Row from 'antd/lib/row';
import Card from 'antd/lib/card';
import ButtonGroup from 'antd/lib/button/button-group';
import Button from 'antd/lib/button';
import './style.less';
import Spin from 'antd/lib/spin';
import { useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import actions from '../../redux/actions';
import { notificationSuccess } from '../../utils/Notification';
import { MESSAGE_CONTENT } from '../../contents/Constants';

function Webhook() {
  const dispatch = useDispatch();
  const webhook = useSelector(state => {
    return get(state, 'configuration.webhook.order', []);
  });

  const [webhookUri, setWebhookUri] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setWebhookUri(webhook);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSave = () => {
    setLoading(true);
    dispatch(
      actions.updateConfiguration({
        data: { webhook: webhookUri },
        callback: ({ error }) => {
          setTimeout(() => {
            setLoading(false);
            if (!error) {
              notificationSuccess(MESSAGE_CONTENT.updateSuccess);
            }
          }, 1 * 1000);
        }
      })
    );
  };

  const onReset = () => {
    setWebhookUri([]);
  };

  const onChangeUri = (event, key) => {
    const newState = webhookUri;
    newState[key].uri = event.target.value;
    setWebhookUri([...newState]);
  };
  if (!webhookUri) return null;
  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <ButtonGroup className="btn-action">
          <Button className="save" onClick={onSave}>
            Lưu lại
          </Button>
          <Button type="default" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
        <Row>
          <Card title="Webhook URI" className="card">
            {webhookUri.map((item, key) => {
              return (
                <div key={key} className="card-item">
                  <div className="label">{get(item, 'label', '')}</div>
                  <input
                    className="uri"
                    name={get(item, 'name', '')}
                    value={get(item, 'uri', '')}
                    onChange={e => {
                      return onChangeUri(e, key);
                    }}
                  />
                </div>
              );
            })}
          </Card>
        </Row>
      </div>
    </Spin>
  );
}

export default Webhook;
