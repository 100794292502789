// import get from 'lodash/get';
import { SELLER_PROFILE_MODE } from '../../contents/Constants';
import TYPE from '../actions/TYPE';

// const MODE = {
//   create: 'create',
//   edit: 'edit'
// };

const initialState = {
  list: {
    count: 0,
    sellers: []
  },
  edit: {
    seller: {}
  },
  mode: null,
  isLoading: false
};

const seller = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.LIST_SELLER_PROFILE_SUCCESS:
      return {
        ...state,
        list: {
          count: action.data.count,
          sellers: action.data.results
        }
      };

    case TYPE.CREATE_SELLER_PROFILE_SUCCESS:
      return {
        ...state,
        mode: SELLER_PROFILE_MODE.off
      };

    case TYPE.CHANGE_MODE_SELLER_PROFILE:
      return {
        ...state,
        mode: action.payload
      };

    case TYPE.OPEN_SELLER_PROFILE_DETAIL:
      return {
        ...state,
        edit: {
          ...state.edit,
          seller: action.payload
        }
      };
    default:
      return state;
  }
};

export default seller;
