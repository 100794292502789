import { Table } from 'antd';
import Button from 'antd/lib/button';
import ButtonGroup from 'antd/lib/button/button-group';
import Row from 'antd/lib/row';
import Spin from 'antd/lib/spin';
import Text from 'antd/lib/typography/Text';
import { map } from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { LOGS } from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import actions from '../../redux/actions';
import './style.less';

function PhoneOTP() {
  const dispatch = useDispatch();
  const [listPhoneOTP, setListPhoneOTP] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const getLogPhoneOTP = () =>
    dispatch(
      actions.getLogCancel({
        data: { type: LOGS.PHONE_OTP, limit: 1000 },
        callback: params => {
          setTimeout(() => {
            setLoading(false);
            setListPhoneOTP(params.data);
          }, 1 * 1000);
        }
      })
    );

  useEffect(() => {
    setLoading(true);
    getLogPhoneOTP();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  // const count = get(listPhoneOTP, 'count', 0);
  const data = map(listPhoneOTP?.results, item => ({
    key: item?._id,
    type: item?.type,
    result: item?.result,
    created_at: item?.created_at,
    user: item?.user
  }));

  const columns = [
    {
      title: MessageContent.CustomerName,
      dataIndex: 'user',
      key: 'user',
      render: value => <Text>{value?.[0]?.name}</Text>
    },
    {
      title: MessageContent.Email,
      dataIndex: 'user',
      key: 'email',
      render: value => <Text>{value?.[0]?.email}</Text>
    },
    {
      title: MessageContent.OTP,
      dataIndex: 'result',
      key: 'result',
      render: value => {
        return <Text>{value?.otp}</Text>;
      }
    },
    {
      title: MessageContent.CreatedAt,
      dataIndex: 'created_at',
      key: 'created_at',
      render: text => {
        return <Text>{moment(text).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    }
  ];

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme log">
        <ButtonGroup className="btn-action">
          <Button className="save">Lưu</Button>
          <Button type="default" onClick={() => setRefresh(!refresh)}>
            Làm mới
          </Button>
        </ButtonGroup>
        <Row>
          <Table
            columns={columns}
            dataSource={data}
            style={{ width: '100%' }}
            pagination
          />
        </Row>
      </div>
    </Spin>
  );
}

export default PhoneOTP;
