import TYPE from '../actions/TYPE';

const initialState = {
  list: {},
  detail: {}
};

const dealCategory = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.LIST_CATEGORY_SUCCESS:
      return {
        ...state,
        list: action.data
      };
    default:
      return state;
  }
};

export default dealCategory;
