import { DeleteFilled } from '@ant-design/icons';
import { Button, Col, Collapse, Form, Input, Row, Space, Typography } from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { bool, func, number, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useUploadFile from '../../hooks/useUploadFile';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import { notificationError } from '../../utils/Notification';
import CustomizeCollapse from '../CustomizeCollapse';
import ArrowDownIcon from '../icons/ArrowDownIcon';
import ArrowUpIcon from '../icons/ArrowUpIcon';
import UploadImage from '../UploadImage';
import './styles.less';

export default function ListCategoryItem({
  display_name,
  default_name,
  avatar,
  url,
  index,
  setLoading,
  isNew,
  setShowAddNew
}) {
  const [file, handleUploadFile, handleReset] = useUploadFile(avatar);
  const [expand, setExpand] = useState(false);
  const listBanners = useSelector(state => {
    return get(state, 'configuration.category.list', []);
  });
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ default_name, url });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [default_name, url]);

  const handleSaveBanner = values => {
    if (isEmpty(file.link)) {
      notificationError('Vui lòng nhập đầy đủ thông tin');
    } else {
      setLoading(true);
      const newData = [...listBanners];
      const newItem = { ...values, avatar: file.link };
      if (isNew) {
        newData.push(newItem);
      } else {
        newData[index] = newItem;
      }
      dispatch(
        actions.updateConfiguration({
          data: { category: newData },
          callback: () => {
            setTimeout(() => {
              setLoading(false);
              setShowAddNew(false);
            }, 500);
          }
        })
      );
    }
  };

  const handleDelete = e => {
    const newData = [...listBanners];
    newData.splice(index, 1);
    setLoading(true);
    dispatch(
      actions.updateConfiguration({
        data: { category: newData },
        callback: () => {
          setTimeout(() => {
            setLoading(false);
            setShowAddNew(false);
          }, 500);
        }
      })
    );
    e.stopPropagation();
  };

  const header = (
    <Row justify="space-between" align="middle">
      <Space align="center" size="middle">
        {expand ? <ArrowUpIcon /> : <ArrowDownIcon />}
        <Typography.Text strong>
          {isNew ? 'New Category' : `${display_name || default_name}`}
        </Typography.Text>
      </Space>
      {!isNew && (
        <Col>
          <DeleteFilled onClick={handleDelete} />
        </Col>
      )}
    </Row>
  );

  const handleExpand = () => setExpand(prevState => !prevState);

  return (
    <CustomizeCollapse onChange={handleExpand}>
      <Collapse.Panel showArrow={false} className="banner-header" header={header}>
        <Row gutter={16}>
          <Col className="gutter-row" span={12}>
            <UploadImage
              isPreventCrop
              imgUrl={file.link}
              loading={file.isFetching}
              beforeUpload={handleUploadFile}
              handleReset={handleReset}
            />
          </Col>

          <Col className="gutter-row" span={12}>
            <Form form={form} onFinish={handleSaveBanner}>
              <Form.Item
                name="default_name"
                initialValue={default_name}
                rules={[{ required: true, message: 'Vui lòng nhập default_name' }]}
              >
                <Input placeholder="default_name" />
              </Form.Item>

              {/* <Form.Item name="display_name" initialValue={display_name}>
                <Input placeholder="display_name" />
              </Form.Item> */}

              <Form.Item
                name="url"
                initialValue={url}
                rules={[{ required: true, message: 'Vui lòng nhập url' }]}
              >
                <Input placeholder="url" />
              </Form.Item>

              <Row justify="end">
                <Button htmlType="submit" className="banner-button" type="primary">
                  Lưu
                </Button>
              </Row>
            </Form>
          </Col>
        </Row>
      </Collapse.Panel>
    </CustomizeCollapse>
  );
}

ListCategoryItem.propTypes = {
  display_name: string,
  default_name: string,
  avatar: string,
  url: string,
  index: number,
  setLoading: func,
  isNew: bool,
  setShowAddNew: func
};

ListCategoryItem.defaultProps = {
  display_name: '',
  default_name: '',
  avatar: '',
  url: '',
  index: 0,
  setLoading: () => {},
  isNew: false,
  setShowAddNew: () => {}
};
