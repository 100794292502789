import React from 'react';
import Text from 'antd/lib/typography/Text';
import Button from 'antd/lib/button';
import Skeleton from 'antd/lib/skeleton';
import moment from 'moment';
import { get, isNumber } from 'lodash';
import { MessageContent } from '../../contents/Labels';
import currency from '../../utils/currency';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';

export default {
  draft: [
    {
      title: 'Ngày yêu cầu',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      fixed: true,
      render: created_at => {
        return <Text>{moment(created_at).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'Khách hàng',
      dataIndex: 'user',
      render: user => {
        return <Text>{get(user, '[0].name', '')}</Text>;
      }
    },
    {
      title: 'Khoản dư ghi nhận',
      dataIndex: 'before_deposit',
      render: before_deposit => {
        return <Text>{`${currency.format(before_deposit)} đ`}</Text>;
      }
    },
    {
      title: 'Khoản dư hiện tại',
      dataIndex: 'deposit',
      key: 'balance',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <Text>{`${currency.format(value)} đ`}</Text>;
      }
    },
    {
      title: 'Số điểm ghi nhận',
      dataIndex: 'before_point',
      render: before_point => {
        return <Text>{`${currency.format(before_point)}`}</Text>;
      }
    },
    {
      title: 'Số điểm hiện tại',
      dataIndex: 'point',
      key: 'point',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <Text>{`${currency.format(value)}`}</Text>;
      }
    },
    {
      title: 'Số điểm đổi',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: text => {
        return <Text className="total">{currency.format(text)}</Text>;
      }
    },
    {
      title: 'Thao tác',
      dataIndex: 'action',
      key: 'action',
      align: 'left',
      render: (_, record) => {
        return (
          <Button
            type="primary"
            onClick={() => dispatch(actions.getExchangePointRequestInfo(record))}
          >
            {MessageContent.Confirm}
          </Button>
        );
      }
    }
  ],
  confirmed: [
    {
      title: 'Ngày yêu cầu',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      fixed: true,
      render: created_at => {
        return <Text>{moment(created_at).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'Ngày xác nhận',
      key: 'approved_time',
      dataIndex: 'approved_time',
      align: 'center',
      fixed: true,
      render: approved_time => {
        return <Text>{moment(approved_time).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'Khách hàng',
      dataIndex: 'user',
      render: user => {
        return <Text>{get(user, '[0].name', '')}</Text>;
      }
    },
    {
      title: 'Khoản dư ghi nhận',
      dataIndex: 'before_deposit',
      render: before_deposit => {
        return <Text>{`${currency.format(before_deposit)} đ`}</Text>;
      }
    },
    {
      title: 'Khoản dư hiện tại',
      dataIndex: 'deposit',
      key: 'balance',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <Text>{`${currency.format(value)} đ`}</Text>;
      }
    },
    {
      title: 'Số điểm ghi nhận',
      dataIndex: 'before_point',
      render: before_point => {
        return <Text>{`${currency.format(before_point)}`}</Text>;
      }
    },
    {
      title: 'Số điểm hiện tại',
      dataIndex: 'point',
      key: 'point',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <Text>{`${currency.format(value)}`}</Text>;
      }
    },
    {
      title: 'Số điểm đổi',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: text => {
        return <Text className="total">{currency.format(text)}</Text>;
      }
    },
    {
      title: 'Xác nhận bởi',
      dataIndex: 'approved_by',
      key: 'approved_by',
      render: approved_by => {
        return <Text>{approved_by?.[0]?.name}</Text>;
      }
    }
  ],
  rejected: [
    {
      title: 'Ngày yêu cầu',
      key: 'createdAt',
      dataIndex: 'created_at',
      align: 'center',
      fixed: true,
      render: created_at => {
        return <Text>{moment(created_at).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'Ngày từ chối',
      key: 'rejected_time',
      dataIndex: 'rejected_time',
      align: 'center',
      fixed: true,
      render: rejected_time => {
        return <Text>{moment(rejected_time).format('HH:mm:ss, DD/MM/YYYY')}</Text>;
      }
    },
    {
      title: 'Khách hàng',
      dataIndex: 'user',
      render: user => {
        return <Text>{get(user, '[0].name', '')}</Text>;
      }
    },
    {
      title: 'Khoản dư ghi nhận',
      dataIndex: 'before_deposit',
      render: before_deposit => {
        return <Text>{`${currency.format(before_deposit)} đ`}</Text>;
      }
    },
    {
      title: 'Khoản dư hiện tại',
      dataIndex: 'deposit',
      key: 'balance',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <Text>{`${currency.format(value)} đ`}</Text>;
      }
    },
    {
      title: 'Số điểm ghi nhận',
      dataIndex: 'before_point',
      render: before_point => {
        return <Text>{`${currency.format(before_point)}`}</Text>;
      }
    },
    {
      title: 'Số điểm hiện tại',
      dataIndex: 'point',
      key: 'point',
      align: 'right',
      render: value => {
        if (!isNumber(value)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        return <Text>{`${currency.format(value)}`}</Text>;
      }
    },
    {
      title: 'Số điểm đổi',
      dataIndex: 'amount',
      key: 'amount',
      align: 'right',
      render: text => {
        return <Text className="total">{currency.format(text)}</Text>;
      }
    },
    {
      title: 'Từ chối bởi',
      dataIndex: 'rejected_by',
      key: 'rejected_by',
      render: rejected_by => {
        return <Text>{rejected_by?.[0]?.name}</Text>;
      }
    }
  ]
};
