import React from 'react';
import Popconfirm from 'antd/lib/popconfirm';
import Tag from 'antd/lib/tag';
import { any, func, string } from 'prop-types';
import { MessageContent } from '../../contents/Labels';

const styled = {
  style: { cursor: 'pointer' }
};

const PopupConfirm = ({ title, status, record, onChangeStatus }) => {
  return (
    <Popconfirm
      title={title}
      onConfirm={onChangeStatus(record)}
      okText={MessageContent.YesContent}
      cancelText={MessageContent.NoContent}
    >
      {status ? (
        <Tag {...styled} color="success">
          {MessageContent.Active}
        </Tag>
      ) : (
        <Tag {...styled} color="error">
          {MessageContent.Inactive}
        </Tag>
      )}
    </Popconfirm>
  );
};

PopupConfirm.propTypes = {
  title: string,
  status: any,
  record: any,
  onChangeStatus: func
};

PopupConfirm.defaultProps = {
  title: '',
  status: null,
  record: null,
  onChangeStatus: null
};

export default PopupConfirm;
