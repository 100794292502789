import { call, put, select } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';

export function* getListDepositForTransaction() {
  try {
    const transactions = yield select(state => state.transaction.list.transactions);
    const customerIds = transactions.map(item => {
      const [{ odoo_customer_id } = {}] = item.user_id;
      return odoo_customer_id;
    });

    const { data } = yield call(services.getDepositList, customerIds);

    const newTrans = transactions.map(item => {
      const [{ odoo_customer_id } = {}] = item.user_id;
      const { balance: deposit = 0 } = data.find(
        x => x.partner_id === odoo_customer_id
      );

      return { ...item, deposit };
    });

    yield put({ type: TYPE.LIST_DEPOSITS_SUCCESS, data: newTrans });
  } catch (err) {
    yield put({ type: TYPE.LIST_DEPOSITS_FAILED, message: err });
  }
}

export function* getListDepositForCustomer() {
  try {
    const customers = yield select(state => state.customer.list.customers);
    const customerIds = customers.map(item => item.odoo_customer_id);

    const { data } = yield call(services.getDepositList, customerIds);

    const newCustomers = customers.map(item => {
      const { odoo_customer_id } = item;
      const deposit = data.find(x => x.partner_id === odoo_customer_id);
      return { ...item, deposit };
    });

    yield put({ type: TYPE.LIST_DEPOSITS_FOR_CUSTOMER_SUCCESS, data: newCustomers });
  } catch (err) {
    yield put({ type: TYPE.LIST_DEPOSITS_FOR_CUSTOMER_FAILED, message: err });
  }
}

export function* getListDepositForWithdrawal() {
  try {
    const withdrawals = yield select(state => state.withdrawal.list.withdrawals);
    const customerIds = withdrawals.map(item => {
      const [{ odoo_customer_id } = {}] = item.user;
      return odoo_customer_id;
    });

    const { data } = yield call(services.getDepositList, customerIds);
    const newWithdrawal = withdrawals.map(item => {
      const [{ odoo_customer_id } = {}] = item.user;
      const { balance: deposit = 0 } = data.find(
        x => x.partner_id === odoo_customer_id
      );
      return { ...item, deposit };
    });

    yield put({
      type: TYPE.LIST_DEPOSITS_FOR_WITHDRAWAL_SUCCESS,
      data: newWithdrawal
    });
  } catch (err) {
    yield put({ type: TYPE.LIST_DEPOSITS_FOR_WITHDRAWAL_FAILED, message: err });
  }
}

export function* getListDepositForExchangePointRequest() {
  try {
    const exchangePointRequest = yield select(
      state => state.exchangePointRequest?.list?.data
    );
    const customerIds = exchangePointRequest.map(item => {
      const [{ odoo_customer_id } = {}] = item.user;
      return odoo_customer_id;
    });

    const { data } = yield call(services.getDepositList, customerIds);
    const newExchangePointRequest = exchangePointRequest.map(item => {
      const [{ odoo_customer_id } = {}] = item.user;
      const { balance: deposit = 0 } = data.find(
        x => x.partner_id === odoo_customer_id
      );
      return { ...item, deposit };
    });

    yield put({
      type: TYPE.LIST_DEPOSITS_FOR_EXCHANGE_POINT_REQUEST_SUCCESS,
      data: newExchangePointRequest
    });
  } catch (err) {
    yield put({
      type: TYPE.LIST_DEPOSITS_FOR_EXCHANGE_POINT_REQUEST_FAILED,
      message: err
    });
  }
}

export function* getListPointForExchangePointRequest() {
  try {
    const exchangePointRequest = yield select(
      state => state.exchangePointRequest?.list?.data
    );

    const customerIds = exchangePointRequest.map(item => {
      const [{ _id } = {}] = item.user;
      return _id;
    });

    const { data } = yield call(services.getPointList, customerIds);
    const newExchangePointRequest = exchangePointRequest.map(item => {
      const [{ _id } = {}] = item.user;
      const { cumulative_point = 0 } = data.find(x => x.user_id === _id);
      return { ...item, point: cumulative_point };
    });

    yield put({
      type: TYPE.LIST_POINT_FOR_EXCHANGE_POINT_REQUEST_SUCCESS,
      data: newExchangePointRequest
    });
  } catch (err) {
    yield put({
      type: TYPE.LIST_POINT_FOR_EXCHANGE_POINT_REQUEST_FAILED,
      message: err
    });
  }
}

export function* getListDepositForTransactionDeal() {
  try {
    const dealPurchaseTransactions = yield select(
      state => state.transactionDeal.list.transactions
    );
    const customerIds = dealPurchaseTransactions.map(item => {
      const { odoo_customer_id } = item.deal_order_id.user;
      return odoo_customer_id;
    });

    const { data } = yield call(services.getDepositList, customerIds);

    const newTrans = dealPurchaseTransactions.map(item => {
      const { odoo_customer_id } = item.deal_order_id.user;
      const { balance: deposit = 0 } = data.find(
        x => x.partner_id === odoo_customer_id
      );

      return { ...item, deposit };
    });

    yield put({ type: TYPE.LIST_DEPOSITS_SUCCESS, data: newTrans });
  } catch (err) {
    yield put({ type: TYPE.LIST_DEPOSITS_FAILED, message: err });
  }
}
