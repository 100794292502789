/* eslint-disable func-call-spacing */
import React, { useEffect } from 'react';
import { Switch, Route, Router, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import Spin from "antd/lib/spin";
import { any, arrayOf } from 'prop-types';
import history from '../navigation';
import { IS_AUTH, RoutePages, Routes } from '../contents/Constants';
import OutLayout from '../components/OutLayout';
import actions from '../redux/actions';
import Login from './Login';

const PrivateRoute = ({ children, ...rest }) => {
  const isAuth = localStorage.getItem(IS_AUTH);
  return (
    <Route
      {...rest}
      render={routeProps => {
        return isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: Routes.Login,
              state: { from: routeProps.location }
            }}
          />
        );
      }}
    />
  );
};

PrivateRoute.propTypes = {
  children: arrayOf(any)
};

PrivateRoute.defaultProps = {
  children: []
};

const RootApp = () => {
  const dispatch = useDispatch();

  // const loading = useSelector(state => state.common.loading);

  useEffect(() => {
    dispatch(actions.handleCheckAuth());
    dispatch(actions.getConfiguration());
  }, [dispatch]);

  return (
    <Router history={history}>
      {/* <Spin spinning={loading} size="large"> */}
      <Switch>
        <Route
          path="/"
          exact
          component={props => (
            <OutLayout {...props}>
              <Login />
            </OutLayout>
          )}
        />
        {RoutePages.public
          .filter(item => !item?.includes)
          .map(route => (
            <Route
              key={route.name}
              path={route.path}
              exact
              component={props => (
                <route.Layout {...props}>
                  <route.Page />
                </route.Layout>
              )}
            />
          ))}
        <PrivateRoute path="/">
          {RoutePages.private
            .filter(item => !item?.includes)
            .map(route => (
              <Route
                key={route.name}
                path={route.path}
                exact
                component={props => (
                  <route.Layout {...props}>
                    <route.Page />
                  </route.Layout>
                )}
              />
            ))}
        </PrivateRoute>
      </Switch>
      {/* </Spin> */}
    </Router>
  );
};

export default RootApp;
