import { all, takeLatest, call, put } from 'redux-saga/effects';
import TYPE from '../actions/TYPE';
import services from '../../services';
import { notificationError } from '../../utils/Notification';

function* list() {
  try {
    const result = yield call(services.marketplace.campaign.list);
    if (!result?.success) {
      notificationError(result?.message);
      return;
    }

    yield put({ type: TYPE.LIST_CAMPAIGN_SUCCESS, data: result?.data });
  } catch (error) {
    yield put({ type: TYPE.LIST_CAMPAIGN_FAILED, error });
  }
}

function* create({ payload }) {
  const { data, callback } = payload;
  try {
    const result = yield call(services.marketplace.campaign.create, data);
    if (!result?.success) {
      callback({ error: true, data: null });
      return;
    }

    yield put({ type: TYPE.CREATE_CAMPAIGN_SUCCESS, data: result?.data });
    yield list();
    callback({ error: false, data: result?.data });
  } catch (error) {
    yield put({ type: TYPE.CREATE_CAMPAIGN_FAILED, error });
  }
}

function* update({ payload }) {
  try {
    const result = yield call(services.marketplace.campaign.update, payload);
    if (!result?.success) {
      return;
    }

    yield list();
  } catch (error) {
    yield put({ type: TYPE.UPDATE_CAMPAIGN_FAILED, error });
  }
}

function* history({ payload }) {
  try {
    const result = yield call(services.marketplace.campaign.history, payload);
    if (!result?.success) {
      return;
    }

    yield put({ type: TYPE.GET_CAMPAIGN_HISTORY_SUCCESS, payload: result?.data });
  } catch (error) {
    yield put({ type: TYPE.GET_CAMPAIGN_HISTORY_FAILED, error });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.LIST_CAMPAIGN, list),
    takeLatest(TYPE.CREATE_CAMPAIGN, create),
    takeLatest(TYPE.UPDATE_CAMPAIGN, update),
    takeLatest(TYPE.GET_CAMPAIGN_HISTORY, history)
  ]);
}
