import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { Button, Card, Row, Spin } from 'antd';
import ButtonGroup from 'antd/lib/button/button-group';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import shortid from 'shortid';
import { MESSAGE_CONTENT } from '../../../contents/Constants';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
import { notificationSuccess } from '../../../utils/Notification';
import FormEdit from '../fragments/FormEdit';
import '../style.less';

function ContentTheme() {
  const configuration = useSelector(state => {
    return get(state, 'configuration.content', {});
  });
  const [contents, setContents] = useState({});
  const [termAndCondition, setTermAndCondition] = useState('');
  const [loading, setLoading] = useState(false);

  const term = useSelector(state => {
    return get(state, 'configuration.content.term_and_condition', '');
  });
  useEffect(() => {
    setTermAndCondition(term);
  }, [term]);

  useEffect(() => {
    if (!isEmpty(configuration)) {
      const helps = configuration.helps.map(item => {
        return {
          ...item,
          id: shortid.generate()
        };
      });

      setContents({
        ...configuration,
        helps
      });
    }
  }, [configuration]);

  const onSave = () => {
    setLoading(true);
    dispatch(
      actions.updateConfiguration({
        data: {
          content: { ...contents, term_and_condition: termAndCondition.toString() }
        },
        callback: ({ error }) => {
          setTimeout(() => {
            setLoading(false);
            if (!error) {
              notificationSuccess(MESSAGE_CONTENT.updateSuccess);
            }
          }, 1 * 1000);
        }
      })
    );
  };

  const onReset = () => {
    setContents({
      ...configuration,
      helps: [...configuration.helps].map(item => {
        return {
          ...item,
          id: shortid.generate()
        };
      })
    });
  };

  // const handleChangeData = ({ name, value }) => {
  //   setContents({
  //     ...contents,
  //     [name]: value
  //   });
  // };

  // const handleChangeInfo = ({ name, value }) => {
  //   setContents({
  //     ...contents,
  //     purchase_review: {
  //       ...contents.purchase_review,
  //       [name]: value
  //     }
  //   });
  // };

  const handleChangeForm = ({ id, name, value }) => {
    const newHelps = get(contents, 'helps', []).map(item => {
      if (item.id !== id) {
        return item;
      }
      return {
        ...item,
        [name]: value
      };
    });

    setContents({
      ...contents,
      helps: newHelps
    });
  };

  const handleAddQuestion = () => {
    setContents({
      ...contents,
      helps: [...contents.helps, { ask: '', answer: '', id: shortid.generate() }]
    });
  };
  const handleDeleteQuestion = id => {
    setContents({
      ...contents,
      helps: [...contents.helps].filter(item => {
        return item.id !== id;
      })
    });
  };

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <ButtonGroup className="btn-action">
          <Button className="save" onClick={onSave}>
            Lưu lại
          </Button>
          <Button type="default" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
        <Row>
          <Card title="Điều khoản và điều kiện" className="card">
            <div className="input-editor">
              <CKEditor
                editor={DecoupledEditor}
                data={termAndCondition}
                onReady={editor => {
                  editor.ui
                    .getEditableElement()
                    .parentElement.insertBefore(
                      editor.ui.view.toolbar.element,
                      editor.ui.getEditableElement()
                    );
                }}
                onChange={(_, editor) => setTermAndCondition(editor.getData())}
              />
            </div>
          </Card>
        </Row>
        <Row>
          <Card title="Trợ giúp" className="card">
            {get(contents, 'helps', []).map((item, index) => {
              return (
                <FormEdit
                  key={index}
                  index={index + 1}
                  data={item}
                  onChangeData={handleChangeForm}
                  onDeleteData={handleDeleteQuestion}
                />
              );
            })}
            <Button block type="primary" onClick={handleAddQuestion}>
              Thêm câu hỏi
            </Button>
          </Card>
        </Row>
        {/* <Row>
          <Card title="Thông tin đơn hàng" className="card">
            <InputEdit
              title="Nhắc nhở"
              name="remind"
              value={get(contents, 'purchase_review.remind')}
              onChangeData={handleChangeInfo}
            />
            <InputEdit
              title="Cảnh báo"
              name="warning"
              value={get(contents, 'purchase_review.warning')}
              onChangeData={handleChangeInfo}
            />
          </Card>
        </Row> */}
      </div>
    </Spin>
  );
}

export default ContentTheme;
