import { findIndex, get } from 'lodash';
import TYPE from '../actions/TYPE';

const initialState = {
  list: {},
  detail: {},
  disable: [],
  loading: false
};

const location = (state = initialState, action = {}) => {
  const list = get(state, 'list.data.results', []);

  const onMappingFormat = (listData, data) => {
    const { websites } = listData.reduce(
      (result, quote) => {
        if (quote._id === data._id) {
          result.websites.push(data);
        } else result.websites.push(quote);
        return result;
      },
      { websites: [] }
    );
    return websites;
  };

  switch (action.type) {
    case TYPE.GET_WEBSITE_LIST_CRAWLER_SUCCESS:
      return {
        ...state,
        list: get(action, 'data'),
        loading: false
      };

    case TYPE.ADD_FORMAT_WEBSITE_CRAWLER_SUCCESS:
      const { data: editData } = action;
      return {
        ...state,
        detail: editData,
        loading: !state.loading,
        list: {
          data: {
            ...state.list.data,
            results: onMappingFormat(list, editData)
          }
        }
      };

    case TYPE.EDIT_FORMAT_WEBSITE_CRAWLER_SUCCESS:
      const { data } = action;
      return {
        ...state,
        list: {
          data: {
            ...state.list.data,
            results: onMappingFormat(list, data)
          }
        }
      };
    case TYPE.GET_LIST_WEBSITE_DISABLE_US_SUCCESS:
      return {
        ...state,
        disable: action.payload
      };
    case TYPE.DELETE_WEBSITE_SUCCESS: {
      const newList = { ...state.list.data.result };
      const index = findIndex(newList, { _id: action.id });
      if (index !== -1) {
        newList.splice(index, 1);
      }
      return {
        ...state,
        list: {
          data: {
            ...state.list.data,
            result: newList,
            count: state.list.data.count - 1
          }
        }
      };
    }
    default:
      return state;
  }
};

export default location;
