import { CloseOutlined } from '@ant-design/icons';
import { Badge, Card, Divider, Drawer, List } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import actions from '../../../../redux/actions';
import { convertDollar, convertVND } from '../../../../utils/convert';
import dispatch from '../../../../utils/dispatch';
import CardLayout from '../../../Transaction/TransactionDetail/Component/CardLayout';
import ContentItem from '../../../Transaction/TransactionDetail/Component/Content';

export default function TransactionDealDetail() {
  const deal = useSelector(state => get(state, 'transactionDeal.detail', {}));
  const name = get(deal, 'deal_order_id.history.name', '');
  const avatar = get(deal, 'deal_order_id.history.avatar', '');
  const rate = get(deal, 'deal_order_id.history.rate', '');
  const price = get(deal, 'deal_order_id.history.price', '');
  const open_sale = get(deal, 'deal_order_id.history.time_open_sell');
  const end_sale = get(deal, 'deal_order_id.history.time_end_sell');
  const expired = get(deal, 'deal_order_id.history.expired_date');
  const amount = get(deal, 'deal_order_id.amount', '');

  const user_name = get(deal, 'deal_order_id.user.name', '');
  const phone = get(deal, 'deal_order_id.user.phone', '');
  const email = get(deal, 'deal_order_id.user.email', '');

  const payment_type = get(deal, 'transactions[0].type', '');
  const bank_name = get(deal, 'transactions[0].content.bank_refer[0].name', '');
  const path = get(deal, 'transactions[0].content.bank_refer[0].path', '');
  const sort_name = get(deal, 'transactions[0].content.bank_refer[0].sortName', '');
  const account_holder = get(
    deal,
    'transactions[0].content.bank_refer[0].account_holder',
    ''
  );
  const account_number = get(
    deal,
    'transactions[0].content.bank_refer[0].account_number',
    ''
  );

  const handleClose = () => {
    dispatch(actions.clearDealDetail());
  };

  const dealInfor = [
    {
      title: 'Tên deal',
      value: name
    },
    {
      title: 'Nhà cung cấp',
      value: 'BuyNgon'
    },
    {
      title: 'Avatar',
      value: <img style={{ width: 50, height: 50 }} src={avatar} />
    },
    {
      title: 'Rate',
      value: convertVND(rate)
    },
    {
      title: 'Hạn mức của deal',
      value: convertDollar(price)
    },
    {
      title: 'Ngày mở bán',
      value: open_sale ? moment(open_sale).format('HH:mm:ss, DD/MM/YYYY') : ''
    },
    {
      title: 'Ngày kết thúc ',
      value: end_sale ? moment(end_sale).format('HH:mm:ss, DD/MM/YYYY') : ''
    },
    {
      title: 'Ngày hết hạn',
      value: expired ? moment(expired).format('HH:mm:ss, DD/MM/YYYY') : ''
    }
  ];
  const priceInfor = [
    {
      title: 'Số lượng',
      value: ` ${amount} USD`
    },
    {
      title: 'Rate',
      value: convertVND(rate)
    },
    {
      title: 'Tổng số tiền cần thanh toán',
      value: convertVND(amount * rate)
    }
  ];

  const userInfor = [
    {
      title: 'Họ Và Tên',
      value: user_name
    },
    {
      title: 'SDT',
      value: phone
    },
    {
      title: 'Email',
      value: email
    }
  ];

  // const bankInfor = [
  //   {
  //     title: 'Tên ngân hàng',
  //     value: bank_name
  //   },
  //   {
  //     title: 'Chủ tài khoản',
  //     value: account_holder
  //   },
  //   {
  //     title: 'Số tài khoản',
  //     value: account_number
  //   }
  // ];

  return (
    <Drawer
      title="CHI TIẾT DEAL"
      placement="left"
      visible={deal}
      width={1000}
      closeIcon={<CloseOutlined onClick={handleClose} />}
    >
      <CardLayout title="Thông tin deal">
        {dealInfor.map((item, key) => (
          <>
            <ContentItem title={item.title} value={item.value} key={key} />
            <Divider />
          </>
        ))}
      </CardLayout>
      <CardLayout title="Giá trị đơn hàng">
        {priceInfor.map((item, key) => (
          <>
            <ContentItem title={item.title} value={item.value} key={key} />
            <Divider />
          </>
        ))}
      </CardLayout>
      <CardLayout title="Thông tin khách hàng">
        {userInfor.map((item, key) => (
          <>
            <ContentItem title={item.title} value={item.value} key={key} />
            <Divider />
          </>
        ))}
      </CardLayout>
      {payment_type === 'banking' && (
        <CardLayout title="Thông tin ngân hàng">
          <Badge.Ribbon color="green" text="Được chọn">
            <List.Item>
              <Card
                style={{ width: '100%' }}
                title={
                  <div>
                    <img src={path} alt="icon" />
                    <br />
                    <b style={{ padding: 10 }}>{sort_name}</b>
                  </div>
                }
                bordered={false}
                size="small"
              >
                <b>Tên chủ tài khoản:&nbsp;</b> {account_holder} <br />
                <b>Số tài khoản:&nbsp;</b> {account_number} <br />
                <b>Ngân hàng:&nbsp;</b> {bank_name} <br />
              </Card>
            </List.Item>
          </Badge.Ribbon>
        </CardLayout>
      )}
    </Drawer>
  );
}
