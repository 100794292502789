import React from 'react';

export default function ImageIcon() {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18 0H2C0.895 0 0 0.895 0 2V14C0 15.105 0.895 16 2 16H18C19.105 16 20 15.105 20 14V2C20 0.895 19.105 0 18 0ZM8 4C8.552 4 9 4.448 9 5C9 5.552 8.552 6 8 6C7.448 6 7 5.552 7 5C7 4.448 7.448 4 8 4ZM16 13H4.022C3.606 13 3.372 12.521 3.628 12.193L6.118 8.991C6.314 8.739 6.693 8.733 6.897 8.979L9 11.51L12.098 7.518C12.3 7.257 12.695 7.261 12.893 7.525L16.4 12.2C16.647 12.53 16.412 13 16 13Z"
        fill="#E3E3E3"
      />
    </svg>
  );
}
