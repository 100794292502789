import { Select, Spin, Typography } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { WithdrawModalContext } from '../../pages/DealWithdraw/component/CreateNewWithdrawModal';
import ListAddress from '../ListAddress';
import './style.less';

const { Text } = Typography;

function getDealItem(deal) {
  const dealValue = deal?._id;
  const dealLabel = deal?.deal_purchase_transaction?.deal_order_id?.history?.name;
  return { ...deal, value: dealValue, label: dealLabel };
}

export default function SearchDeal() {
  const loading = useSelector(
    state => !!state.common?.loadingImprove?.getListDealByUser
  );
  const { selectedDeal, setSelectedDeal } = useContext(WithdrawModalContext);
  const listDealOfUser = useSelector(
    state => state.transactionDeal.listDealOfUser || []
  );
  const listOption = listDealOfUser.map(item => getDealItem(item));

  const handleChange = value => {
    const dealItem = listDealOfUser.find(item => item?._id === value);
    const newData = getDealItem(dealItem);
    setSelectedDeal(newData);
  };

  useEffect(() => {
    const dealItem = listDealOfUser?.[0];
    const newData = getDealItem(dealItem);
    setSelectedDeal(newData);
  }, [listDealOfUser, setSelectedDeal]);

  return (
    <Spin spinning={loading}>
      <Text strong>Chọn deal muốn hoàn trả:</Text>
      <Select
        style={{ width: '100%' }}
        placeholder="Chọn deal"
        value={selectedDeal}
        onChange={handleChange}
        options={listOption}
      />
      <ListAddress.DealItem {...selectedDeal} className="address-item" />

      {/* <AmountInput {...selectedDeal} /> */}
    </Spin>
  );
}
