import { all, call, put, putResolve, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import actions from '../actions';
import TYPE from '../actions/TYPE';

function* createTag(action) {
  try {
    yield put(actions.enableLoading());
    const data = yield call(services.createTag, action.payload);
    yield put({ type: TYPE.CREATE_TAG_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.CREATE_TAG_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* getListTags() {
  try {
    yield put(actions.enableLoading());
    const data = yield call(services.getListTags);
    yield put({ type: TYPE.GET_LIST_TAGS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_TAGS_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* updateTag(action) {
  try {
    yield put(actions.enableLoading());
    const data = yield call(services.updateTag, action.payload);
    yield put({ type: TYPE.UPDATE_TAG_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_TAG_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* createWebsite(action) {
  try {
    yield put(actions.enableLoading());
    const { data } = yield call(services.createWebsite, action.payload);
    yield put({ type: TYPE.CREATE_WEBSITE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.CREATE_WEBSITE_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* getListWebsites() {
  try {
    yield put(actions.enableLoading());
    const { data } = yield call(services.getListWebsites);
    yield put({ type: TYPE.GET_LIST_WEBSITES_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_WEBSITES_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* updateWebsite(action) {
  try {
    yield put(actions.enableLoading());
    const { data } = yield call(services.updateWebsite, action.payload);
    yield put({ type: TYPE.UPDATE_WEBSITE_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_WEBSITE_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* createDNCategory(action) {
  try {
    yield put(actions.enableLoading());
    const { data } = yield call(services.createDNCategory, action.payload);
    yield put({ type: TYPE.CREATE_DN_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.CREATE_DN_CATEGORY_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* updateDNCategory(action) {
  try {
    yield put(actions.enableLoading());
    const { data } = yield call(services.updateDNCategory, action.payload);
    yield put({ type: TYPE.UPDATE_DN_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_DN_CATEGORY_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* getListDNCategories(action) {
  try {
    yield put(actions.enableLoading());
    const { data } = yield call(services.getListDNCategories, action.payload);
    yield put({ type: TYPE.GET_LIST_DN_CATEGORIES_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_DN_CATEGORIES_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* createIcon(action) {
  try {
    yield put(actions.enableLoading());
    const data = yield call(services.createIcon, action.payload);
    yield put({ type: TYPE.CREATE_ICON_SUCCESS, payload: data });
  } catch (err) {
    yield put({ type: TYPE.CREATE_ICON_FAILED, msg: err });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* getListIcons() {
  try {
    yield put(actions.enableLoading());
    const data = yield call(services.getListIcons);
    yield put({ type: TYPE.GET_LIST_ICONS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_ICONS_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* getListIconsOther() {
  try {
    const data = yield call(services.getListIcons);
    yield put({ type: TYPE.GET_LIST_ICONS_OTHER_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_LIST_ICONS_OTHER_FAILED, msg: error });
  }
}

function* getListPromotionUs() {
  try {
    const data = yield call(services.geListPromotionUs);
    yield put({ type: TYPE.GET_PROMOTION_US_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.GET_PROMOTION_FAILED, msg: error });
  }
}

function* deleteIcon(action) {
  try {
    const { payload: id } = action;
    yield call(services.deleteIcon, id);
    yield putResolve({ type: TYPE.DELETE_ICON_SUCCESS, payload: id });
    yield putResolve({ type: TYPE.GET_LIST_DN_CATEGORIES });
    yield putResolve({ type: TYPE.GET_LIST_WEBSITES });
  } catch (error) {
    yield put({ type: TYPE.DELETE_ICON_FAILED, msg: error });
  }
}

function* deleteTag(action) {
  try {
    const { payload: id } = action;
    yield put(actions.enableLoading());
    yield call(services.deleteTag, id);
    yield putResolve({ type: TYPE.DELETE_TAG_SUCCESS, payload: id });
    yield putResolve({ type: TYPE.GET_LIST_TAGS });
    yield putResolve({ type: TYPE.GET_LIST_DN_CATEGORIES });
    yield putResolve({ type: TYPE.GET_LIST_WEBSITES });
  } catch (error) {
    yield put({ type: TYPE.DELETE_TAG_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* deletePromotionUs(action) {
  try {
    yield put(actions.enableLoading());
    const data = yield call(services.deletePromotionUs, action.payload);
    const { _id: id } = data;
    yield put({ type: TYPE.DELETE_PROMOTION_US_SUCCESS, payload: id });
  } catch (error) {
    yield put({ type: TYPE.DELETE_PROMOTION_US_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* createPromotionUs(action) {
  try {
    yield put(actions.enableLoading());
    const data = yield call(services.createPromotionUs, action.payload);
    yield put({ type: TYPE.CREATE_PROMOTION_US_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.CREATE_PROMOTION_US_FAILED, msg: error });
  } finally {
    yield put(actions.disableLoading());
  }
}

function* updatePromotionUs(action) {
  try {
    yield put(actions.enableLoading());

    const data = yield call(services.updatePromotionUs, action.payload);

    yield put({ type: TYPE.UPDATE_PROMOTION_US_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.UPDATE_PROMOTION_US_FAILED });
  } finally {
    yield put(actions.disableLoading());
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.CREATE_TAG, createTag),
    takeLatest(TYPE.GET_LIST_TAGS, getListTags),
    takeLatest(TYPE.UPDATE_TAG, updateTag),
    takeLatest(TYPE.CREATE_WEBSITE, createWebsite),
    takeLatest(TYPE.GET_LIST_WEBSITES, getListWebsites),
    takeLatest(TYPE.UPDATE_WEBSITE, updateWebsite),
    takeLatest(TYPE.CREATE_DN_CATEGORY, createDNCategory),
    takeLatest(TYPE.UPDATE_DN_CATEGORY, updateDNCategory),
    takeLatest(TYPE.GET_LIST_DN_CATEGORIES, getListDNCategories),
    takeLatest(TYPE.CREATE_ICON, createIcon),
    takeLatest(TYPE.GET_LIST_ICONS, getListIcons),
    takeLatest(TYPE.GET_LIST_ICONS_OTHER, getListIconsOther),
    takeLatest(TYPE.DELETE_ICON, deleteIcon),
    takeLatest(TYPE.DELETE_TAG, deleteTag),
    takeLatest(TYPE.CREATE_PROMOTION_US, createPromotionUs),
    takeLatest(TYPE.GET_PROMOTION_US, getListPromotionUs),
    takeLatest(TYPE.UPDATE_PROMOTION_US, updatePromotionUs),
    takeLatest(TYPE.DELETE_PROMOTION_US, deletePromotionUs)
  ]);
}
