import TYPE from '../actions/TYPE';

const initialState = {
  list: {},
  pool: {},
  listVA: []
};

const bankTransfer = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_BANK_TRANSFER_POOL_SUCCESS:
      return {
        ...state,
        pool: action.data
      };

    case TYPE.GET_LIST_VA_SUCCESS:
      return {
        ...state,
        listVA: action.data
      };

    default:
      return state;
  }
};

export default bankTransfer;
