import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Spin, Tag } from 'antd';
import Button from 'antd/lib/button';
import get from 'lodash/get';
// import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizeTable from '../../components/CustomizeTable/CustomizeTable';
import FilterAndSearch from '../../components/FilterAndSearch';
import { LIMIT_PAGE_DEFAULT, SELLER_PROFILE_MODE } from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import actions from '../../redux/actions';
import dispatch from '../../utils/dispatch';
import CreateSellerProfile from './Create';
import EditSellerProfile from './Edit';

export const SellerProfileContext = createContext(null);

const SEARCH_SELLER = {
  text: '',
  state: ''
};

const Seller = () => {
  const { count, sellers } = useSelector(state => {
    return get(state, 'seller.list', { count: 0, sellers: [] });
  });
  const loading = useSelector(state => {
    return get(state, 'seller.isLoading', false);
  });
  // const hasDetail = useSelector(state => {
  //   return !isEmpty(get(state, 'seller.detail'));
  // });
  const [page, setPage] = useState(1);
  const [searchForm, setSearchForm] = useState(SEARCH_SELLER);

  const searchFormRef = useRef(SEARCH_SELLER);
  const mode = useSelector(state => state?.seller?.mode);

  useEffect(() => {
    const { text: searchParams, state } = searchForm;
    const filterStatus = state === 'all' ? null : { state };
    const pageIndex = searchFormRef.current === searchForm ? page : 1;

    searchFormRef.current = searchForm;
    setPage(pageIndex);

    dispatch(
      actions.listSellerProfile({
        searchParams,
        page: pageIndex,
        limit: LIMIT_PAGE_DEFAULT,
        ...filterStatus
      })
    );
  }, [page, searchForm]);

  const handleChangePage = pageIndex => {
    return setPage(pageIndex);
  };

  const data = map(sellers, item => {
    return {
      key: item._id,
      ...item.seller_profile,
      email: item.email,
      phone: item.phone
    };
  });

  const columns = [
    {
      title: MessageContent.Status,
      key: 'state',
      dataIndex: 'state',
      width: '8%',
      align: 'center',
      fixed: true,
      render: state => (
        <Tag color={state === 'active' ? 'success' : 'warning'}>{state}</Tag>
      )
    },
    {
      title: MessageContent.NameShop,
      dataIndex: 'name',
      key: 'name',
      render: text => {
        return (
          <Button type="link" className="name" onClick={() => {}}>
            {text}
          </Button>
        );
      }
    },
    {
      title: MessageContent.EmailShop,
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: MessageContent.PhoneShop,
      dataIndex: 'phone',
      key: 'phone',
      align: 'center'
    },
    {
      title: MessageContent.BuyNgonShop,
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      render: text => {
        return text === 'default' ? (
          <CloseOutlined style={{ fontSize: '25px', color: 'red' }} />
        ) : (
          <CheckOutlined style={{ fontSize: '25px', color: 'green' }} />
        );
      }
    },
    {
      title: MessageContent.Action,
      key: 'action',
      align: 'center',
      render: (_, record) => {
        return (
          <Button
            onClick={() => {
              dispatch(actions.changeModeSeller(SELLER_PROFILE_MODE.edit));
              dispatch(actions.openSellerProfileDetail(record));
            }}
            type="primary"
          >
            {MessageContent.Edit}
          </Button>
        );
      }
    }
  ];

  return (
    <SellerProfileContext.Provider value={{ searchForm, setSearchForm }}>
      <Spin spinning={loading}>
        <CustomizeTable
          title={MessageContent.SellerProfile}
          data={data}
          columns={columns}
          count={count}
          currentPage={page}
          onPageChange={handleChangePage}
          filterAndSearch={<FilterAndSearch.SellerProfile />}
        />
      </Spin>
      {mode === SELLER_PROFILE_MODE.create && <CreateSellerProfile />}
      {mode === SELLER_PROFILE_MODE.edit && <EditSellerProfile />}
    </SellerProfileContext.Provider>
  );
};

export default Seller;
