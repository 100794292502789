import Icon from '@ant-design/icons/lib/components/Icon';
import { Spin } from 'antd';
import Button from 'antd/lib/button';
import Skeleton from 'antd/lib/skeleton';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import CustomizeTable from '../../components/CustomizeTable/CustomizeTable';
import FilterAndSearch from '../../components/FilterAndSearch';
import Facebook from '../../components/icons/Facebook';
import Google from '../../components/icons/Google';
import Phone from '../../components/icons/Phone';
import User from '../../components/icons/User';
import PopupConfirm from '../../components/Popup/PopupConfirm';
import { LIMIT_PAGE_DEFAULT, PROVIDERS } from '../../contents/Constants';
import { MessageContent } from '../../contents/Labels';
import actions from '../../redux/actions';
import currency from '../../utils/currency';
import dispatch from '../../utils/dispatch';
import CustomerDetail from './CustomerDetail';
import WalletDetail from './CustomerDetail/walletDetail/walletDetail';

export const CustomerContext = createContext(null);

const SEARCH_CUSTOMER = {
  text: '',
  status: 'all',
  provider: 'all'
};

const Customer = () => {
  const { count, customers } = useSelector(state => {
    return get(state, 'customer.list', { count: 0, customers: [] });
  });
  const loading = useSelector(state => {
    return get(state, 'customer.isLoading', false);
  });
  const hasDetail = useSelector(state => {
    return !isEmpty(get(state, 'customer.detail'));
  });
  const [page, setPage] = useState(1);
  const [reset, setReset] = useState(false);
  const [searchForm, setSearchForm] = useState(SEARCH_CUSTOMER);

  const searchFormRef = useRef(SEARCH_CUSTOMER);

  useEffect(() => {
    const { text: searchParams, status, provider } = searchForm;
    const filterStatus = status === 'all' ? null : { status };
    const filterProvider = provider === 'all' ? null : { provider };
    const pageIndex = searchFormRef.current === searchForm ? page : 1;

    searchFormRef.current = searchForm;
    setPage(pageIndex);

    dispatch(
      actions.searchCustomer({
        searchParams,
        page: pageIndex,
        limit: LIMIT_PAGE_DEFAULT,
        isEnableDeposit: true,
        ...filterStatus,
        ...filterProvider
      })
    );
  }, [page, searchForm]);

  const handleChangePage = pageIndex => {
    return setPage(pageIndex);
  };

  const data = map(customers, item => {
    return { key: item._id, ...item };
  });

  const changeStatusActivityCustomer = ({ key, status }) => {
    return () => {
      return dispatch(actions.changeStatusActivityCustomer({ id: key, status }));
    };
  };

  const getCustomerDetail = customer => {
    return dispatch(actions.getCustomerInfo(customer));
  };

  const showModal = record => {
    return () => {
      return getCustomerDetail(record);
    };
  };

  const closeModal = () => {
    getCustomerDetail(null);
    setReset(!reset);
  };

  const columns = [
    {
      title: MessageContent.Status,
      key: 'status',
      dataIndex: 'status',
      width: '8%',
      align: 'center',
      fixed: true,
      render: (status, record) => {
        return (
          <PopupConfirm
            title={MessageContent.ChangeStatusCustomer}
            status={status}
            record={record}
            onChangeStatus={changeStatusActivityCustomer}
          />
        );
      }
    },
    {
      title: MessageContent.Name,
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <Button type="link" className="name" onClick={showModal(record)}>
            {text}
          </Button>
        );
      }
    },
    {
      title: MessageContent.Email,
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: MessageContent.Phone,
      dataIndex: 'phone',
      key: 'phone',
      align: 'center'
    },
    {
      title: MessageContent.Balance,
      key: 'balance',
      align: 'right',
      render: value => {
        if (!isNumber(value?.deposit?.balance)) {
          return <Skeleton title={false} paragraph={{ rows: 1, width: '100%' }} />;
        }
        const text = `${currency.format(value?.deposit?.balance || 0)} đ`;
        return <p>{text}</p>;
      }
    },
    {
      title: MessageContent.Wallet,
      key: 'wallet',
      align: 'center',
      render: value => {
        return (
          <Button
            disabled={!isNumber(value?.deposit?.balance)}
            onClick={() => dispatch(actions.getInfoWallet(value))}
            type="primary"
          >
            {MessageContent.Detail}
          </Button>
        );
      }
    },
    {
      title: MessageContent.Provider,
      dataIndex: 'providers',
      key: 'providers',
      align: 'center',
      render: value => {
        if (value === PROVIDERS.GOOGLE) return <Icon component={Google} />;
        if (value === PROVIDERS.FACEBOOK) return <Icon component={Facebook} />;
        if (value === PROVIDERS.ACCOUNT) return <Icon component={User} />;
        return <Icon component={Phone} />;
      }
    }
  ];

  return (
    <CustomerContext.Provider value={{ searchForm, setSearchForm }}>
      <Spin spinning={loading}>
        <CustomizeTable
          title={MessageContent.CustomerInfo}
          data={data}
          columns={columns}
          count={count}
          currentPage={page}
          onPageChange={handleChangePage}
          filterAndSearch={<FilterAndSearch.Customer />}
        />
      </Spin>
      {hasDetail && (
        <CustomerDetail
          visible={hasDetail}
          onOk={closeModal}
          onCancel={closeModal}
        />
      )}
      <WalletDetail />
    </CustomerContext.Provider>
  );
};

export default Customer;
