import { CheckOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Empty,
  Form,
  Modal,
  Row,
  Spin,
  Typography
} from 'antd';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  any,
  arrayOf,
  bool,
  func,
  number,
  object,
  objectOf,
  string
} from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import actions from '../../redux/actions';
import { convertAddress, convertDollar } from '../../utils/convert';
import dispatch from '../../utils/dispatch';
import { notificationError } from '../../utils/Notification';
import FormAddress from '../FormAddress';
import './style.less';

const { Meta } = Card;
const { Text, Title } = Typography;

export default function ListAddress({ user, listAddress, listDeal }) {
  const [showModalAddAddress, setShowModalAddAddress] = useState(false);
  const loading = useSelector(state => {
    return get(state, 'common.loading', false);
  });

  const handleAddAddress = () => {
    setShowModalAddAddress(true);
  };

  return (
    <Spin spinning={loading}>
      <Row className="list-address">
        <Col span={24}>
          <Title level={5}>Danh sách địa chỉ khách hàng</Title>
          <Divider />
          <Button className="btn" onClick={handleAddAddress} block type="primary">
            {'Thêm địa chỉ '} <PlusOutlined />
          </Button>
          {showModalAddAddress && (
            <ListAddress.Modal
              visible={showModalAddAddress}
              setIsVisible={setShowModalAddAddress}
              userId={get(user, '_id')}
            />
          )}
          {listAddress.map(addr => {
            return (
              <ListAddress.Item
                key={addr._id}
                className="address-item"
                address={addr}
              />
            );
          })}

          {listAddress.length === 0 && <Empty />}
        </Col>
      </Row>

      {!isEmpty(listDeal) && (
        <Row className="list-address">
          <Col span={24}>
            <Title level={5}>Danh sách deal</Title>
            <Divider />

            {listDeal.map((deal, index) => {
              return (
                <ListAddress.DealItem
                  {...deal}
                  key={index}
                  className="address-item"
                />
              );
            })}
          </Col>
        </Row>
      )}
    </Spin>
  );
}

ListAddress.propTypes = {
  user: objectOf(any),
  listAddress: arrayOf(any),
  listDeal: arrayOf(any)
};

ListAddress.defaultProps = {
  user: null,
  listAddress: [],
  listDeal: []
};

ListAddress.DealItem = function ListDealItem({
  deal_purchase_transaction,
  price_in_used,
  amount
}) {
  const name = get(deal_purchase_transaction, 'deal_order_id.history.name', '');
  const avatar = get(deal_purchase_transaction, 'deal_order_id.history.avatar', '');

  const dealAmount = `Tổng giá trị: ${convertDollar(amount)}`;
  const dealUsed = `Đã sử dụng ${convertDollar(price_in_used)}`;
  const dealResidual = `Còn lại ${convertDollar(amount - price_in_used)}`;

  return (
    <Card>
      <Meta
        avatar={<Avatar src={avatar} />}
        title={name}
        description={
          <>
            {`${dealAmount} | ${dealUsed} | `}
            <Text strong>{`${dealResidual}`}</Text>
          </>
        }
      />
    </Card>
  );
};

ListAddress.DealItem.propTypes = {
  deal_purchase_transaction: object,
  price_in_used: number,
  amount: number
};

ListAddress.DealItem.defaultProps = {
  deal_purchase_transaction: {},
  price_in_used: 0,
  amount: 0
};

ListAddress.Item = function ListAddressItem({ address, ...restProps }) {
  const { name, phone, selected } = address;
  const userId = useSelector(state => {
    return get(state, 'customer.userSelected._id');
  });

  const deliveryAddress = convertAddress(address);

  const handleSelectAddress = () => {
    dispatch(actions.selectedDeliveryAddress(address._id, userId), true);
  };

  return (
    <Row {...restProps} justify="space-between">
      <Col span={16}>
        <Title level={5}>{name}</Title>
        <Text type="secondary">{phone}</Text> <br />
        <Text type="secondary">{deliveryAddress}</Text>
      </Col>
      <Col span={7} offset={1}>
        {selected ? (
          <Button type="primary">
            {'Đã chọn '} <CheckOutlined />
          </Button>
        ) : (
          <Button onClick={handleSelectAddress}>Chọn</Button>
        )}
      </Col>
    </Row>
  );
};

ListAddress.Item.propTypes = {
  address: objectOf(any)
};

ListAddress.Item.defaultProps = {
  address: null
};

ListAddress.Modal = function ListAddressModal({ visible, setIsVisible, userId }) {
  const [form] = Form.useForm();
  const [isVN, setIsVN] = useState(true);
  const loading = useSelector(state => {
    return get(state, 'common.loading', false);
  });

  const handleCancel = () => {
    form.resetFields();
    setIsVisible(false);
    setIsVN(true);
  };

  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const data = form.getFieldsValue();
      dispatch(actions.createDeliveryAddress(userId, data), true);
      form.resetFields();
      setIsVisible(false);
      setIsVN(true);
    } catch (err) {
      notificationError('Vui lòng nhập đầy đủ thông tin');
    }
  };

  useEffect(() => {
    dispatch(actions.getAllProvince());
  }, []);

  return (
    <Spin spinning={loading}>
      <Modal
        title="Thêm địa chỉ"
        visible={visible}
        onOk={handleSubmit}
        onCancel={handleCancel}
      >
        <FormAddress isVN={isVN} setIsVN={setIsVN} form={form} />
      </Modal>
    </Spin>
  );
};

ListAddress.Modal.propTypes = {
  visible: bool,
  setIsVisible: func,
  userId: string
};

ListAddress.Modal.defaultProps = {
  visible: false,
  setIsVisible: () => {},
  userId: ''
};
