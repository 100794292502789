import { searchListDeal, updateStateDeal } from '../../utils/helpers';
import TYPE from '../actions/TYPE';

const initialState = {
  list: {},
  listSearch: {},
  updated: null
};

const metadeal = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.LIST_META_DEAL_SUCCESS:
      return {
        ...state,
        list: action.data,
        listSearch: action.data
      };
    case TYPE.UPDATE_STATE_DEAL_SUCCESS:
      return {
        ...state,
        list: updateStateDeal([...state.list], action.payload),
        updateStateDeal: updateStateDeal([...state.listSearch], action.payload),
        updated: action.data
      };
    case TYPE.CLEAR_UPDATE_STATE_DEAL:
      return { ...state, updated: null };

    case TYPE.SEARCH_DEAL:
      const result = searchListDeal({ search: action.payload }, [
        ...state.listSearch
      ]);
      return {
        ...state,
        list: result
      };
    default:
      return state;
  }
};

export default metadeal;
