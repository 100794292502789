import React, { useContext } from 'react';
import Search from 'antd/lib/input/Search';
import Space from 'antd/lib/space';
import Select from 'antd/lib/select';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { MessageContent } from '../../contents/Labels';
import { SellerProfileContext } from '../../pages/SellerProfile';
import actions from '../../redux/actions';
import { SELLER_PROFILE_MODE } from '../../contents/Constants';

const { Option } = Select;

const FilterSearchSellerProfile = () => {
  const { searchForm, setSearchForm } = useContext(SellerProfileContext);
  const dispatch = useDispatch();
  const onTextSearch = text => {
    return setSearchForm({ ...searchForm, text });
  };
  const onStatusSearch = state => {
    return setSearchForm({ ...searchForm, state });
  };

  return (
    <Space>
      <Search
        allowClear
        placeholder={MessageContent.SearchBy}
        onSearch={onTextSearch}
        enterButton
      />
      <Select defaultValue="all" style={{ width: 180 }} onChange={onStatusSearch}>
        <Option value="all">Tất cả trạng thái</Option>
        <Option value="active">Active</Option>
        {/* <Option value="inactive">Block</Option>
        <Option value="pending">Pending</Option> */}
      </Select>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() =>
          dispatch(actions.changeModeSeller(SELLER_PROFILE_MODE.create))
        }
      >
        Tạo đối tác bán hàng
      </Button>
    </Space>
  );
};

export default FilterSearchSellerProfile;
