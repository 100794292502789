import { getStore } from '../App';
import actions from '../redux/actions';

const dispatch = (action, isLoading = false) => {
  if (isLoading) {
    getStore().dispatch(actions.loading());
  }
  getStore().dispatch(action);
};

export function* offLoading() {
  yield getStore().dispatch(actions.disableLoading());
}

export default dispatch;
