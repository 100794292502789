import { CloseOutlined } from '@ant-design/icons';
import { Button, Drawer, Form, Skeleton, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import actions from '../../../redux/actions';
import { convertVND } from '../../../utils/convert';
import dispatch from '../../../utils/dispatch';
import { removeAccentVietnamese } from '../../../utils/search';
import CardLayout from '../../Transaction/TransactionDetail/Component/CardLayout';
import ContentItem from '../../Transaction/TransactionDetail/Component/Content';
import './style.less';

const { Text } = Typography;

function generateTransferCode(userInfo) {
  const userId = userInfo?.odoo_customer_id || '';
  const username = removeAccentVietnamese(userInfo?.name || '');
  const suffix = 'rf';
  return `NGON${userId} ${username} ${suffix}`;
}

function useQRCode(userInfo, bankRefer, amount, isStart) {
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isStart) return;
    setLoading(true);
    fetch('https://api.vietqr.io/v2/generate', {
      method: 'POST',
      headers: {
        'x-client-id': '565020f6-833e-46ec-9c1b-9b425ddafc25',
        'x-api-key': 'dcf4a353-917f-4bbd-9556-74f401feebe0',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        accountNo: bankRefer?.acc_number,
        accountName: bankRefer?.acc_holder_name,
        acqId: bankRefer?.bin,
        addInfo: generateTransferCode(userInfo),
        amount,
        template: 'Doo0jB9'
      })
    })
      .then(response => response.json())
      .then(result => setUrl(result?.data?.qrDataURL))
      .catch(error => error)
      .finally(() => setLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStart]);

  return [url, loading];
}

export default function WithdrawalDetail() {
  const withdrawal = useSelector(
    state => state?.withdrawal?.detail || null,
    shallowEqual
  );
  const isLoading = useSelector(
    state => state?.withdrawal?.loading?.detail,
    shallowEqual
  );
  const showType = useSelector(
    state => state?.withdrawal?.showType || null,
    shallowEqual
  );
  // const buyngonBank = useSelector(state => state?.bank?.list || [], shallowEqual);
  const isShowConfirm = showType === 'detail';
  const isDraft = withdrawal?.state === 'draft';
  const [form] = Form.useForm();

  const isOpen = !!withdrawal && isShowConfirm;
  const [QRCodeUrl, loading] = useQRCode(
    withdrawal?.user?.[0],
    withdrawal?.bank_refer,
    withdrawal?.amount || 0,
    isOpen
  );

  const onCancel = () => {
    dispatch(actions.clearWithdrawalInfo(), false);
  };

  useEffect(() => {
    form.resetFields();
    dispatch(actions.getListBank());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  const customerInfo = [
    { title: 'Họ và tên:', value: withdrawal?.user?.[0]?.name || '' },
    { title: 'Số điện thoại: ', value: withdrawal?.user?.[0]?.phone || '' },
    { title: 'Email:', value: withdrawal?.user?.[0]?.email || '' }
  ];

  const bankCustomerInfo = [
    {
      title: 'Chủ tài khoản:',
      value: withdrawal?.bank_refer?.acc_holder_name || ''
    },
    { title: 'Số tài khoản: ', value: withdrawal?.bank_refer?.acc_number || '' },
    { title: 'Ngân hàng: ', value: withdrawal?.bank_refer?.name || '' },
    { title: 'Chi nhánh: ', value: withdrawal?.bank_refer?.branch || '' }
  ];

  const withdrawalInfo = [
    {
      title: 'Khoản dư ghi nhận: ',
      value: convertVND(withdrawal?.current_deposit || 0)
    },
    {
      title: 'Khoản dư hiện tại: ',
      value: (
        <Text style={{ color: 'red', fontWeight: 700 }}>
          {convertVND(withdrawal?.deposit || 0)}
        </Text>
      )
    },
    { title: 'Số tiền rút: ', value: `${convertVND(withdrawal?.amount || 0)}` },
    {
      title: 'Trạng thái',
      value: withdrawal?.bank_refer?.is_verify ? (
        <Text style={{ color: 'green', fontWeight: 700 }}>Đã được xác nhận</Text>
      ) : (
        <Text style={{ color: 'red', fontWeight: 700 }}>Chưa xác nhận</Text>
      )
    }
  ];

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const onFinish = data => {
    dispatch(
      actions.approveWithdrawal({
        ...data,
        amount: withdrawal?.amount,
        partner_id: withdrawal?.user?.[0]?.odoo_customer_id,
        acc_customer_number: withdrawal?.bank_refer?.acc_number,
        _id: withdrawal?._id
      })
    );
  };

  const onReject = () => {
    dispatch(actions.rejectWithdrawal({ withdrawal_id: withdrawal._id }));
  };

  const actionButtons = action => [
    <Button
      key="cf"
      type="primary"
      onClick={() => action.submit()}
      disabled={isLoading}
      style={{ float: 'right' }}
    >
      Xác nhận
    </Button>,
    <Button
      key="rj"
      type="primary"
      danger
      disabled={isLoading}
      onClick={onReject}
      style={{ float: 'right', marginRight: 10 }}
    >
      Từ chối
    </Button>
  ];

  if (!withdrawal) return null;
  return (
    <Form
      {...layout}
      initialValues={{
        ...withdrawal?.approved_info
      }}
      form={form}
      onFinish={onFinish}
    >
      <Drawer
        className="drawer"
        width="70vw"
        title="CHI TIẾT YÊU CẦU RÚT TIỀN"
        placement="left"
        onClose={onCancel}
        closeIcon={<CloseOutlined onClick={onCancel} />}
        open={isOpen}
        footer={isDraft && actionButtons(form)}
      >
        <Spin spinning={isLoading}>
          <CardLayout title="Thông tin khách hàng">
            {customerInfo.map((item, key) => (
              <ContentItem title={item.title} value={item.value} key={key} />
            ))}
          </CardLayout>
          <CardLayout
            title="Thông tin tài khoản khách hàng"
            className="cart-qr-code"
          >
            {bankCustomerInfo.map((item, key) => (
              <ContentItem title={item.title} value={item.value} key={key} />
            ))}
            <div className="qr-code">
              {loading ? (
                <Skeleton.Node active={loading} />
              ) : (
                QRCodeUrl && <img src={QRCodeUrl} alt="qr-code" />
              )}
            </div>
          </CardLayout>
          <CardLayout title="Thông tin rút tiền">
            {withdrawalInfo.map((item, key) => (
              <ContentItem title={item.title} value={item.value} key={key} />
            ))}
          </CardLayout>

          {/* <CardLayout title="Thông tin payment">
            {!isDraft && (
              <>
                <Form.Item name="bank_reference" label="Mã giao dịch ngân hàng">
                  <Input disabled={!isDraft} />
                </Form.Item>
                <Form.Item name="communication" label="Nội dung chuyển khoản">
                  <Input disabled={!isDraft} />
                </Form.Item>
              </>
            )}
            <Form.Item
              name="acc_number"
              label="Tài khoản BuyNgon"
              rules={[{ required: true, message: 'Vui lòng điền thông tin' }]}
            >
              <Select allowClear disabled={!isDraft}>
                {size(buyngonBank) > 0 &&
                  buyngonBank.map((item, key) => (
                    <Select.Option value={item?.account_number} key={key}>
                      {item?.name}{' '}
                      {<Text style={{ color: 'red' }}>{item?.account_number}</Text>}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </CardLayout> */}
        </Spin>
      </Drawer>
    </Form>
  );
}
