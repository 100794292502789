import Spin from 'antd/lib/spin';
import List from 'antd/lib/list';
import React, { useState } from 'react';
import { Button } from 'antd';
import { shallowEqual, useSelector } from 'react-redux';
import { get } from 'lodash';
import WebsiteItem from './WebsiteItem';

export default function Website() {
  const [showAddWebsite, setShowAddWebsite] = useState(false);
  const loading = useSelector(state => {
    return get(state, 'dealngon.loading', false);
  });
  const listWebsites = useSelector(state => {
    return get(state, 'dealngon.websites', []);
  }, shallowEqual);

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <div className="btn-action">
          <div />
          <Button
            onClick={() => {
              return setShowAddWebsite(true);
            }}
            className="save"
          >
            <b>+ Thêm website</b>
          </Button>
        </div>
      </div>
      <div className="dealngon-website">
        {showAddWebsite && (
          <WebsiteItem isNew setShowAddWebsite={setShowAddWebsite} />
        )}
        {(listWebsites.length !== 0 || !showAddWebsite) && (
          <List
            split={false}
            dataSource={listWebsites}
            renderItem={({ _id, ...restProps }) => {
              return (
                <List.Item key={_id}>
                  <WebsiteItem id={_id} {...restProps} />
                </List.Item>
              );
            }}
          />
        )}
      </div>
    </Spin>
  );
}
