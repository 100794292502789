import { Button, Form, Input, Modal, Spin } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomerProfile from '../../../components/CustomerProfile';
import SearchCustomer from '../../../components/SearchCustomer';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';

export default function RequestModel() {
  const detail = useSelector(state => state.exchangePointRequest.request);
  const userSelected = useSelector(state => state.customer?.userSelected);
  const [isSelectedUser, setIsSelectedUser] = useState(false);
  const loading = useSelector(
    state => !!state.common.loadingImprove?.updatePointByAdmin
  );

  const onCancel = () => {
    dispatch(actions.toggleRequestChangePoint());
    dispatch(actions.clearCustomer());
  };
  const onFinish = data => {
    const payload = { user_id: userSelected._id, ...data };
    dispatch(actions.updatePointByAdmin(payload));
  };

  return (
    <Modal
      visible={!!detail}
      title="Tạo yêu cầu thay đổi điểm thưởng"
      onCancel={onCancel}
      footer={null}
      width={650}
    >
      <Spin spinning={loading}>
        {userSelected && (
          <CustomerProfile
            shorthand
            isSelected={isSelectedUser}
            setIsSelected={setIsSelectedUser}
          />
        )}
        {!userSelected && <SearchCustomer />}

        <Form
          name="basic"
          style={{ marginTop: 16 }}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          autoComplete="off"
        >
          {userSelected && (
            <Form.Item
              label="Số điểm cần thay đổi"
              name="amount"
              rules={[
                { required: true, message: 'Vui lòng nhập số điểm cần nạp/rút' }
              ]}
            >
              <Input />
            </Form.Item>
          )}

          {userSelected && (
            <Form.Item
              label="Lý do"
              name="reason"
              rules={[{ required: true, message: 'Vui lòng nhập lý do nạp/rút' }]}
            >
              <Input />
            </Form.Item>
          )}

          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={onCancel} style={{ marginRight: 8 }}>
                Huỷ
              </Button>
              {userSelected && (
                <Button type="primary" htmlType="submit">
                  Xác nhận
                </Button>
              )}
            </div>
          </Form.Item>
        </Form>
      </Spin>
    </Modal>
  );
}
