import get from 'lodash/get';
import TYPE from '../actions/TYPE';

const initialState = {};

const configuration = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.CLEAR_CONFIGURATION:
      return {};
    case TYPE.DRAGGABLE_BANNER:
      return {
        ...state,
        banner: { list: action.payload }
      };
    case TYPE.DRAGGABLE_LIST_CATEGORY:
      return {
        ...state,
        category: { list: action.payload }
      };
    case TYPE.GET_CONFIGURATION_SUCCESS:
    case TYPE.UPDATE_CONFIGURATION_SUCCESS:
      const data = get(action, 'data', null);
      return data || initialState;

    case TYPE.GET_CONFIGURATION_FAILED:
    case TYPE.UPDATE_CONFIGURATION_FAILED:
    default:
      return state;
  }
};

export default configuration;
