import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import get from 'lodash/get';
import actions from '../../redux/actions';
import { MessageContent } from '../../contents/Labels';
import CustomizeTable from '../../components/CustomizeTable/CustomizeTable';
import PopupConfirm from '../../components/Popup/PopupConfirm';
import { LIMIT_PAGE_DEFAULT } from '../../contents/Constants';
import dispatch from '../../utils/dispatch';

const AccountList = () => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(actions.getAccountList({ page, limit: LIMIT_PAGE_DEFAULT }));
  }, [page]);

  const { count, accounts } = useSelector(state => {
    return get(state, 'account.list', {});
  });

  const changeStatusActivityAccount = ({ key, status }) => {
    return () => {
      return dispatch(actions.changeStatusActivityAccount({ id: key, status }));
    };
  };

  const data = map(accounts, item => {
    return {
      key: item._id,
      status: item.status,
      name: item.name,
      email: item.email,
      role: item.role
    };
  });

  const columns = [
    {
      title: MessageContent.Status,
      key: 'status',
      dataIndex: 'status',
      align: 'center',
      width: '10%',
      fixed: true,
      render: (status, record) => {
        return (
          <PopupConfirm
            title={MessageContent.ChangeStatusAccount}
            status={status}
            record={record}
            onChangeStatus={changeStatusActivityAccount}
          />
        );
      }
    },
    {
      title: MessageContent.Name,
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: MessageContent.Email,
      dataIndex: 'email',
      key: 'email'
    },
    {
      title: MessageContent.Role,
      dataIndex: 'role',
      key: 'role',
      align: 'center'
    }
  ];

  const [searchParams, setSearch] = useState('');

  const getAccountList = pageIndex => {
    if (searchParams) {
      dispatch(actions.searchAccount({ searchParams, page: pageIndex }));
      return;
    }
    dispatch(actions.getAccountList({ page: pageIndex }));
  };

  const searchAccount = event => {
    setSearch(event);
    dispatch(actions.searchAccount({ searchParams: event }));
  };

  return (
    <CustomizeTable
      title={MessageContent.AccountInfo}
      columns={columns}
      data={data}
      count={count}
      currentPage={page}
      onPageChange={setPage}
      onSearch={searchAccount}
      onGetList={getAccountList}
    />
  );
};

export default AccountList;
