import React, { useState } from 'react';
import './style.less';
import Button from 'antd/lib/button';
import Drawer from 'antd/lib/drawer';
import Image from 'antd/lib/image';
import Title from 'antd/lib/typography/Title';
import Space from 'antd/lib/space';
import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import UserInfo from '../UserInfo';
import MainMenu from '../MainMenu';
import { MessageContent, CompanyName } from '../../contents/Labels';
import Images from '../../contents/Images';

const SideBar = () => {
  const [visible, setVisible] = useState(false);
  const showDrawer = () => {
    return setVisible(true);
  };
  const onClose = () => {
    return setVisible(false);
  };

  return (
    <div className="sidebar">
      <Space className="sidebar-header">
        <Image preview={false} width={40} src={Images.logo} alt="logo" />
        <Title level={4} className="title">
          {CompanyName}
        </Title>
      </Space>
      <div>
        <Button
          className="toggle-btn"
          type="primary"
          onClick={showDrawer}
          icon={<MenuOutlined />}
        />
        <Drawer
          title={MessageContent.Category}
          placement="right"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <MainMenu />
          <UserInfo />
        </Drawer>
      </div>
      <div className="sidebar-body">
        <MainMenu />
        <UserInfo />
      </div>
    </div>
  );
};

export default SideBar;
