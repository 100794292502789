import React, { useEffect, useState } from 'react';
import 'antd/dist/antd.css';
import { Upload, Modal, Spin } from 'antd';
import { useSelector } from 'react-redux';
import { size, get, findIndex } from 'lodash';
import { UploadOutlined } from '@ant-design/icons';
import API from '../../services/API';
import { ACCESS_KEY } from '../../contents/Constants';
import './styles.less';
import dispatch from '../../utils/dispatch';
import actions from '../../redux/actions';
import { notificationError } from '../../utils/Notification';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      return resolve(reader.result);
    };
    reader.onerror = error => {
      return reject(error);
    };
  });
}

function handleCheckIconExisted(iconId, websites, categories) {
  const existedCategories = categories.filter(item => {
    const index = findIndex(item.icon, { _id: iconId });
    return index !== -1 && item;
  });

  const existedWebsites = websites.filter(item => {
    const index = findIndex(item.icon, { _id: iconId });
    return index !== -1 && item;
  });

  const isExisted = size(existedCategories) > 0 || size(existedWebsites) > 0;

  return {
    existedCategories,
    existedWebsites,
    isExisted
  };
}

export default function Icons() {
  const listWebsites = useSelector(state => {
    return get(state, 'dealngon.websites', []);
  });
  const listCategories = useSelector(state => {
    return get(state, 'dealngon.categories', []);
  });
  const listIcons = useSelector(state => {
    return get(state, 'dealngon.icons', []);
  });
  const loading = useSelector(state => {
    return get(state, 'common.iconLoading', false);
  });

  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  const [fileList, setFileList] = useState([]);

  const [isUpload, setIsUpload] = useState(false);

  useEffect(() => {
    if (size(listIcons) > 0) {
      const iconList = listIcons.map((item, index) => {
        return {
          uid: get(item, '_id', ''),
          name: `Icon ${index + 1}`,
          status: 'done',
          url: get(item, 'image', '')
        };
      });

      setFileList(iconList);
    }
  }, [listIcons]);

  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setPreviewImage(file.url || file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  useEffect(() => {
    const isUpdate = fileList.every(item => item.status === 'done');
    if (isUpdate && isUpload) {
      dispatch(actions.getListIconsOther());
      setIsUpload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  const handleChange = ({ fileList: listFileData }) => {
    setIsUpload(true);
    setFileList(listFileData);
  };

  const handleRemove = file => {
    const id = file?.response ? file.response._id : file.uid;

    const { existedCategories, existedWebsites, isExisted } = handleCheckIconExisted(
      id,
      listWebsites,
      listCategories
    );

    if (!isExisted) {
      dispatch(actions.deleteIcon(id));
      return true;
    }

    existedCategories.forEach(item => {
      return notificationError(`Xoá icon category: ${item.name}`);
    });
    existedWebsites.forEach(item => {
      return notificationError(`Xoá icon website: ${item.domain}`);
    });
    return false;
  };

  const handleCancel = () => {
    return setPreviewVisible(false);
  };

  return (
    <div className="icon-container">
      <Spin spinning={loading}>
        <Upload
          accept={'image/*'}
          multiple
          name="image"
          action={API.ICON}
          headers={{ authorization: window.localStorage.getItem(ACCESS_KEY) }}
          listType="picture-card"
          fileList={fileList}
          onPreview={handlePreview}
          onChange={handleChange}
          onRemove={handleRemove}
        >
          <div className="upload-button icon-item">
            <UploadOutlined />
            <p>Upload</p>
          </div>
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Spin>
    </div>
  );
}
