import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { get, size } from 'lodash';
import {
  Button,
  Form,
  Input,
  InputNumber,
  Select,
  Space,
  TreeSelect,
  Typography
} from 'antd';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import UploadImage from '../../../components/UploadImage';
import useUploadFile from '../../../hooks/useUploadFile';
import dispatch from '../../../utils/dispatch';
import actions from '../../../redux/actions';
import './style.less';
import { addAttributeTag, replaceOneTagHtmlByDiv } from '../../../utils/helpers';
import API from '../../../services/API';
import { ACCESS_KEY } from '../../../contents/Constants';

const { Title } = Typography;

export default function CreateDeal() {
  const [form] = Form.useForm();
  const [customEditor, setCustomEditor] = useState(null);
  const formItemLayout = {
    labelCol: {
      span: 4
    },
    wrapperCol: {
      span: 14
    }
  };
  const buttonItemLayout = {
    wrapperCol: {
      span: 14,
      offset: 4
    }
  };
  const { Option } = Select;
  const [file, handleUploadFile, handleReset] = useUploadFile('');

  useEffect(() => {
    dispatch(actions.listSellerProfile());
  }, []);
  useEffect(() => {
    dispatch(actions.listCategory());
  }, []);

  // useEffect(() => {
  //   dispatch(actions.listTopic());
  // }, []);

  // const listTopic = useSelector(state => get(state, 'topic.list', []));
  // const selectTopic =
  //   size(listTopic) > 0
  //     ? listTopic?.map(item => ({
  //         title: item.name,
  //         value: item.id
  //       }))
  //     : [];
  const listSeller = useSelector(state => get(state, 'seller.list', {}));
  const listCategory = useSelector(state => get(state, 'dealCategory.list', []));

  const categoryTree = size(listCategory) > 0 ? listCategory : [];

  const inputHandler = (event, editor) => {
    const data = editor.getData();
    const dataCustom = replaceOneTagHtmlByDiv(data, 'a');
    if (dataCustom !== data) editor.setData(dataCustom);
  };
  const onFinish = values => {
    const deal_data = {
      user_id: values.user_id,
      deal: {
        name: values.name,
        price: values.price,
        rate: values.rate,
        buy_step: values.buy_step,
        avatar: file.link,
        topic_id: 1,
        description_html: addAttributeTag(
          values.description_html,
          'img',
          'style',
          'width:50%;'
        ),
        product_category_id: values.product_category_id,
        website: values.website.toLowerCase()
      }
    };
    dispatch(actions.createDeal(deal_data));
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          loader.file.then(img => {
            const formData = new FormData();
            formData.append('image', img);
            return fetch(API.GET_URL_IMAGE, {
              method: 'POST',
              headers: {
                authorization: window.localStorage.getItem(ACCESS_KEY)
              },
              body: formData
            })
              .then(response => {
                return response.json();
              })
              .then(({ data }) => {
                resolve({
                  default: `${data}`
                });
              })
              .catch(err => {
                reject(err);
              });
          });
        });
      }
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = loader => {
      return uploadAdapter(loader);
    };
  }
  return (
    <div>
      <Space size="large">
        <Title level={4}>Thông tin deal:</Title>
      </Space>
      <Form {...formItemLayout} layout="horizontal" form={form} onFinish={onFinish}>
        <Form.Item
          label="Người bán"
          name="user_id"
          rules={[{ required: true, message: 'Nhập seller' }]}
        >
          <Select placeholder="BuyNgon" optionFilterProp="children" showSearch>
            {listSeller?.sellers.map((item, index) => {
              return (
                <Option value={item?._id} key={index}>
                  {item?.seller_profile.name}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          label="Tên deal"
          name="name"
          rules={[{ required: true, message: 'Nhập tên deal' }]}
        >
          <Input placeholder="Deal Nike Hot" />
        </Form.Item>
        <Form.Item
          label="Website"
          name="website"
          rules={[{ required: true, message: 'Nhập Website' }]}
        >
          <Input placeholder="nike.com" />
        </Form.Item>

        <Form.Item
          label="Giá trị"
          name="price"
          rules={[{ required: true, message: 'Nhập giá trị deal' }]}
        >
          <InputNumber
            placeholder="30000"
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          label="Tỉ giá"
          name="rate"
          rules={[{ required: true, message: 'Nhập tỉ giá deal' }]}
        >
          <InputNumber
            placeholder="18000"
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          label="Bước nhảy"
          name="buy_step"
          rules={[{ required: true, message: 'Nhập bước nhảy' }]}
        >
          <InputNumber
            placeholder="Giá trị nhỏ nhất có thể mua của Deal (ví dụ :100) "
            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        {/* <Form.Item label="Topic" name="topic_id">
          <TreeSelect treeData={selectTopic} />
        </Form.Item> */}
        <Form.Item label="Category" name="product_category_id">
          <TreeSelect treeData={categoryTree} />
        </Form.Item>

        <Form.Item
          label="Body"
          name="description_html"
          valuePropName="data"
          getValueFromEvent={(event, editor) => {
            // eslint-disable-next-line no-shadow
            const data = editor.getData();
            return data;
          }}
          rules={[{ required: true, message: 'Please enter the body' }]}
        >
          <CKEditor
            config={{
              extraPlugins: [uploadPlugin],
              image: {
                resizeUnit: '%',
                resizeOptions: [
                  {
                    name: 'resizeImage:original',
                    value: '100',
                    label: '100%',
                    icon: 'original'
                  },
                  {
                    name: 'resizeImage:50',
                    value: '50',
                    label: '50%',
                    icon: 'medium'
                  },
                  {
                    name: 'resizeImage:75',
                    value: '75',
                    label: '75%',
                    icon: 'large'
                  }
                ],
                toolbar: ['resizeImage:50', 'resizeImage:75', 'resizeImage:original']
              }
            }}
            editor={DecoupledEditor}
            onReady={editor => {
              editor.ui
                .getEditableElement()
                .parentElement.insertBefore(
                  editor.ui.view.toolbar.element,
                  editor.ui.getEditableElement()
                );
              setCustomEditor(editor);
            }}
            onError={(error, { willEditorRestart }) => {
              if (willEditorRestart) {
                customEditor.ui.view.toolbar.element.remove();
              }
            }}
            onChange={inputHandler}
          />
        </Form.Item>

        <Form.Item label="Hình ảnh" name="image">
          <UploadImage
            imgUrl={file.link}
            loading={file.isFetching}
            beforeUpload={handleUploadFile}
            handleReset={handleReset}
          />
        </Form.Item>
        <Form.Item {...buttonItemLayout}>
          <Button disabled={file.isFetching} type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
