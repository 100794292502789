import { get } from 'lodash';
import TYPE from '../actions/TYPE';

const initialState = {
  province: [],
  district: [],
  ward: []
};

const location = (state = initialState, action = {}) => {
  switch (action.type) {
    case TYPE.GET_ALL_PROVINCE_SUCCESS:
      return {
        ...state,
        province: get(action, 'data')
      };
    case TYPE.GET_ALL_DISTRICT_SUCCESS:
      return {
        ...state,
        district: get(action, 'data')
      };
    case TYPE.GET_ALL_WARD_SUCCESS:
      return {
        ...state,
        ward: get(action, 'data')
      };

    default:
      return state;
  }
};

export default location;
