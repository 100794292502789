import { Spin } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import React, { createContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import FilterAndSearch from '../../../components/FilterAndSearch';
import CustomizeTable from '../component/CustomizeTable/CustomizeTable';
// import ConfirmTransaction from '../../../components/Modal/ConfirmTransaction';
import {
  DEAL_TRANSACTION_STATUS,
  LIMIT_PAGE_DEFAULT,
  TRANSACTION_STATUS
} from '../../../contents/Constants';
import { MessageContent } from '../../../contents/Labels';
import actions from '../../../redux/actions';
import dispatch from '../../../utils/dispatch';
// import TransactionDetail from '../TransactionDetail';
import CreateNewWithdrawModal from '../component/CreateNewWithdrawModal';
import DealWithdrawDetailModal from '../component/DealWithdrawDetailModal';
import columnData from './columnData';
import './style.less';

export const TransactionListContext = createContext(null);

const SEARCH_TRANSACTION = {
  text: '',
  date: null
};

const DealWithdraw = () => {
  const { count, data: transactions } = useSelector(state => {
    return get(state, 'dealWithdraw.list', {});
  });
  const loadingCreate = useSelector(state => {
    return get(state, 'common.loadingImprove.createDealWithdrawRequest', false);
  });
  const loadingList = useSelector(state => {
    return get(state, 'common.loadingImprove.getListDealWithdraw', false);
  });
  const [page, setPage] = useState(3);
  const [activeTab, setActiveTab] = useState(
    DEAL_TRANSACTION_STATUS.customerConfirmed.key
  );
  const [searchForm, setSearchForm] = useState(SEARCH_TRANSACTION);
  const activeTabRef = useRef(TRANSACTION_STATUS.customerConfirmed.key);

  useEffect(() => {
    if (loadingCreate) return;
    const pageIndex = activeTabRef.current === activeTab ? page : 1;

    activeTabRef.current = activeTab;
    setPage(pageIndex);

    dispatch(
      actions.getListDealWithdraw({
        page: pageIndex,
        state: activeTab,
        limit: LIMIT_PAGE_DEFAULT
      })
    );
  }, [page, loadingCreate, activeTab]);

  const data = map(transactions, item => {
    return { key: item._id, ...item };
  });

  const handleChangePage = pageIndex => {
    return setPage(pageIndex);
  };

  const [openModal, setOpenModal] = useState(false);

  const handleShowModalWithdraw = () => setOpenModal(true);
  const handleCloseModalWithdraw = () => {
    setOpenModal(false);
    dispatch(actions.clearCustomer());
    dispatch(actions.clearListDealByUser());
  };

  return (
    <TransactionListContext.Provider value={{ searchForm, setSearchForm }}>
      <Spin spinning={loadingCreate || loadingList}>
        <CustomizeTable
          title={MessageContent.DealWithdrawInfo}
          columns={columnData[activeTab]}
          data={data}
          count={count}
          currentPage={page}
          onPageChange={handleChangePage}
          filterAndSearch={
            <FilterAndSearch.DealWithdraw onOpenModal={handleShowModalWithdraw} />
          }
          categoryTabs={DEAL_TRANSACTION_STATUS.list}
          activeCategoryTab={activeTab}
          onChangeCategoryTab={setActiveTab}
        />
      </Spin>
      <DealWithdrawDetailModal />
      <CreateNewWithdrawModal open={openModal} onClose={handleCloseModalWithdraw} />
    </TransactionListContext.Provider>
  );
};

export default DealWithdraw;
