/* eslint-disable react/prop-types */
import { Button, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'react-redux';
import { MessageContent } from '../../../contents/Labels';
import actions from '../../../redux/actions';
import { convertDollar, convertVND } from '../../../utils/convert';
import dispatch from '../../../utils/dispatch';

const { Text } = Typography;

function ButtonDetail({ _id }) {
  return (
    <Button
      type="dashed"
      style={{ marginLeft: 10 }}
      onClick={() => {
        return dispatch(actions.getDealWithdrawDetail(_id));
      }}
    >
      {MessageContent.Detail}
    </Button>
  );
}

function useGetLoading(id) {
  return useSelector(
    state =>
      !!state.common?.loadingImprove?.[`confirmDealWithdraw_${id}`] ||
      !!state.common?.loadingImprove?.[`rejectDealWithdraw_${id}`]
  );
}

function ButtonConfirm({ _id }) {
  const loading = useGetLoading(_id);
  return (
    <Button
      type="primary"
      loading={loading}
      onClick={() => dispatch(actions.confirmDealWithdraw({ id: _id }))}
    >
      {MessageContent.Confirm}
    </Button>
  );
}

function ButtonReject({ _id }) {
  const loading = useGetLoading(_id);
  return (
    <Button
      type="danger"
      loading={loading}
      style={{ marginLeft: 10 }}
      onClick={() => dispatch(actions.rejectDealWithdraw({ id: _id }))}
    >
      {MessageContent.Reject}
    </Button>
  );
}

const createdAtSection = [
  {
    title: 'Ngày giao dịch',
    key: 'created_at',
    dataIndex: 'created_at',
    align: 'center',
    width: '10%',
    fixed: true,
    render: text => {
      return <Text>{text ? moment(text).format('HH:mm:ss, DD/MM/YYYY') : '-'}</Text>;
    }
  }
];

const confirmedAtSection = [
  {
    title: 'Ngày xác nhận',
    key: 'approved_time',
    dataIndex: 'approved_time',
    align: 'center',
    width: '10%',
    fixed: true,
    render: text => {
      return <Text>{text ? moment(text).format('HH:mm:ss, DD/MM/YYYY') : '-'}</Text>;
    }
  }
];

const rejectedAtSection = [
  {
    title: 'Ngày từ chối',
    key: 'approved_time',
    dataIndex: 'approved_time',
    align: 'center',
    width: '10%',
    fixed: true,
    render: text => {
      return <Text>{text ? moment(text).format('HH:mm:ss, DD/MM/YYYY') : '-'}</Text>;
    }
  }
];

const infoSection = [
  {
    title: 'Tên deal',
    dataIndex: 'deal_name',
    key: 'deal_name',
    width: '25%',
    align: 'center',
    render: (_, record) => (
      <p style={{ textAlign: 'left' }}>
        {
          record?.deal_gift_card?.deal_purchase_transaction?.deal_order_id?.history
            ?.name
        }
      </p>
    )
  },
  {
    title: 'Số tiền cần hoàn',
    dataIndex: 'current_gift_card_deposit',
    key: 'current_gift_card_deposit',
    width: '10%',
    align: 'right',
    render: data => <Text>{convertDollar(data)}</Text>
  },
  {
    title: 'Tỉ giá',
    dataIndex: 'rate',
    key: 'rate',
    align: 'right',
    width: '10%',
    render: (_, record) => (
      <span>
        {convertVND(
          record?.deal_gift_card?.deal_purchase_transaction?.deal_order_id?.history
            ?.rate || 0
        )}
      </span>
    )
  },
  {
    title: 'Thông tin khách hàng',
    dataIndex: 'name',
    key: 'name',
    width: '20%',
    align: 'left',
    render: (_, record) => (
      <>
        <span>
          <b>Tên:</b> {record?.user?.name}
        </span>
        <br />
        <span>
          <b>Email:</b> {record?.user?.email}
        </span>
      </>
    )
  }
];

const approved_section = infoSection.concat([
  {
    title: 'Người xác nhận',
    dataIndex: 'approved_by',
    key: 'approved_by',
    width: '15%',
    align: 'right',
    render: info => <span>{info?.[0]?.name}</span>
  }
]);

const actionDraftSection = [
  {
    title: 'Thao tác',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    render: (_, record) => {
      return (
        <div>
          <ButtonConfirm {...record} />
          <ButtonReject {...record} />
          <ButtonDetail {...record} />
        </div>
      );
    }
  }
];

const actionDetailSection = [
  {
    title: 'Thao tác',
    dataIndex: 'action',
    key: 'action',
    align: 'left',
    render: (_, record) => {
      return <ButtonDetail {...record} />;
    }
  }
];

export default {
  draft: [...createdAtSection, ...infoSection, ...actionDraftSection],
  buyngon_confirmed: [
    ...confirmedAtSection,
    ...approved_section,
    ...actionDetailSection
  ],
  buyngon_rejected: [
    ...rejectedAtSection,
    ...approved_section,
    ...actionDetailSection
  ]
};
