import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import { notificationError, notificationSuccess } from '../../utils/Notification';
import TYPE from '../actions/TYPE';

function* getListDealWithdraw({ payload }) {
  try {
    yield put({ type: TYPE.ENABLE_LOADING_IMPROVE, target: 'getListDealWithdraw' });
    const cleanedParams = pickBy(payload, identity);
    const {
      data: { results, count }
    } = yield call(services.getListDealWithdraw, cleanedParams);
    yield put({
      type: TYPE.GET_LIST_DEAL_WITHDRAW_SUCCESS,
      data: results,
      count
    });
  } catch (error) {
    yield put({
      type: TYPE.GET_LIST_DEAL_WITHDRAW_FAILED
    });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING_IMPROVE, target: 'getListDealWithdraw' });
  }
}

function* createDealWithdrawRequest({ payload }) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: 'createDealWithdrawRequest'
    });
    const { data } = yield call(services.createDealWithdrawRequest, payload);
    yield put({ type: TYPE.CREATE_DEAL_WITHDRAW_REQUEST_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: TYPE.CREATE_DEAL_WITHDRAW_REQUEST_FAILED });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: 'createDealWithdrawRequest'
    });
  }
}

function* confirmDealWithdraw({ payload }) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: `confirmDealWithdraw_${payload.id}`
    });
    const { success } = yield call(services.confirmDealWithdraw, payload);

    if (!success) {
      // const messageError = 'Có lỗi xảy ra vui lòng thử lại sau';
      // notificationError(messageError);
      // throw new Error(messageError);
      return;
    }

    yield put({ type: TYPE.CONFIRM_DEAL_WITHDRAW_SUCCESS, payload });
    notificationSuccess('Xác nhân yêu cầu hoàn tiền thành công');
  } catch (error) {
    yield put({ type: TYPE.CONFIRM_DEAL_WITHDRAW_FAILED });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: `confirmDealWithdraw_${payload.id}`
    });
  }
}

function* rejectDealWithdraw({ payload }) {
  try {
    yield put({
      type: TYPE.ENABLE_LOADING_IMPROVE,
      target: `rejectDealWithdraw_${payload.id}`
    });
    const { success } = yield call(services.rejectDealWithdraw, payload);

    if (!success) {
      const messageError = 'Có lỗi xảy ra vui lòng thử lại sau';
      notificationError(messageError);
      throw new Error(messageError);
    }

    yield put({ type: TYPE.REJECT_DEAL_WITHDRAW_SUCCESS, payload });
    notificationSuccess('Huỷ yêu cầu hoàn tiền thành công');
  } catch (error) {
    yield put({ type: TYPE.REJECT_DEAL_WITHDRAW_FAILED });
  } finally {
    yield put({
      type: TYPE.DISABLE_LOADING_IMPROVE,
      target: `rejectDealWithdraw_${payload.id}`
    });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_LIST_DEAL_WITHDRAW, getListDealWithdraw),
    takeLatest(TYPE.CREATE_DEAL_WITHDRAW_REQUEST, createDealWithdrawRequest),
    takeLatest(TYPE.CONFIRM_DEAL_WITHDRAW, confirmDealWithdraw),
    takeLatest(TYPE.REJECT_DEAL_WITHDRAW, rejectDealWithdraw)
  ]);
}
