import Account from './Account';
import Campaign from './Campaign';
import Customer from './Customer';
import DealManage from './DealManage';
import DealTransaction from './DealTransaction';
import DealWithdraw from './DealWithdraw';
import Refresh from './Refresh';
import SellerProfile from './SellerProfile';
import Transaction from './Transaction';

export default {
  Account,
  Campaign,
  Customer,
  DealManage,
  DealTransaction,
  DealWithdraw,
  Refresh,
  SellerProfile,
  Transaction
};
