import React from 'react';
import { Form, Input } from 'antd';
import './style.less';
import { any, func, objectOf, string } from 'prop-types';

const InputEdit = ({ title, name, value, onChangeData, formSize }) => {
  const handleChangeData = event => {
    const { name: nameInput, value: valueInput } = event.target;
    onChangeData({ name: nameInput, value: valueInput });
  };

  return (
    <Form.Item label={title} className="input-edit">
      <Input.TextArea
        autoSize={formSize}
        name={name}
        value={value}
        onChange={handleChangeData}
      />
    </Form.Item>
  );
};

InputEdit.propTypes = {
  title: string,
  name: string,
  value: any,
  onChangeData: func,
  formSize: objectOf(any)
};

InputEdit.defaultProps = {
  title: '',
  name: '',
  value: null,
  onChangeData: () => {},
  formSize: {
    minRows: 2,
    maxRows: 6
  }
};

export default InputEdit;
