import React from 'react';

export default function User() {
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#F5F5F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.294 19.2911C29.294 22.2281 26.9391 24.5831 24 24.5831C21.0619 24.5831 18.706 22.2281 18.706 19.2911C18.706 16.354 21.0619 14 24 14C26.9391 14 29.294 16.354 29.294 19.2911ZM24 34C19.6624 34 16 33.295 16 30.575C16 27.8539 19.6854 27.1739 24 27.1739C28.3386 27.1739 32 27.8789 32 30.599C32 33.32 28.3146 34 24 34Z"
        fill="#252629"
      />
    </svg>
  );
}
