import React from 'react';
import Text from 'antd/lib/typography/Text';
import Title from 'antd/lib/typography/Title';
import Space from 'antd/lib/space';
import Image from 'antd/lib/image';
import Avatar from 'antd/lib/avatar';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Switch from 'antd/lib/switch';
import MailOutlined from '@ant-design/icons/MailOutlined';
import IdcardOutlined from '@ant-design/icons/IdcardOutlined';
import DollarOutlined from '@ant-design/icons/DollarOutlined';
import Icon from '@ant-design/icons/lib/components/Icon';
import { object, oneOfType } from 'prop-types';
import { useDispatch } from 'react-redux';
import currency from '../../../../utils/currency';
import Google from '../../../../components/icons/Google';
import Facebook from '../../../../components/icons/Facebook';
import Phone from '../../../../components/icons/Phone';
import { PROVIDERS } from '../../../../contents/Constants';
import actions from '../../../../redux/actions';

const Profile = ({ customer }) => {
  const dispatch = useDispatch();
  const {
    balance,
    email,
    name,
    providers,
    status,
    odoo_customer_id: odooCustomerId,
    avatar_url: avatarUrl
  } = customer;

  const renderProvider = () => {
    if (providers === PROVIDERS.FACEBOOK) return <Icon component={Facebook} />;
    if (providers === PROVIDERS.GOOGLE) return <Icon component={Google} />;
    return <Icon component={Phone} />;
  };

  const changeStatusActivityCustomer = ({ _id: id, status: statusCustomer }) => {
    return () => {
      return dispatch(
        actions.changeStatusActivityCustomer({
          id,
          status: statusCustomer
        })
      );
    };
  };

  return (
    <Row gutter={[15, 30]} justify="center">
      <Col span={10}>
        <Space size="large">
          <Avatar
            size={80}
            src={<Image preview={false} src={avatarUrl} />}
            className="avatar"
          />
          <Space direction="vertical">
            <Title level={3}>{name}</Title>
            <Space>
              <Switch
                checked={status}
                size="small"
                onClick={changeStatusActivityCustomer(customer)}
              />
              {renderProvider()}
            </Space>
          </Space>
        </Space>
      </Col>
      <Col span={10}>
        <Space direction="vertical">
          <Space>
            <MailOutlined />
            <Text>{email}</Text>
          </Space>
          <Space>
            <IdcardOutlined />
            <Text>{odooCustomerId}</Text>
          </Space>
          <Space>
            <DollarOutlined />
            <Text>{`${currency.format(balance)} đ`}</Text>
          </Space>
        </Space>
      </Col>
    </Row>
  );
};

Profile.propTypes = {
  customer: oneOfType([object]).isRequired
};

export default Profile;
