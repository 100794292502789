import React, { useEffect, useState } from 'react';
import Row from 'antd/lib/row';
import Card from 'antd/lib/card';
import Checkbox from 'antd/lib/checkbox';
import ButtonGroup from 'antd/lib/button/button-group';
import Button from 'antd/lib/button';
import '../../theme/style.less';
import Spin from 'antd/lib/spin';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { get } from 'lodash';
import actions from '../../../redux/actions';
import { notificationSuccess } from '../../../utils/Notification';
import { MESSAGE_CONTENT } from '../../../contents/Constants';
import InputEdit from '../../theme/fragments/InputEdit';

const options = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'Google', value: 'google' }
];

function LoginAction() {
  const dispatch = useDispatch();
  const actionConfig = useSelector(state => {
    return get(state, 'configuration.actions', []);
  }, shallowEqual);
  const actionLogin = actionConfig?.login;
  const actionExtension = useSelector(state => {
    return get(state, 'configuration.actions.extension.id', '');
  });

  const [provider, setProvider] = useState([]);
  const [extensionId, setExtensionId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setProvider(actionLogin);
  }, [actionLogin]);

  useEffect(() => {
    setExtensionId(actionExtension);
  }, [actionExtension]);

  const onSave = () => {
    setLoading(true);
    dispatch(
      actions.updateConfiguration({
        data: {
          actions: {
            ...actionConfig,
            login: provider,
            extension: { id: extensionId }
          }
        },
        callback: ({ error }) => {
          setTimeout(() => {
            setLoading(false);
            if (!error) {
              notificationSuccess(MESSAGE_CONTENT.updateSuccess);
            }
          }, 1 * 1000);
        }
      })
    );
  };

  const onReset = () => {
    setProvider([]);
  };

  const onChange = checkedValues => {
    setProvider(checkedValues);
  };

  const onChangeInput = ({ value }) => {
    setExtensionId(value);
  };

  return (
    <Spin spinning={loading}>
      <div className="fragment-theme">
        <ButtonGroup className="btn-action">
          <Button className="save" onClick={onSave}>
            Lưu lại
          </Button>
          <Button type="default" onClick={onReset}>
            Reset
          </Button>
        </ButtonGroup>
        <Row>
          <Card title="Hình thức đăng nhập" className="card">
            <Checkbox.Group options={options} value={provider} onChange={onChange} />
          </Card>
        </Row>
        <Row>
          <Card title="Chrome Extension" className="card">
            <InputEdit
              title="Extension ID"
              value={extensionId}
              onChangeData={onChangeInput}
            />
          </Card>
        </Row>
      </div>
    </Spin>
  );
}

export default LoginAction;
