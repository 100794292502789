import { all, call, put, takeLatest } from 'redux-saga/effects';
import services from '../../services';
import TYPE from '../actions/TYPE';

function* getAllCategories() {
  try {
    yield put({ type: TYPE.ENABLE_LOADING });
    const { data } = yield call(services.getAllCategories);
    yield put({ type: TYPE.GET_ALL_CATEGORIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: TYPE.GET_ALL_CATEGORIES_FAILED, msg: error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING });
  }
}

function* createGroup(action) {
  try {
    const { body } = action;
    yield put({ type: TYPE.ENABLE_LOADING });
    const data = yield call(services.createGroup, body);
    yield put({ type: TYPE.CREATE_GROUP_SUCCESS, data });
  } catch (err) {
    yield put({ type: TYPE.CREATE_GROUP_FAILED, msg: err });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING });
  }
}

function* createCategory(action) {
  try {
    const { body } = action;
    yield put({ type: TYPE.ENABLE_LOADING });
    const data = yield call(services.createCategory, body);
    yield put({ type: TYPE.CREATE_CATEGORY_SUCCESS, data });
  } catch (err) {
    yield put({ type: TYPE.CREATE_CATEGORY_FAILED, msg: err });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING });
  }
}

function* updateCategories({ payload }) {
  const { data, callback } = payload;
  try {
    // yield put({ type: TYPE.ENABLE_LOADING });
    yield call(services.updateCategoriesImprovement, data);
    yield put({ type: TYPE.UPDATE_CATEGORIES_SUCCESS, payload: data });
    callback({ error: false, data });
  } catch (error) {
    callback({ error: true, data: null });
    // yield put({ type: TYPE.UPDATE_CATEGORIES_FAILED, msg: error });
  } finally {
    // yield put({ type: TYPE.DISABLE_LOADING });
  }
}

function* deleteCategory(action) {
  try {
    const { body, groupIndex, categoryIndex } = action;
    yield put({ type: TYPE.ENABLE_LOADING });
    yield call(services.deleteCategory, body);
    yield put({
      type: TYPE.DELETE_CATEGORY_SUCCESS,
      data: { type: body.type, groupIndex, categoryIndex }
    });
  } catch (error) {
    yield put({ type: TYPE.DELETE_CATEGORY_FAILED, msg: error });
  } finally {
    yield put({ type: TYPE.DISABLE_LOADING });
  }
}

export default function* root() {
  yield all([
    takeLatest(TYPE.GET_ALL_CATEGORIES, getAllCategories),
    takeLatest(TYPE.CREATE_GROUP, createGroup),
    takeLatest(TYPE.CREATE_CATEGORY, createCategory),
    takeLatest(TYPE.UPDATE_CATEGORIES, updateCategories),
    takeLatest(TYPE.DELETE_CATEGORY, deleteCategory)
  ]);
}
