import { findIndex } from 'lodash';
import { useDispatch } from 'react-redux';

export default function useDraggable(list, action) {
  const dispatch = useDispatch();

  function onDragChildEnd(fromIndex, toIndex, parentId, child) {
    if (toIndex < 0) return;
    const index = findIndex(list, { _id: parentId });
    const targetList = [...list[index][child]];
    const item = targetList.splice(fromIndex, 1)[0];
    targetList.splice(toIndex, 0, item);
    targetList.forEach((target, _index) => {
      target.priority = _index + 1;
    });
    const newValue = [...list];
    newValue[index].categories = targetList;
    dispatch(action({ count: newValue.length, result: newValue }));
  }

  function onDragEnd(fromIndex, toIndex) {
    if (toIndex < 0) return [];
    const targetList = [...list];
    const item = targetList.splice(fromIndex, 1)[0];
    targetList.splice(toIndex, 0, item);
    targetList.forEach((target, index) => {
      target.priority = index + 1;
    });
    if (action) {
      dispatch(action({ count: targetList.length, result: targetList }));
    }
    return targetList;
  }

  return {
    onDragEnd,
    onDragChildEnd
  };
}
